import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component used to create nice image meadia player
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.jsx";
import ItemShop from "components/MisComponentes/ItemShop.jsx";
import ResumenShop from "components/MisComponentes/ResumenShop.jsx";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";


class CartPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    handleSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.productPage}>
                <Header
                    brand=""
                    links={<HeaderLinks dropdownHoverColor="dark" />}
                    fixed
                    color="transparent"
                    changeColorOnScroll={{
                        height: 100,
                        color: "dark"
                    }}
                />
                <Parallax
                    image={require("assets/img/bg6.jpg")}
                    filter="dark"
                    className={classes.pageHeader}
                >
                    {/* <div className={classes.container}>
            <GridContainer className={classes.titleRow}>
              <GridItem md={4} className={classes.mlAuto}>
                <Button color="white" className={classes.floatRight}>
                  <ShoppingCart /> 0
                </Button>
              </GridItem>
            </GridContainer>
          </div> */}
                </Parallax>

                <div className={classNames(classes.section, classes.sectionGray)}>
                    <div className={classes.container}>
                        <div className={classNames(classes.main, classes.mainRaised)}>
                            <GridContainer >
                                <GridItem >
                                    <Button color="info" ><KeyboardArrowLeftIcon />Seguir comprando</Button>
                                </GridItem>
                            </GridContainer>
                            <GridContainer >

                                <GridItem md={7} sm={12}>
                                    <h2 >Mi carrito</h2>
                                    <ItemShop />
                                    <ItemShop />
                                </GridItem>
                                <GridItem md={5} sm={12}>
                                    <ResumenShop />
                                </GridItem>
                            </GridContainer>

                        </div>
                    </div>
                </div>

                <Footer
                    // theme="dark"
                    content={
                        <div>
                            <div className={classes.left}>
                                <List className={classes.list}>
                                    <ListItem className={classes.inlineBlock}>
                                        <a
                                            href="#"
                                            className={classes.block}
                                        >
                                            Términos y Condiciones
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a
                                            href="#"
                                            className={classes.block}
                                        >
                                            Politica de privacidad
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a
                                            href="//blog.creative-tim.com/"
                                            className={classes.block}
                                        >
                                            Blog
                                        </a>
                                    </ListItem>
                                </List>
                            </div>
                            <div className={classes.right}>
                                &copy; {1900 + new Date().getYear()} {" "}
                                <a
                                    href="#"
                                    className={classes.aClasses}
                                >
                                    Terco SA de CV
                                </a>
                            </div>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default withStyles(productStyle)(CartPage);
