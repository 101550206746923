import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Header from "../Generales/Header/Header.jsx";
import HeaderLinks from "../Generales/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.jsx";

import Globales from "utils/Globales";
import Cookies from "js-cookie";
import Cargando from "../Generales/Cargando.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link } from "react-router-dom";
import ModalDomicilio from "./ModalDomicilio.jsx";
import ModalCargando from "../Generales/Modales/ModalCargando.jsx";
import Loader from "react-loader-spinner";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import { FulfillingBouncingCircleSpinner } from "react-epic-spinners";

//import { CardHeader } from "@material-ui/core";
///Valor minimo para compra coneckta 22/01/2020
const CompraMinimaConekta = 3;

class ShoppingCartPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.AgregarQuitarCantidadProducto = this.AgregarQuitarCantidadProducto.bind(
      this
    );
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
    this.eventoModalDomicilio = this.eventoModalDomicilio.bind(this);
    this.CambiarPaqueteria = this.CambiarPaqueteria.bind(this);

    this.state = {
      TipoUsuario: JSON.parse(Cookies.get("UsuarioTerco")).TipoUsuario,
      ValorIvaSistema: 0,
      SaldoCliente: 0,
      SaldoClienteRestante: 0,
      AplicarSaldo: false,
      Tallas: [],
      selectedEnabled: "b",
      ListaCostosDeEnvio: [],
      ListaParaCotizar: [],
      CodigoPostalDestinatario: "",
      ListaCarrito: [],
      NombreAtributo: 0,
      cargando: false,
      PrecioFedex: 0,
      PrecioRedpack: 0,
      Domicilios: [],
      DomicilioSeleccionado: {},
      BotonComprar: "Continuar",
      PreciosEnvio: {
        RedpackNormal: 0,
        DiasRedpackNormal: 0,
        RedpackExpress: 0,
        DiasRedpackExpress: 0,
        FedexNormal: 0,
        DiasFedexNormal: 0,
        FedexExpress: 0,
        DiasFedexExpress: 0
      },
      DiasDeEntrega: 0,
      PrecioEnvio: 0,
      Paqueterias: [],
      Paqueteria: "",
      NombrePaqueteria: "",
      ServicioPaqueteria: "",
      PaqueteriaSeleccionada: [],

      /**usuario */
      NombreUsuario: JSON.parse(Cookies.get("UsuarioTerco")).Nombre,
      Correo: JSON.parse(Cookies.get("UsuarioTerco")).Correo,
      Telefono: JSON.parse(Cookies.get("UsuarioTerco")).Telefono,
      idUsuarioTerco: JSON.parse(Cookies.get("UsuarioTerco")).id,
      /**VARIABLES PARA VER */
      VerCostosEnvio: true,
      VerModalDomicilio: false,
      VerPreciosDias: false,
      /**ERROR */
      errorPaqueteria: false
    };

    /**variables globales para totales */
    global.Total = 0;
    global.Subtotal = 0;
    global.IVA = 0;
    global.Descuento = 0;
    global.Saldo = 0;
    global.TotalProductos = 0;
    global.Envio = 0;
    global.Index = 0;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    this.setState({ cargando: true });
    //  this.ObtenerCarrito();
    this.ObtenerDomiciliosUsuario(0);
    this.ObtenerSaldoCliente();
  }
  componentWillMount() {
    this.ObtenerTallas();
    this.obtenerEmpresa();
  }

  obtenerEmpresa() {
    fetch(Globales.Url + "Empresa/Empresa", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token
      },
      mode: 'cors'
    })
      .then(res => res.json())
      .then(data => {
        // eslint-disable-next-line no-empty
        if (data.error) {
        } else {
          this.setState({
            ValorIvaSistema: parseInt(data.data[0].ValorIVA)
          });
        }
      });
  }

  ObtenerSaldoCliente() {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ObtenerSaldoCliente', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }, body: JSON.stringify({ _id: JSON.parse(Cookies.get('UsuarioTerco')).id })
        }
        ).then(res => res.json())
      .then(data => {
        // eslint-disable-next-line no-empty
        if (data.error) {
        } else {
          this.setState({
            SaldoCliente: data.data.Saldo,
            SaldoClienteRestante: data.data.Saldo
          });
        }
      });
  }

    ObtenerTallas() {
        fetch(Globales.Url + 'Tallas/ObtenerTallas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
        }
        ).then(res => res.json())
            .then(data => {
                if (data.error) {

                } else {
                    this.setState({
                        Tallas: data.data
                    })
                }
            })
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: parseInt(value) });
    }
    ObtenerCarrito() {
        global.Index = 0;
        //console.log("Obtener carro");
        this.state.ListaCostosDeEnvio = [];
        this.state.Paqueterias = [];
        this.setState({ VerCostosEnvio: true })

        fetch(Globales.Url + 'Carrito/Carrito/ConsultarCarrito', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get('UsuarioTerco')).id })
        })
            .then(res => res.json())
            .then(data => {
                //console.log("🍺 / file: Carrito.jsx / line 214 / data", data)
                var ListaParaCotizar = []; 
                var listaCarritoNew = [];

                if (data.error) { //Error al obtener los registros
                    //console.log(data.error)
                } else { //Obtuvo los registros correctamente
                    var galleta = JSON.parse(Cookies.get("UsuarioTerco"));

                    galleta.Carrito = data.data.length;
                    Cookies.set('UsuarioTerco', JSON.stringify(galleta));
                    
                    if (data.data.length > 0) {
                        //console.log(data.data)

                        var IndexDescuento = 0;
                        //console.log("son la compras:--->", data.data)
                        data.data.map((m, indexCarrito) => {
                            m.idModelo = m.idDiseno.idModelo;
                            m.idDiseno.idModelo = m.idDiseno.idModelo._id;
                            var material = [];
                            m.idModelo.GruposMaterial.map(g => {
                                if (m.idMaterial == g.id) {

                                    material.push(g);
                                }
                            })
                            m.idModelo.GruposMaterial = material;
                            if(m.idModelo.GruposMaterial.length > 0){
                                listaCarritoNew.push(m);
                                m["Material"] = m.idModelo.GruposMaterial[0].NombreGrupo;
                                if (this.state.TipoUsuario == "Artista") {
                                    m["Comision"] = m.idModelo.GruposMaterial[0].PorcentajeComision;
                                }
                                m["PrecioTotal"] = m.Cantidad * m.idModelo.GruposMaterial[0].PrecioPublico;
                                this.ValidarDescuentos(galleta.id, m.idDiseno._id).then((DescuentoAplicable) => {
                                    IndexDescuento++;
                                    if (m.idDiseno.Descuento == true && m.Cantidad >= m.idDiseno.CantidadUnidades && DescuentoAplicable.data == true) {
                                        if (m.idDiseno.TipoDescuento == 0) {
                                            /**porcentaje */
                                            var descuento = (m.idModelo.GruposMaterial[0].PrecioSinIva * m.Cantidad) * (m.idDiseno.TotalDescuento / 100);
                                            m["Descuento"] = descuento;
                                            m.idModelo.GruposMaterial[0].valorIva = (((m.idModelo.GruposMaterial[0].PrecioSinIva * m.Cantidad) - descuento) * (this.state.ValorIvaSistema / 100))
                                            m.idModelo.GruposMaterial[0].valorIva = m.idModelo.GruposMaterial[0].valorIva / 4;
                                            m["PrecioTotal"] = ((m.idModelo.GruposMaterial[0].PrecioSinIva) + m.idModelo.GruposMaterial[0].valorIva) * m.Cantidad;
                                        }
                                        if (m.idDiseno.TipoDescuento == 1) {
                                            /**entero */
                                            m["Descuento"] = 10;
                                        }
                                    } else {
                                        m["Descuento"] = 0;
                                    }

                                    if (m.idDiseno.idCliente == galleta.id && m.idDiseno.EnTienda == true) {
                                        var DescuentoComision = m.idModelo.GruposMaterial[0].PrecioSinIva * (m.Comision / 100).toFixed(2);
                                    }

                                    if (this.state.DomicilioSeleccionado !== null) {
                                        ListaParaCotizar.push({ _id: m._id, idMateriaPrima: m.idModelo.idMateriaPrima, Cantidad: m.Cantidad, Peso: m.idModelo.Peso, CodigoPostalDestinatario: this.state.DomicilioSeleccionado.CodigoPostal,DomicilioSeleccionado:this.state.DomicilioSeleccionado })

                                    }
                                    this.setState({
                                        ListaParaCotizar: ListaParaCotizar,
                                        ListaCarrito: listaCarritoNew,
                                        cargando: false

                                    }, () => {
                                        this.CalcularTotales();
                                        if (IndexDescuento == data.data.length) {
                                            if (this.state.DomicilioSeleccionado !== null && data.data.length > 0) {
                                                this.ObtenerCostosDeEnvio();
                                            }
                                        }
                                    });

                                })
                            }
                        })

                    } else {
                        this.setState({
                            ListaParaCotizar: ListaParaCotizar,
                            ListaCarrito: data.data,
                            cargando: false
                        })

                    }
                }
            });
    }
    async ValidarDescuentos(idCliente, idDiseno) {

        var DescuentoAplicable = await fetch(Globales.Url + 'Descuentos/Descuentos/VerificarClienteConDescuentoAplicable', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }, body: JSON.stringify({ idCliente: idCliente, idDiseno: idDiseno })
        }
        )

        DescuentoAplicable = DescuentoAplicable.json();
        return DescuentoAplicable;

    }
    ObtenerCostosDeEnvio() {
        //idMateriaPrima Cantidad Peso CodigoPostalDestinatario
        if (global.Index < this.state.ListaParaCotizar.length) {
            fetch(Globales.Url + 'Carrito/Carrito/ObtenerCostosDeEnvioDemo', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                }, body: JSON.stringify(this.state.ListaParaCotizar[global.Index])
            })
                .then(res => res.json())
                .then(data => {
                    //console.log("data")
                    //console.log(data)
                    //console.log(this.state.ListaParaCotizar[global.Index])
                    if (data.error) { //Error al obtener los registros
                        //console.log(data);
                        //console.log(this.state.ListaParaCotizar[global.Index])
                        this.showNotification(data.mensaje, "warning")

                    } else { //Obtuvo los registros correctamentes

                        
                        ////console.log(data.data)
                        ////console.log(this.state.ListaParaCotizar[global.Index])
                        if (data.error == false) {
                            if(this.state.ListaParaCotizar[global.Index] != undefined){
                                data.data.rates.map(m => {
                                    if (m.provider == "REDPACK" || m.provider == "FEDEX") {
                                        this.state.ListaCostosDeEnvio.push({
                                            idModelo: this.state.ListaParaCotizar[global.Index]._id,
                                            Precio: parseInt(m.amount_local),
                                            Paqueteria: m.provider,
                                            Servicio: m.service_level_code,
                                            Dias: m.days,
                                            Id: m.id
                                        })
                                    }
                                })
                            }
                        } else {
                            //console.log(data);
                            //console.log(this.state.ListaParaCotizar[global.Index])
                            this.showNotification("Servicio no disponible", "danger")
                        }


                        // if ( && m.service_level_name == "Fedex Express Saver") {
                        //   this.state.ListaCostosDeEnvio.push({ idModelo: this.state.ListaParaCotizar[global.Index]._id,Precio: parseInt(m.amount_local), Paqueteria: "FEDEX" })

                        // }

                        global.Index++;
                        this.ObtenerCostosDeEnvio()
                    }
                })
                .catch(err => {
                    //console.log(err);
                    this.showNotification(err, "warning")
                });
        } else {
            this.ColocarPreciosEnvio();
            // this.setState({ cargando: false });
        }

    }
    ColocarPreciosEnvio() {

        /**LIMPIAR PRECIOS DE ENVIO */
        this.state.PreciosEnvio.RedpackNormal = 0;
        this.state.PreciosEnvio.RedpackExpress = 0;
        this.state.PreciosEnvio.FedexNormal = 0;
        this.state.PreciosEnvio.FedexExpress = 0;


        var ListaRedpack = {
            ECOEXPRESS: [],
            EXPRESS: []
        }
        var ListaFedex = {
            FEDEX_EXPRESS_SAVER: [],
            STANDARD_OVERNIGHT: []
        }


        /**Separar costos por paqueteria */
        this.state.ListaCostosDeEnvio.map(m => {

            if (m.Paqueteria == "REDPACK") {
                if (m.Servicio == "ECOEXPRESS") {
                    ListaRedpack.ECOEXPRESS.push(m)
                }
                if (m.Servicio == "EXPRESS") {
                    ListaRedpack.EXPRESS.push(m)
                }
            }
            if (m.Paqueteria == "FEDEX") {
                if (m.Servicio == "FEDEX_EXPRESS_SAVER") {
                    ListaFedex.FEDEX_EXPRESS_SAVER.push(m)
                }
                if (m.Servicio == "STANDARD_OVERNIGHT") {
                    ListaFedex.STANDARD_OVERNIGHT.push(m)
                }
            }
        })


        var Precios = this.state.PreciosEnvio;

        /**PRECIO NORMAL */

        if (ListaRedpack.ECOEXPRESS.length > 0) {
            ListaRedpack.ECOEXPRESS.map(r => {
                Precios.RedpackNormal = Precios.RedpackNormal + r.Precio;
            })
            Precios.DiasRedpackNormal = ListaRedpack.ECOEXPRESS[0].Dias;
            this.state.Paqueterias.push({ Nombre: "REDPACK NORMAL", id: 0 ,data:ListaRedpack.ECOEXPRESS[0]})
        }

        /**PRECIO EXPRESS */
        if (ListaRedpack.EXPRESS.length > 0) {
            ListaRedpack.EXPRESS.map(r => {
                Precios.RedpackExpress = Precios.RedpackExpress + r.Precio;
            })
            Precios.DiasRedpackExpress = ListaRedpack.EXPRESS[0].Dias;
            this.state.Paqueterias.push({ Nombre: "REDPACK EXPRESS", id: 1 ,data:ListaRedpack.EXPRESS[0]})

        }

        if (ListaFedex.FEDEX_EXPRESS_SAVER.length > 0) {
            ListaFedex.FEDEX_EXPRESS_SAVER.map(r => {
                Precios.FedexNormal = Precios.FedexNormal + r.Precio;
            })
            Precios.DiasFedexNormal = ListaFedex.FEDEX_EXPRESS_SAVER[0].Dias;
            this.state.Paqueterias.push({ Nombre: "FEDEX NORMAL", id: 2,data:ListaFedex.FEDEX_EXPRESS_SAVER[0] })

        }

        if (ListaFedex.STANDARD_OVERNIGHT.length > 0) {

            ListaFedex.STANDARD_OVERNIGHT.map(r => {
                Precios.FedexExpress = Precios.FedexExpress + r.Precio;
            })
            Precios.DiasFedexExpress = ListaFedex.STANDARD_OVERNIGHT[0].Dias;
            this.state.Paqueterias.push({ Nombre: "FEDEX EXPRESS", id: 3 ,data:ListaFedex.STANDARD_OVERNIGHT[0]})

        }

        this.setState({
            VerCostosEnvio: false,
            PreciosEnvio: Precios
        }, () => {

            if (this.state.Paqueteria != "") {

                this.state.VerPreciosDias = true;
                this.setState({
                    VerPreciosDias: true
                });
                /**r REDPACK NORMAL */
                if (this.state.Paqueteria == 0) {
                    this.setState({
                        DiasDeEntrega: this.state.PreciosEnvio.DiasRedpackNormal,
                        PrecioEnvio: this.state.PreciosEnvio.RedpackNormal,
                    });
                    // global.Envio = 10;

                }
                /**REDPACK EXPRESS */
                if (this.state.Paqueteria == 1) {
                    this.setState({
                        DiasDeEntrega: this.state.PreciosEnvio.DiasRedpackExpress,
                        PrecioEnvio: this.state.PreciosEnvio.RedpackExpress,
                    });
                    global.Envio = this.state.PreciosEnvio.RedpackExpress;

                }
                /**FEDEX NORMAL */
                if (this.state.Paqueteria == 2) {
                    this.setState({
                        DiasDeEntrega: this.state.PreciosEnvio.DiasFedexNormal,
                        PrecioEnvio: this.state.PreciosEnvio.FedexNormal,
                    });
                    global.Envio = this.state.PreciosEnvio.FedexNormal;

                }
                /**FEDEX EXPRESS */
                if (this.state.Paqueteria == 3) {

                    this.setState({
                        DiasDeEntrega: this.state.PreciosEnvio.DiasFedexExpress,
                        PrecioEnvio: this.state.PreciosEnvio.FedexExpress,
                    });
                    global.Envio = this.state.PreciosEnvio.FedexExpress;

                }

                this.CalcularTotales();
            }
        });

    }

    EliminarProductoCarrito(id) {

        fetch(Globales.Url + 'Carrito/Carrito/EliminarProductoCarrito', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }, body: JSON.stringify({ _id: id })
        })
            .then(res => res.json())
            .then(data => {
                if (data.error) { //Error al obtener los registros

                } else { //Obtuvo los registros correctamente
                    this.showNotification(data.mensaje, "danger")
                    this.ObtenerCarrito();
                }
            });
    }


    AgregarQuitarCantidadProducto(opcion) {
        if (opcion == 1) {
      this.setState({ NombreAtributo: this.state.NombreAtributo + 1 });
        } else {
            if ((this.state.NombreAtributo - 1) > 0) {
                this.setState({ NombreAtributo: this.state.NombreAtributo - 1 })
            } else {
                this.setState({ NombreAtributo: 0 })
            }
        }
    }

    AgregarPiezas(idModelo, Opcion) {
        this.state.ListaCarrito.map(m => {
            if (m._id == idModelo) {
                if (Opcion == true) {
                    m.Cantidad = m.Cantidad + 1;
                } else {
                    if (m.Cantidad != 0) {
                        m.Cantidad = m.Cantidad - 1;
                    }
                }
                /**CALCULAR TOTALES */
                m.PrecioTotal = m.Cantidad * m.idModelo.PrecioPublico;
            }
        })
        this.CalcularTotales()
        //  this.CalcularTotales();
        this.setState({ a: "a" })
    }
    CalcularTotales() {
        global.Subtotal = 0;
        global.Total = 0;
        global.IVA = 0;
        global.Descuento = 0;
        // global.Saldo = 0;
        global.TotalProductos = 0;
        this.state.ListaCarrito.map(m => {

            global.TotalProductos = global.TotalProductos + m.Cantidad;
            if (m.idDiseno.Descuento == true) {
                global.Descuento = global.Descuento + m.Descuento;
                global.Subtotal = (global.Subtotal + ((m.idModelo.GruposMaterial[0].PrecioSinIva) * m.Cantidad))
            } else {
                global.Subtotal = (global.Subtotal + (m.idModelo.GruposMaterial[0].PrecioSinIva * m.Cantidad))
            }
            global.IVA = (global.IVA + (m.idModelo.GruposMaterial[0].valorIva * m.Cantidad));
            global.Total = global.Subtotal + global.IVA - global.Descuento - global.Saldo;

        })
        global.Total =  Number((global.Total + global.Envio).toFixed(2));
        this.setState({ a: "a" });
    }

    CambiarFormatoPrecio(precio) {
    var num =
      "$" +
      parseFloat(precio, 10)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        .toString();
    return num;
  }
  showNotification(Mensaje, Color) {
    var x = [];
    this.setState({
      colorNotificacion: Color,
      mensaje: Mensaje,
      br: true
    });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      3000
    );
  }
  handleChangeEnabled(event) {
    this.setState({ selectedEnabled: event.target.value });
  }

    eventoModalDomicilio(domicilio, opcion) {

        if (opcion == "cambio") {

            this.state.Domicilios.map(m => {
                if (m._id == domicilio._id) {
                    m.Usado = true
                    this.setState({
                        Paqueteria: "",
                        DomicilioSeleccionado: m,
                        VerCostosEnvio: true,
                        VerPreciosDias: false
                    }, () => {
                        global.Index = 0;
                        this.state.ListaCostosDeEnvio = [];
                        this.state.Paqueterias = [];
                        this.GenerarListaParaCotizar();
                        this.ObtenerCostosDeEnvio()
                    });
                } else {
                    m.Usado = false
                }
            })
        }

        if (opcion == "nuevo") {
            this.setState({
                Paqueteria: "",
                VerCostosEnvio: true,
                VerPreciosDias: false,
                ListaCostosDeEnvio: [],
                Paqueterias: []
            })
            global.Index = 0;
            this.ObtenerDomiciliosUsuario(0);
        }

        this.setState({
            VerModalDomicilio: false
        });
    }
    GenerarListaParaCotizar() {
        var lista = [];
        this.state.ListaCarrito.map(m => {
            lista.push({ _id: m._id, idMateriaPrima: m.idModelo.idMateriaPrima, Cantidad: m.Cantidad, Peso: m.idModelo.Peso, CodigoPostalDestinatario: this.state.DomicilioSeleccionado.CodigoPostal })
        })

        this.setState({
            ListaParaCotizar: lista
        })
    }
    ObtenerDomiciliosUsuario(op) {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ObtenerDomicilioPorUsuario', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }, body: JSON.stringify({ _id: JSON.parse(Cookies.get('UsuarioTerco')).id })
        })
            .then(res => res.json())
            .then(data => {
                if (data.error) { //Error al obtener los registros

                } else { //Obtuvo los registros correctamente
                    if (data.data.Domicilios != undefined) {
                        //console.log("los domi------------------>", data.data)
                        if (data.data.Domicilios.length >= 0) {
                            var ultimoDomicilio = null;
                            data.data.Domicilios.map(d => {
                                if (d.Usado == true) {
                                    ultimoDomicilio = d
                                }
                            })
                            this.setState({
                                Domicilios: data.data.Domicilios,
                                DomicilioSeleccionado: ultimoDomicilio
                            }, () => {
                                if (op == 0) {
                                    this.ObtenerCarrito();

                                }
                            });
                        }
                    }
                }
            });
    }

    PreRegistroCompra() {
        var datos = {
            obj1: null,
            obj2: null,
            obj2: null,
        }
        datos.obj1 = this.state.ListaCarrito;
        datos.obj2 = global.Envio;
        datos.obj3 = {
            Calle: this.state.DomicilioSeleccionado.Calle,
            Colonia: this.state.DomicilioSeleccionado.Colonia,
            CodigoPostal: this.state.DomicilioSeleccionado.CodigoPostal
        };
        fetch(Globales.Url + 'Compras/RegistrarCompra', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get('UsuarioTerco')).id, DatosCompra: datos })
        })
            .then(res => res.json())
            .then(data => {
                if (data.error) { //Error al obtener los registros

                } else { //Obtuvo los registros correctamente
                    localStorage.setItem("idCompra", data.data);

                    //  document.getElementById("ConfirmarCompra").click();
                    window.location.href = '/#/confirmar-compra';




                }
            });
    }

    ConfirmarCompra() {

        if (this.ValidarDatos()) {
            /**Preparar los datos para hacer el pago */
            var ArregloFinal = [];
            var Productos = [];
            var CostoEnvio = [{ amount: global.Envio, carrier: this.state.Paqueteria }];
            // var CostoEnvio = [{ amount: 150, carrier: "FEDEX" }];
            var Usuario = { name: this.state.NombreUsuario, email: this.state.Correo, phone: this.state.DomicilioSeleccionado.TelefonoReferencia };
            var Domicilio = {
                address: {
                    street1: this.state.DomicilioSeleccionado.Calle,
                    postal_code: this.state.DomicilioSeleccionado.CodigoPostal,
                    country: "MX",
                }
            };
            var metodo = [{
                payment_method: {
                    type: "card",
                    token_id: ""
                }
            }]

            this.state.ListaCarrito.map(producto => {
                Productos.push({
                    name: producto.idModelo.NombreProducto,
                    unit_price: (producto.idModelo.GruposMaterial[0].PrecioPublico * 100),
                    // unit_price:1000,
                    quantity: producto.Cantidad,
                })
                //console.log("enviios....")
                this.state.ListaCostosDeEnvio.map(envio => {
                    //console.log(envio)
                    if (envio.idModelo == producto._id && envio.Paqueteria == this.state.NombrePaqueteria && envio.Servicio == this.state.ServicioPaqueteria) {

                        CostoEnvio[0].amount = CostoEnvio[0].amount;
                        CostoEnvio[0].carrier = envio.Paqueteria;
                        CostoEnvio[0].days = envio.days;
                    }
                })
            })
            CostoEnvio[0].amount = CostoEnvio[0].amount * 100;

            ArregloFinal = {
                line_items: Productos,
                shipping_lines: CostoEnvio,
                customer_info: Usuario,
                shipping_contact: Domicilio,
                charges: metodo,
                Productos: this.state.ListaCarrito,
                Domicilio: this.state.DomicilioSeleccionado,
                Total: global.Total,
                Saldo: global.Saldo,
                PaqueteriaSeleccionada: this.state.PaqueteriaSeleccionada

            }
            localStorage.setItem("ValoresParaCompra", JSON.stringify(ArregloFinal))


            this.setState({
                BotonComprar: "Procesando"
            });

            var datos = {
                ListaCarrito: null,
                Envio: null,
                DatosEnvio: null,
            }
            datos.ListaCarrito = this.state.ListaCarrito;
            datos.Envio = global.Envio;


            datos.DatosEnvio = {
                Calle: this.state.DomicilioSeleccionado.Calle,
                Colonia: this.state.DomicilioSeleccionado.Colonia,
                CodigoPostal: this.state.DomicilioSeleccionado.CodigoPostal
            };
            //localStorage.setItem("ValoresParaCompra2", JSON.stringify(datos))


            document.getElementById("ConfirmarCompra").click();


            //  this.PreRegistroCompra()
        } else {
            this.showNotification("Faltan datos por capturar", "warning")

        }




    }
    ValidarDatos() {
        var valido = true;

        if (this.state.Paqueteria === "") {
            valido = false;
            this.setState({
                errorPaqueteria: true
            });
        } else {
            this.setState({
                errorPaqueteria: false
            });
        }
        if (this.state.DiasDeEntrega == "") {
            valido = false;
        }
        if (this.state.PrecioEnvio == "") {
            valido = false;
        }
        // return true;
        return valido;
    }
    CambiarPaqueteria(e) {

        this.setState({
            [e.target.name]: e.target.value,
            VerPreciosDias: true,
            errorPaqueteria: false
        }, () => {
            /**r REDPACK NORMAL */
            if (e.target.value.id == 0) {
                this.setState({
                    DiasDeEntrega: this.state.PreciosEnvio.DiasRedpackNormal,
                    PrecioEnvio: this.state.PreciosEnvio.RedpackNormal,
                    NombrePaqueteria: "REDPACK",
                    ServicioPaqueteria: "ECOEXPRESS"
                });
                global.Envio = this.state.PreciosEnvio.RedpackNormal;

            }
            /**REDPACK EXPRESS */
            if (e.target.value.id == 1) {
                this.setState({
                    DiasDeEntrega: this.state.PreciosEnvio.DiasRedpackExpress,
                    PrecioEnvio: this.state.PreciosEnvio.RedpackExpress,
                    NombrePaqueteria: "REDPACK",
                    ServicioPaqueteria: "EXPRESS"


                });
                global.Envio = this.state.PreciosEnvio.RedpackExpress;

            }
            /**FEDEX NORMAL */
            if (e.target.value.id == 2) {
                this.setState({
                    DiasDeEntrega: this.state.PreciosEnvio.DiasFedexNormal,
                    PrecioEnvio: this.state.PreciosEnvio.FedexNormal,
                    NombrePaqueteria: "FEDEX",
                    ServicioPaqueteria: "FEDEX_EXPRESS_SAVER"


                });
                global.Envio = this.state.PreciosEnvio.FedexNormal;

            }
            /**FEDEX EXPRESS */
            if (e.target.value.id == 3) {
                this.setState({
                    DiasDeEntrega: this.state.PreciosEnvio.DiasFedexExpress,
                    PrecioEnvio: this.state.PreciosEnvio.FedexExpress,
                    NombrePaqueteria: "FEDEX",
                    ServicioPaqueteria: "STANDARD_OVERNIGHT"

                });
                global.Envio = this.state.PreciosEnvio.FedexExpress;

            }
            this.setState({
                PaqueteriaSeleccionada: e.target.value
            })
            global.Saldo = 0;
            this.CalcularTotales();
            if (this.state.AplicarSaldo == true) {

                var total = global.Total - this.state.SaldoCliente;
                if (total > 0 && total <= 3) {
                }

                if (total == 0) {
                    global.Saldo = this.state.SaldoCliente;
                }

                if (total < 0) {
                    global.Saldo = this.state.SaldoCliente - (total * -1)
                    this.setState({
                        SaldoClienteRestante: total * -1
                    })
                }

                if (total >= 0) {
                    global.Saldo = this.state.SaldoCliente;
                    this.setState({
                        SaldoClienteRestante: 0
                    })
                }


            }
            this.CalcularTotales();

        });

    }
    ObtenerNombreTamano(id) {
        var nombre = "";
        this.state.Tallas.map(m => {
            if (m._id == id) {
                nombre = m.NombreTalla;
            }
        })
        return nombre;
    }
    handleChangeSaldo = name => event => {
        if (event.target.checked == true) {

            var total = global.Total - this.state.SaldoCliente;
            if (total > 0 && total <= 3) {
            }

            if (total == 0) {
                global.Saldo = this.state.SaldoCliente;
            }

            if (total < 0) {
                global.Saldo = this.state.SaldoCliente - (total * -1)
                this.setState({
                    SaldoClienteRestante: total * -1
                })
            }

            if (total >= 0) {
                global.Saldo = this.state.SaldoCliente;
                this.setState({
                    SaldoClienteRestante: 0
                })
            }


        } else {
            global.Saldo = 0;
            this.setState({ SaldoClienteRestante: this.state.SaldoCliente })

        }
        this.CalcularTotales();
        this.setState({ [name]: event.target.checked });

    };

    render() {

        const { classes } = this.props;
        const styleTotal = {
            color: "#FFFFFF",
            textDecoration: "none",
            fontWeight: "700",
            marginTop: "30px",
            marginBottom: "25px",
            minHeight: "32px",
            fontFamily: `"Roboto Slab", "Times New Roman", serif`,
            marginTop: ".625rem",
            marginBottom: "0.75rem",
            minHeight: "auto"
        }

        const containerFluid = {
            paddingRight: "15px",
            paddingLeft: "15px",
            marginRight: "auto",
            marginLeft: "auto",
            width: "100%"
        };
        const container = {
            ...containerFluid,
            "@media (min-width: 576px)": {
                maxWidth: "540px"
            },
            "@media (min-width: 768px)": {
                maxWidth: "720px"
            },
            "@media (min-width: 992px)": {
                maxWidth: "960px"
            },
            "@media (min-width: 1200px)": {
                maxWidth: "1920px"
            }
        };

        var Encabezado = ["", "Nombre", "Tamaño", "Material", "Precio", "IVA", "Piezas", "Total", "Descuento"];

        /*if (this.state.TipoUsuario == "Artista") {
            Encabezado.push(
                <Tooltip title="Porcentaje de comisón que obtendrías por cada producto vendido." placement="top" classes={{ tooltip: classes.tooltip }}>
                    <span>Comisión<HelpOutline fontSize="small" color="action" /></span>
                </Tooltip>)
        }*/



        var Tabla = [];
        return (
            <div>
                <Header links={<HeaderLinks dropdownHoverColor="dark" />}
                    fixed
                    color="dark"
                />

                <Parallax
                    image={require("assets/img/border.svg")}
                    // filter="dark"
                    style={{
                        height: "20vh",
                        overflow: "hidden"
                    }}
                >
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem
                                md={8}
                                sm={8}
                                className={classNames(
                                    classes.mlAuto,
                                    classes.mrAuto,
                                    classes.textCenter
                                )}
                            >

                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <Parallax
                    // filter="dark"
                    style={{
                        height: "10vh",
                        overflow: "hidden"
                    }}
                ></Parallax>
                {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
                <div style={container}>
                    <div>
                        <div>
                            <GridContainer style={{
                                marginRight: "25px",
                                marginLeft: "25px",
                                width: "auto"
                            }}
                                justify="center">
                                <GridItem>
                                    <h2 className={classes.cardTitle}>Carrito</h2>
                                </GridItem>
                            </GridContainer>
                            <GridContainer style={{
                                marginRight: "25px",
                                marginLeft: "25px",
                                width: "auto"
                            }}
                                justify="center">

                                <GridItem lg={8} md={12} sm={12} xs={12}  >
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>

                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12} >

                                                    <Card>
                                                        {/* <CardHeader >
                              <h4 >Example</h4>

                            </CardHeader> */}
                                                        <CardBody>

                                                            {
                                                                
                                                                this.state.cargando == true ? <Cargando /> : this.state.ListaCarrito.length > 0
                                                                    ?

                                                                    <Table
                                                                        striped
                                                                        tableHead={Encabezado}
                                                                        tableData={
                                                                            this.state.ListaCarrito.map(producto =>
                                                                                [

                                                                                    <Tooltip
                                                                                        id="close1"
                                                                                        title="Eliminar"
                                                                                        placement="left"
                                                                                        classes={{ tooltip: classes.tooltip }}

                                                                                    >
                                                                                        <Button onClick={() => this.EliminarProductoCarrito(producto._id)} link className={classes.actionButton}>
                                                                                            <Close />
                                                                                        </Button>
                                                                                    </Tooltip>,
                                                                                    producto.idDiseno.nombre,
                                                                                    this.ObtenerNombreTamano(producto.idModelo.Tamano),
                                                                                    producto.Material,
                                                                                    <span>
                                                                                        {this.CambiarFormatoPrecio(producto.idModelo.GruposMaterial[0].PrecioSinIva)}
                                                                                    </span>,

                                                                                    this.CambiarFormatoPrecio(producto.idModelo.GruposMaterial[0].valorIva),
                                                                                    <span>
                                                                                        {producto.Cantidad}
                                                                                        {/* <GridContainer>

                                              <button className="botonMasMenos" onClick={() => this.AgregarPiezas(producto._id, false)}><Remove /></button>

                                              <button className="botonCantidad" disabled >
                                                <span style={{ fontSize: '12px' }}> {producto.Cantidad}</span>
                                              </button>

                                              <button className="botonMasMenos" onClick={() => this.AgregarPiezas(producto._id, true)}> <span ><Add /></span> </button>
                                            </GridContainer> */}
                                                                                    </span>,

                                                                                    this.CambiarFormatoPrecio(producto.PrecioTotal),
                                                                                    this.CambiarFormatoPrecio(producto.Descuento),

/*                                                                                     <span> {this.state.TipoUsuario == "Artista" ? <span>{this.CambiarFormatoPrecio(producto.idModelo.GruposMaterial[0].PrecioSinIva * (producto.Comision / 100))} ({producto.Comision}%)</span> : ""}  </span>
 */                                                                                    // <Button round size='sm' color="github">Ver diseño 2D </Button>,
                                                                                ],
                                                                            )





                                                                        }


                                                                    /> : <h5>No tienes productos en tu carrito</h5>
                                                            }

                                                        </CardBody>
                                                        <CardFooter>

                                                        </CardFooter>

                                                    </Card>

                                                </GridItem>
                                            </GridContainer>

                                        </GridItem>

                                        {
                                            this.state.ListaCarrito.length > 0 ?
                                                <GridItem lg={12}>
                                                    <GridContainer>
                                                        <GridItem lg={12}>


                                                            <h4>Seleccionar dirección de envío</h4>
                                                            <Card >

                                                                <CardBody  >

                                                                    {
                                                                        this.state.Domicilios.length > 0 ?
                                                                            <GridContainer>
                                                                                <GridItem lg={10}>
                                                                                    <div style={{ marginTop: '20px' }}>
                                                                                        <span style={{ fontSize: '17px', }}>
                                                                                            {this.state.DomicilioSeleccionado.Calle}  {this.state.DomicilioSeleccionado.Colonia}  C.P. {this.state.DomicilioSeleccionado.CodigoPostal}

                                                                                        </span>

                                                                                    </div>
                                                                                </GridItem>
                                                                                <GridItem lg={2}>
                                                                                    <Button color="transparent" onClick={() => this.setState({ VerModalDomicilio: true })} style={{ color: "#333", textDecoration: "underline" }} >Cambiar</Button>
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                            :
                                                                            <GridContainer>
                                                                                <GridItem lg={10}>
                                                                                    <Button color="transparent" onClick={() => this.setState({ VerModalDomicilio: true })} style={{ color: "#333", textDecoration: "underline" }} >Agrega un domicilio</Button>
                                                                                </GridItem>

                                                                            </GridContainer>

                                                                    }




                                                                </CardBody>

                                                            </Card>
                                                            <h4>Seleccionar paquetería</h4>


                                                            <Card >
                                                                <CardBody  >
                                                                    {
                                                                        this.state.DomicilioSeleccionado !== null ?


                                                                            this.state.VerCostosEnvio == true ?
                                                                                <GridContainer>

                                                                                    <GridItem lg={12}>
                                                                                        <div style={{ float: 'left' }}>
                                                                                            Obteniendo información de envío
                                                                                        </div>
                                                                                        <div style={{ float: 'left', marginLeft: "15px" }}>
                                                                                            <Loader visible={true} type="Oval" color="#212121" height={20} width={20} />

                                                                                        </div>
                                                                                    </GridItem>
                                                                                </GridContainer>
                                                                                :
                                                                                <GridContainer>
                                                                                    <GridItem lg={4} md={4} sm={4} xs={12} >
                                                                                        <FormControl fullWidth className={classes.selectFormControl} error={this.state.errorPaqueteria}>
                                                                                            <InputLabel
                                                                                                htmlFor="simple-select"
                                                                                                className={classes.selectLabel}
                                                                                            >
                                                                                                Seleccione
                                                                                            </InputLabel>
                                                                                            <Select
                                                                                                MenuProps={{
                                                                                                    className: classes.selectMenu
                                                                                                }}
                                                                                                classes={{
                                                                                                    select: classes.select
                                                                                                }}
                                                                                                value={this.state.Paqueteria}
                                                                                                onChange={this.CambiarPaqueteria}
                                                                                                inputProps={{
                                                                                                    name: "Paqueteria",
                                                                                                    id: "Paqueteria",

                                                                                                }}

                                                                                            >

                                                                                                <MenuItem value={""} disabled classes={{ root: classes.selectMenuItem }}>Seleccione</MenuItem>
                                                                                                {
                                                                                                    this.state.Paqueterias.map(m =>
                                                                                                        <MenuItem value={m} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} >
                                                                                                            {m.Nombre}
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                }





                                                                                            </Select>

                                                                                        </FormControl>


                                                                                    </GridItem>
                                                                                    {
                                                                                        this.state.VerPreciosDias ? <GridItem lg={8} md={8} sm={12} xs={12} >
                                                                                            <div style={{ marginTop: '20px' }}>
                                                                                                <span style={{ fontSize: '17px' }}>
                                                                                                    Entrega en {this.state.DiasDeEntrega} días despues de fabricado
                                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                                                                                    <b>  Precio: {'$' + parseFloat(this.state.PrecioEnvio, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()}</b>
                                                                                                </span>
                                                                                            </div>

                                                                                        </GridItem> : null
                                                                                    }
                                                                                    {/* {
                                            this.state.VerPreciosDias ?
                                              <GridItem lg={4} >
                                                <span>
                                                  Precio: {'$' + parseFloat(this.state.PrecioEnvio, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()}
                                                </span>
                                              </GridItem>
                                              : null
                                          } */}
                                                                                </GridContainer>
                                                                            : "Ingrese una dirección de envío"
                                                                    }
                                                                </CardBody>
                                                            </Card>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                : null
                                        }


                                    </GridContainer>
                                    {/**RESUMEN COMPRA */}

                                    <GridContainer style={{
                                        marginRight: "100px",
                                        marginLeft: "100px",
                                        width: "auto"
                                    }}
                                        justify="center">


                                        <GridItem   >

                                        </GridItem>



                                    </GridContainer>

                                </GridItem>

                                <GridItem md={4} lg={4}>
                                    {
                                        this.state.ListaCarrito.length > 0 ?
                                            <Card >
                                                <CardHeader   >
                                                    <GridContainer>

                                                        <GridItem>
                                                            &nbsp;

                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>

                                                        <GridItem>
                                                            <h4>Resumen de compra</h4>
                                                        </GridItem>

                                                        <GridItem>
                                                            <hr />
                                                        </GridItem>
                                                    </GridContainer>

                                                </CardHeader>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridItem className={`${classes.textRight}`} xs={7} sm={5} md={5} >
                                                            <span  >Productos ({global.TotalProductos}):  </span><br /><br />
                                                            {/* <span  >Subtotal: </span><br /> */}
                                                            <span >IVA: </span><br /><br />
                                                            <span >Envío: </span><br /><br />
                                                            <span  >Descuento: </span> <br /><br />
                                                            <span  >Saldo: </span> <br /><br />
                                                            <span  >Total: </span>

                                                        </GridItem>

                                                        <GridItem className={`${classes.textRight}`} xs={5} sm={5} md={5} >
                                                            {/* <span  >  {global.TotalProductos} </span><br /> */}
                                                            <span  >{this.CambiarFormatoPrecio(global.Subtotal)}</span><br /><br />
                                                            <span >{this.CambiarFormatoPrecio(global.IVA)}</span><br /><br />
                                                            <span >{this.CambiarFormatoPrecio(global.Envio)}</span><br /><br />
                                                            <span  >{this.CambiarFormatoPrecio(global.Descuento)}</span> <br /><br />
                                                            <span  >{this.CambiarFormatoPrecio(global.Saldo)}</span> <br /><br />
                                                            <span  >{this.CambiarFormatoPrecio(global.Total)}</span>

                                                        </GridItem>
                                                        <GridItem className={`${classes.textCenter}`} xs={12} sm={12} md={12} >
                                                            <br />
                                                            {
                                                                this.state.SaldoCliente > 0 ?
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                // checked={this.state.checkedA}
                                                                                onChange={this.handleChangeSaldo("AplicarSaldo")}
                                                                                value={this.state.AplicarSaldo}
                                                                                name={"AplicarSaldo"}
                                                                                color="primary"
                                                                                size="small"


                                                                                classes={{
                                                                                    switchBase: classes.switchBase,
                                                                                    checked: classes.switchChecked,
                                                                                    icon: classes.switchIcon,
                                                                                    iconChecked: classes.switchIconChecked,
                                                                                    bar: classes.switchBar,
                                                                                }}
                                                                            />
                                                                        }
                                                                        classes={{
                                                                            label: classes.label
                                                                        }}
                                                                        label={
                                                                            this.state.AplicarSaldo == true ?
                                                                                "Saldo restante " + this.CambiarFormatoPrecio(this.state.SaldoClienteRestante) :
                                                                                "Utilizar Saldo " + this.CambiarFormatoPrecio(this.state.SaldoClienteRestante)}
                                                                    />
                                                                    : null
                                                            }




                                                        </GridItem>
                                                        <GridItem className={`${classes.textCenter}`} xs={12} sm={12} md={12} >
                                                            <hr />

                                                            <Button disabled={this.state.BotonComprar == "Continuar" ? false : true} onClick={() => this.ConfirmarCompra()} size="md" color="github">{this.state.BotonComprar} &nbsp;
                                                                {this.state.BotonComprar != "Continuar" ? <FulfillingBouncingCircleSpinner size={20} color="white" /> : null}
                                                            </Button>

                                                            <div>


                                                            </div>



                                                        </GridItem>


                                                    </GridContainer>
                                                </CardBody>
                                                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

                                            </Card>
                                            : null
                                    }





                                </GridItem>






                            </GridContainer>
                            {
                                this.state.VerModalDomicilio ?
                                    <ModalDomicilio eventoModal={this.eventoModalDomicilio} verModal={this.state.VerModalDomicilio} domicilios={this.state.Domicilios} idCliente={JSON.parse(Cookies.get('UsuarioTerco')).id} />

                                    : null
                            }

                            {/**boton para pasar a la confirmacion sin recargar la pagina */}
                            <Link to="/confirmar-compra"><Button id="ConfirmarCompra" color="transparent"></Button></Link>
                            <ModalCargando Ver={false} Cuerpo={"Cargando"} />
                            <Snackbar

                                place="tc"
                                color={this.state.colorNotificacion}
                                message={this.state.mensaje}
                                open={this.state.br}
                                closeNotification={() => this.setState({ br: false })}
                                close
                            />

                            {/* <div style={{ marginBottom: 60 }}>
                <div style={{ textAlign: 'right', height: 60, position: 'fixed', bottom: 0, left: 0, width: '100%', right: 20 }} className="border-top border-dark">
                  <div style={{ marginTop: 10, marginRight: 195 }}>

                    <Button onClick={() => window.location.href = "/confirmar-compra"} size="md" color="github" style={{ marginRight: 10 }}>&nbsp;Finalizar compra&nbsp;</Button>
                  </div>
                </div>
              </div> */}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default withStyles(shoppingCartStyle)(ShoppingCartPage);
