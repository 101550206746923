import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ModalMembresia extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //this.props.eventClick()
    }
  }

  componentDidMount() {
    //console.log(this.props)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      3000
    );
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.props.VerModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("liveDemo")}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            {/* <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => this.props.eventClick(true,"")}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button> */}
            <h4 className={classes.modalTitle}>Éxito</h4>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
            {
              this.props.TipoMensaje == false ? 
              <div>
                 <span>Felicidades ahora eres un artista </span>
              <center>
              <h4 onClick={() => window.location.reload()} style={{ textDecoration: 'underline', cursor: 'pointer', color: 'black' }}>Continuar</h4>

              </center>
              </div>
              
              
              :
              <div>
                     <span>Tu membresía se ha cancelado </span>
              <center>
              <h4 onClick={() => window.location.reload()} style={{ textDecoration: 'underline', cursor: 'pointer', color: 'black' }}>Continuar</h4>

              </center>
              
              </div>
            }
             

      
          </DialogContent>
          {/* <DialogActions className={classes.modalFooter}>
            <Button onClick={() =>this.CancelarPedido()} size="sm" color="success" className="pull-right" round ><Save />&nbsp;ACEPTAR</Button>
            <Button size="sm" color="danger" className="pull-right" round onClick={() => this.props.EventoModalCancelar(false)}><Clear />&nbsp;CANCELAR</Button>
          </DialogActions> */}
        </Dialog>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(modalStyle)(ModalMembresia);