import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

// Definir estilos para el botón
const StyledButton = styled.button`
  /* Agrega tus estilos personalizados aquí */
  background-color: #212121;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background-color: #212121;
    border: none;
  }
`;

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <StyledButton onClick={() => loginWithRedirect()}>INICIAR SESIÓN</StyledButton>;
};

export default LoginButton;
