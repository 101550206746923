
// @material-ui/core components

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Globales from "utils/Globales";
import Enumerable from 'linq';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Slide from "@material-ui/core/Slide";
import withStyles from "@material-ui/core/styles/withStyles"; 
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Delete } from "@material-ui/icons"

import DialogActions from "@material-ui/core/DialogActions";
import Success from "components/Typography/Success.jsx";
import Subject from "@material-ui/icons/Subject";
import Refresh from "@material-ui/icons/Refresh";
import mongoose from 'mongoose';
import Snackbar from "components/Snackbar/Snackbar.jsx";








const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class ModalDomicilio extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            BloquearEstadoMunicipio:false,
            Telefono: '',
            colorNotificacion: 'success',
            mensaje: '',
            Domiclios: [],
            AgregarNuevoDomicilio: false,
            Domicilio: {},
            Estado: "",
            Municipio: "",
            Calle: "",
            Colonia: "",
            CodigoPostal: "",
            NumeroExterior: "",
            NumeroInterior: "",
            EntreCalle: "",
            Referencia: "",
            Nombre:"",
            errores: {}





        }
        this.escFunction = this.escFunction.bind(this);
        this.handleChange = this.handleChange.bind(this)


    }


    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.eventoModal()
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
        if (this.props.domicilios.length > 0) {
            /**poner domicilio actual */
            this.setState({
                Domiclios: this.props.domicilios
            });
        } else {
            this.setState({
                AgregarNuevoDomicilio: true
            });
        }

    }
    componentWillMount() {

    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }
    CambiarDomicilio(domicilio) {
        this.props.eventoModal(domicilio, "cambio")

    }
    ObtenerDatosCodigoPostal(cp){


        if (this.state.CodigoPostal.length < 5) {
            var errores = {};
            errores["CodigoPostal"] = true
            this.setState({errores})
            this.showNotification("EL código postal debe tener 5 dígitos","warning")


        }else{
            var errores = {};
            
            this.setState({BloquearEstadoMunicipio:true,errores})

        
        fetch(Globales.Url + 'ObtenerDatosDeDireccionPorCodigoPostal', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({  CodigoPostal:this.state.CodigoPostal })
        }
        ).then(res => res.json())
            .then(data => {
                if (data.error) {
                    this.setState({BloquearEstadoMunicipio:false})


                } else {
                    if(data.data.length>0){

                        document.getElementById("Calle").focus();
                        this.setState({
                            Estado:data.data[0].Estado[0].NombreEstado,
                            Municipio:data.data[0].Municipio[0].NombreMunicipio
                        })
                    }else{
                        this.setState({BloquearEstadoMunicipio:false})
                            document.getElementById("Estado").focus();



                    }

                    

                }
            })
        }
        
    }


    handleChange(e) {
        const { name, value } = e.target;
        if (name == "Telefono") {
            if (value.length < 11) {
                this.setState({ [name]: value })

            }
        } else if (name == "CodigoPostal") {
            this.state.Estado="";
            this.state.Municipio="";
            if (value.length < 6 && /^[0-9]*$/.test(value)) {
                this.setState({ [name]: value })

            }
        } else {
            this.setState({ [name]: value })

        }
    }



    // showNotification() {
    //     var x = [];
    //     this.setState({ br: true });
    //     this.setState(x);
    //     this.alertTimeout = setTimeout(
    //         function () {
    //             this.setState({ br: false });
    //             this.setState(x);
    //         }.bind(this),
    //         3000
    //     );
    // }
    ValidarDomicilo() {
        var errores = {};
        var valido = true;
        if (this.state.Telefono.length < 10) {
            errores["Telefono"] = true
            valido = false;
            this.showNotification("El teléfono debe tener 10 dígitos","warning")

          

        }
        // if (this.state.CodigoPostal.length < 5) {
        if (this.state.CodigoPostal.length < 5 && /^[0-9]*$/.test(this.state.CodigoPostal)) {
            errores["CodigoPostal"] = true
            valido = false;
            this.showNotification("EL código postal debe tener 5 dígitos","warning")


        }
        if (this.state.Calle == "") {
            errores["Calle"] = true
            valido = false;

        }
        if (this.state.Colonia == "") {
            errores["Colonia"] = true
            valido = false;

        }
        if (this.state.NumeroExterior == "") {
            errores["NumeroExterior"] = true
            valido = false;
            

        }
        // if (this.state.NumeroInterior == "") {
        //     errores["NumeroInterior"] = true
        //     valido = false;

        // }
        if(valido == false){
            this.showNotification("Complete los campos requeridos","warning")

        }
        this.setState({
            errores: errores
        })
        

        return valido;

    }

    AgregarDomicilio() {
        if (this.ValidarDomicilo()) {
            this.state.Domiclios.map(m => {
                m.Usado = false;

            })
            this.state.Domiclios.push({
                _id: mongoose.Types.ObjectId().toHexString(),
                Nombre:this.state.Nombre,
                Pais: "MX",
                Estado: this.state.Estado,
                Municipio: this.state.Municipio,
                Colonia: this.state.Colonia,
                Calle: this.state.Calle + " " + this.state.NumeroExterior + " " + this.state.NumeroInterior,
                CodigoPostal: this.state.CodigoPostal,
                TelefonoReferencia: this.state.Telefono,
                Referencia: this.state.Referencia,
                Usado: true
            })

            

            
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/AgregarDomicilio', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ id: this.props.idCliente, Domicilio: this.state.Domiclios })
        }
        ).then(res => res.json())
            .then(data => {
                if (data.error) {


                } else {

                    this.setState({
                        AgregarNuevoDomicilio: false
                    });
                    this.state.Domiclios.map(m => {
                        if (m.Usado == true) {
                            this.props.eventoModal(m, "nuevo")

                        }
                    })


                }
            })

        } else {
            
        }








    }

    EliminarDomicilio(domicilio) {

        var index = this.state.Domiclios.findIndex(f => f._id == domicilio._id);
        this.state.Domiclios.splice(index, 1)


        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/AgregarDomicilio', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ id: this.props.idCliente, Domicilio: this.state.Domiclios })
        }
        ).then(res => res.json())
            .then(data => {
                if (data.error) {


                } else {
                    this.setState({
                        a: "a"
                    })
                    // this.setState({
                    //     AgregarNuevoDomicilio: false
                    // });
                    // this.state.Domiclios.map(m => {
                    //     if (m.Usado == true) {
                    //         this.props.eventoModal(m, "nuevo")

                    //     }
                    // })


                }
            })

    }

    showNotification(Mensaje, Color) {
        var x = [];
        this.setState({
          colorNotificacion: Color,
          mensaje: Mensaje,
          br: true
        });
        this.setState(x);
        this.alertTimeout = setTimeout(
          function () {
            this.setState({ br: false });
            this.setState(x);
          }.bind(this),
          3000
        );
      }

    render() {
        const { classes, ...rest } = this.props;
        


        return (

            <div >


                {/* SIGNUP MODAL START */}
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal + " " + classes.modalSignup
                    }}
                    id="mdlConfiguracionProducto"
                    open={this.props.verModal}
                    TransitionComponent={Transition}
                    keepMounted

                    aria-labelledby="signup-modal-slide-title"
                    aria-describedby="signup-modal-slide-description"
                >
                    <Card plain className={classes.modalSignupCard}>
                        <DialogTitle
                            id="signup-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <Button
                                simple
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.eventoModal("", "")}
                            >
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                            <h3> Domicilios </h3>
                        </DialogTitle>
                        <DialogContent id="signup-modal-slide-description" className={classes.modalBody}>
                            {
                                this.state.AgregarNuevoDomicilio == false ?
                                    <GridContainer>
                                        <Button onClick={() => { this.setState({ AgregarNuevoDomicilio: true }) }} color="transparent" style={{ color: "#333", textDecoration: "underline" }} >Agregar domicilio</Button>

                                    </GridContainer>
                                    : null
                            }



                            <GridContainer 
                             style={{
                                marginRight: "40px",
                                marginLeft: "40px",
                                width: "auto"
                              }}
                            >
                                {
                                    this.state.AgregarNuevoDomicilio == true ?


                                    <div>
                                         {/* <Card >
                                             <CardBody> */}
                                                <GridContainer>
                                                    <GridItem lg={2} md={2} sm={3} xs={12}>
                                                        <CustomInput
                                                            labelText="Código postal"
                                                            id="success"
                                                            formControlProps={{
                                                                fullWidth: true,

                                                            }}
                                                            inputProps={{
                                                                required: true,
                                                                onBlur:()=>this.ObtenerDatosCodigoPostal(),
                                                                onChange: this.handleChange,
                                                                value: this.state.CodigoPostal,
                                                                name: "CodigoPostal",
                                                                id: "CodigoPostal",
                                                                type: "number",
                                                                error: this.state.errores["CodigoPostal"],
                                                                

                                                            }}

                                                        />
                                                    </GridItem>
                                                    <GridItem lg={2} md={2} sm={3} xs={12}>
                                                        <CustomInput
                                                            labelText="Estado"
                                                            id="success"
                                                            formControlProps={{
                                                                fullWidth: true,

                                                            }}
                                                            inputProps={{
                                                                disabled:this.state.BloquearEstadoMunicipio,
                                                                required: true,
                                                                onChange: this.handleChange,
                                                                value: this.state.Estado,
                                                                name: "Estado",
                                                                id: "Estado",
                                                                type: "text",
                                                            }}

                                                        />
                                                    </GridItem>

                                                    <GridItem lg={2} md={2} sm={3} xs={12}>
                                                        <CustomInput
                                                            labelText="Municipio"
                                                            id="success"
                                                            formControlProps={{
                                                                fullWidth: true,

                                                            }}
                                                            inputProps={{
                                                                disabled:this.state.BloquearEstadoMunicipio,
                                                                required: true,
                                                                onChange: this.handleChange,
                                                                value: this.state.Municipio,
                                                                name: "Municipio",
                                                                id: "Municipio",
                                                                type: "text",

                                                            }}

                                                        />
                                                    </GridItem>

                                                    <GridItem lg={3} md={3} sm={3} xs={12}>
                                                        <CustomInput
                                                            labelText="Calle"
                                                            id="success"
                                                            formControlProps={{
                                                                fullWidth: true,

                                                            }}
                                                            inputProps={{
                                                                required: true,
                                                                onChange: this.handleChange,
                                                                value: this.state.Calle,
                                                                name: "Calle",
                                                                id: "Calle",
                                                                type: "text",
                                                                error: this.state.errores["Calle"]

                                                            }}

                                                        />
                                                    </GridItem>

                                                    <GridItem lg={3} md={3} sm={3} xs={12}>
                                                        <CustomInput
                                                            labelText="Colonia"
                                                            id="success"
                                                            formControlProps={{
                                                                fullWidth: true,

                                                            }}
                                                            inputProps={{
                                                                required: true,
                                                                onChange: this.handleChange,
                                                                value: this.state.Colonia,
                                                                name: "Colonia",
                                                                id: "Colonia",
                                                                type: "text",
                                                                error: this.state.errores["Colonia"]
                                                            }}

                                                        />
                                                    </GridItem>
                                                    <GridItem lg={2} md={2} sm={3} xs={12}>
                                                        <CustomInput
                                                            labelText="N° Exterior"
                                                            id="success"
                                                            formControlProps={{
                                                                fullWidth: true,

                                                            }}
                                                            inputProps={{
                                                                required: true,
                                                                onChange: this.handleChange,
                                                                value: this.state.NumeroExterior,
                                                                name: "NumeroExterior",
                                                                id: "NumeroExterior",
                                                                type: "text",
                                                                error: this.state.errores["NumeroExterior"]

                                                            }}

                                                        />
                                                    </GridItem>

                                                    <GridItem lg={2} md={2} sm={3} xs={12}>
                                                        <CustomInput
                                                            labelText="N° Interior"
                                                            id="success"
                                                            formControlProps={{
                                                                fullWidth: true,

                                                            }}
                                                            inputProps={{
                                                                required: true,
                                                                onChange: this.handleChange,
                                                                value: this.state.NumeroInterior,
                                                                name: "NumeroInterior",
                                                                id: "NumeroInterior",
                                                                type: "text",
                                                                error: this.state.errores["NumeroInterior"]
                                                            }}

                                                        />
                                                    </GridItem>
                                                    <GridItem lg={2} md={2} sm={3} xs={12}>
                                                        <CustomInput
                                                            labelText="Teléfono"
                                                            id="success"
                                                            formControlProps={{
                                                                fullWidth: true,

                                                            }}
                                                            inputProps={{
                                                                required: true,
                                                                onChange: this.handleChange,
                                                                value: this.state.Telefono,
                                                                name: "Telefono",
                                                                id: "Telefono",
                                                                type: "number",
                                                                error: this.state.errores["Telefono"]
                                                            }}

                                                        />
                                                    </GridItem>

                                                    <GridItem lg={6} md={6} sm={6} xs={12}>
                                                        <CustomInput
                                                            labelText="Nombre"
                                                            id="success"
                                                            formControlProps={{
                                                                fullWidth: true,

                                                            }}
                                                            inputProps={{
                                                                required: true,
                                                                onChange: this.handleChange,
                                                                value: this.state.Nombre,
                                                                name: "Nombre",
                                                                id: "Nombre",
                                                                type: "text",
                                                            }}

                                                        />
                                                    </GridItem>

                                                    <GridItem lg={6} md={6} sm={6} xs={12}>
                                                        <CustomInput
                                                            labelText="Referencia"
                                                            id="success"
                                                            formControlProps={{
                                                                fullWidth: true,

                                                            }}
                                                            inputProps={{
                                                                required: true,
                                                                onChange: this.handleChange,
                                                                value: this.state.Referencia,
                                                                name: "Referencia",
                                                                id: "Referencia",
                                                                type: "text",
                                                            }}

                                                        />
                                                    </GridItem>




                                                </GridContainer>
                                                <GridContainer>

                                                    <GridItem lg={8} md={8}  >
                                                        &nbsp;
                                        </GridItem>
                                                    <GridItem    lg={4} md={4}  >
                                                        
                                                        <Button className="pull-right mt-5" onClick={() => this.AgregarDomicilio()} round size='sm' color="github">ACEPTAR</Button>
                                                        <Button className="pull-right mt-5" onClick={() => { this.setState({ AgregarNuevoDomicilio: false }) }} round size='sm' color="danger" >CANCELAR</Button>

                                                    </GridItem>


                                                </GridContainer>
                                             {/* </CardBody>
                                     </Card>  */}
                                        </div>
                                        :

                                        this.state.Domiclios.map(domicilio =>
                                            <GridItem lg={12}>

                                                <GridContainer justify="center">
                                                    <GridItem lg={11} md={10} md={12} xs={12}>
                                                        <Button onClick={() => this.CambiarDomicilio(domicilio)} fullWidth color={domicilio.Usado == true ? "github" : "secondary"} >
                                                            <span> {domicilio.Calle}  {domicilio.Colonia}  C.P. {domicilio.CodigoPostal}  </span>
                                                            {/* <Card color={domicilio.Usado == true ? "primary" : ""} >
                                                        <CardBody>
                                                            
            
                                                        </CardBody>
                                                        
                                                    </Card> */}
                                                        </Button>
                                                    </GridItem>
                                                    <GridItem lg={1} md={1} md={12} xs={12}>

                                                        {
                                                            domicilio.Usado == false ?
                                                                <Button onClick={() => this.EliminarDomicilio(domicilio)} round justIcon color="danger" >
                                                                    <Delete />
                                                                </Button>
                                                                : <div>&nbsp;</div>
                                                        }
                                                    </GridItem>

                                                </GridContainer>



                                            </GridItem>

                                        )


                                }


                            </GridContainer>
                        </DialogContent>
                        <DialogActions className={classes.modalFooter}>

                        </DialogActions>
                    </Card>

                    <Snackbar

                        place="tc"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </Dialog>
                {/* SIGNUP MODAL END */}
            </div >

        );
    }
}




export default withStyles(modalStyle)(ModalDomicilio);
