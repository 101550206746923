import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Muted from "components/Typography/Muted.jsx";
import Footer from "components/Footer/Footer.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Divider from '@material-ui/core/Divider';
import Style from "Styles/ItemShopStyle.jsx";

import product1 from "assets/img/product1.jpg";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';



class ItemShop extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={4} md={3} >
                        <img src={product1}  height="200px" width="130px"/>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={5}>
                        <CardBody plain>
                            <h6 className={classes.cardTitle}>Descripción de la playera</h6>
                            <h6 className={classes.cardCategory}># 8745488-99</h6>
                            <h6 className={classes.cardCategory}>TALLA:</h6>
                            <h6 className={classes.cardCategory}>COLOR:</h6>
                        </CardBody>
                        <CardFooter plain className={classes.justifyContentCenter}>
                            <Button justIcon color="danger" round> <DeleteIcon /></Button>
                            <Button justIcon  color="default" round><EditIcon /></Button>
                        </CardFooter>
                    </GridItem>
                    <GridContainer xs={12} sm={4} md={4}>
                        <GridItem xs={12} sm={12} md={12} className={classes.pullRight}>
                            <CardBody>
                                <h6 className={classNames(classes.cardDescription)}>1 x $1,260.00 = $1,260.00</h6>
                            </CardBody>
                        </GridItem>
                    </GridContainer>
                </GridContainer>
                <Divider />
            </div>
        )
    }
}

export default withStyles(Style)(ItemShop);