import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Divider from '@material-ui/core/Divider';
import Style from "Styles/ItemShopStyle.jsx";

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';



class ResumenShop extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <div>
                <GridContainer >
                    <GridItem>
                        <Card>
                        <CardBody>
                            <h4 className={classes.cardTitle} >RESUMEN DEL PEDIDO</h4>
                            <Divider />
                            <GridContainer className={classes.justifyContentBetween}>
                                <div> 
                                <h6 className={classes.cardCategory}>Subtotal:</h6>
                                <h6 className={classes.cardCategory}>Envío:</h6>
                                <h6 className={classes.cardCategory}>Descuento:</h6>
                                </div>
                                <div> 
                                <h6 className={classes.cardTitle}>$200</h6>
                                <h6 className={classes.cardTitle}>$0</h6>
                                <h6 className={classes.cardTitle}>-$100</h6>
                                </div>
                            </GridContainer>
                            <Divider />
                            <GridContainer className={classes.justifyContentBetween}>
                                <h6 className={classes.cardCategory}>Importe Total:</h6>
                                <h5 className={classes.cardTitle}>$100</h5>
                            </GridContainer>
                            <CardFooter >
                                <GridItem>
                                    <Button className={classes.pullRight} color="default" round>FINALIZAR COMPRA<KeyboardArrowRight /></Button>
                                </GridItem>
                        </CardFooter>
                        </CardBody>
                      
                        </Card>
                    </GridItem>
                </GridContainer>
               
            </div>
        )
    }
}

export default withStyles(Style)(ResumenShop);