import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Loader from 'react-loader-spinner'
import Cookies from 'js-cookie'
import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.jsx";

class Cargando extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idUsuarioTerco: JSON.parse(Cookies.get('UsuarioTerco')).id,
    };
  }

  render() {
    const { classes, ...rest } = this.props;
    const { idUsuarioTerco } = this.state;

    return (
      <div>
        <div className={classes.textCenter}>
        <h2>Cargando</h2>
              <Loader type="ThreeDots" color="#212121" height={30} width={50} />
          {/* {idUsuarioTerco === "" ? (
            <h3>Por favor, inicie sesión para comenzar a usar Nook</h3>
          ) : (
            <>
              <h2>Cargando</h2>
              <Loader type="ThreeDots" color="#212121" height={30} width={50} />
            </>
          )} */}
        </div>
      </div>
    );
  }
}

export default withStyles(style)(Cargando);
