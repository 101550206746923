import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "components/Header/Header.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionLatestOffers from "views/EcommercePage/Sections/SectionLatestOffers.jsx";
import SectionProducts from "views/EcommercePage/Sections/SectionProducts.jsx";
import SectionBlog from "views/EcommercePage/Sections/SectionBlog.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.jsx";

class EcommercePage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "dark"
          }}
        />
        <Parallax
          image={require("assets/img/examples/clark-street-merc.jpg")}
          filter="dark"
          small
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <div className={classes.brand}>
                  <h1 className={classes.title}>Galeria de Productos!</h1>
                  <h4>
                    Productos Terco y Artistas
                  </h4>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          {/* <SectionLatestOffers /> */}
          <SectionProducts />
        </div>
        <SectionBlog />
      </div>
    );
  }
}

export default withStyles(styles)(EcommercePage);
