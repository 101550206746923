/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
// core components
import Header from "components/Header/Header.jsx";
import Table from "components/Table/Table.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Badge from "components/Badge/Badge.jsx";
import Muted from "components/Typography/Muted.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Button from "components/CustomButtons/Button.jsx";

import christian from "assets/img/faces/christian.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import marc from "assets/img/faces/marc.jpg";
import kendall from "assets/img/faces/kendall.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";


import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.jsx";

import Cookies from 'js-cookie'
import { Edit, ShoppingBasket, Favorite } from "@material-ui/icons";
// import ModalEditar from './ModalEditar';

import product1 from '../../assets/img/terco-img/playera1.webp';

import product2 from '../../assets/img/terco-img/playera2.jpg';
import product3 from '../../assets/img/terco-img/playera3.jpg';

//Diseños
import diseno1 from '../../assets/img/terco-img/Crazy&Colors.webp';
import diseno2 from '../../assets/img/terco-img/RacingTracks.png';
import diseno3 from '../../assets/img/terco-img/RevolucionCannábica.webp';
import diseno4 from '../../assets/img/terco-img/SkullsWings&Pistons.webp';
import diseno5 from '../../assets/img/terco-img/WolfsFangs.webp';
import diseno6 from '../../assets/img/terco-img/VintageHarleyJersey.webp';




// @material-ui/core components
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.eventoModal = this.eventoModal.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;    
  }

  openModal = () => {
    this.setState({ show: true });
  }

  eventoModal(e) {
    if (!e) {
      this.setState({ show: false });
      return;
    }
    this.setState({ show: false })
    // this.cerrarSesion(this.seleccionado);
  }

  eventoClose = (mensaje, colorNotificacion) => {
    this.setState({ show: false });

    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
      <div>
        <Header
          color="transparent"
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "dark"
          }}
          {...rest}
        />
        <Parallax
          image={require("assets/img/examples/city.jpg")}
          filter="dark"
          className={classes.parallax}
        />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className={classes.name}>
                  <h3 className={classes.title}>Diseños realizados por Terco</h3>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <Table
                tableHead={[""]}
              ></Table>
            </GridContainer>

            <Clearfix />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(profilePageStyle)(ProfilePage);
