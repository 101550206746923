import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Card from "components/Card/Card.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { Link } from "react-router-dom";
import CartaProducto from "./CartaProducto";
import Cookies from 'js-cookie'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class ModalTalla extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            atributosAgregados: [],
            caracteristicasAgregadas: [],
            errorTalla: false,
            Origen: "",
            Tallas: [],
            colorNotificacion: 'success',
            mensaje: '11121221212121',
            idCategoria: '',
            imagenes: [],
            idTalla: '',
            Producto: null,
        }
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.EventoModalTalla()
        }
    }

    componentDidMount() {
        //console.log("tallas", this.props.ProductosTallas)
        document.addEventListener("keydown", this.escFunction, false);

    }
    componentWillMount() {
        //console.log("EL producto den modal talla---->", this.props.Producto)
        this.setState({
            idTalla: this.props.Tallas[0]._id,
            Tallas: this.props.Tallas,
            Producto: this.props.Producto,
            Origen: this.props.Producto.Origen
        }, () => {
            this.props.CaracteristicasPorTalla.map(m => {
                if (m.idTalla == this.state.idTalla) {
                    this.setState({
                        caracteristicasAgregadas: m.caracteristicasAgregadas,
                        atributosAgregados: m.atributosAgregados
                    })
                }
            })
        });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.props.CaracteristicasPorTalla.map(m => {
                if (m.idTalla == event.target.value) {
                    this.setState({
                        caracteristicasAgregadas: m.caracteristicasAgregadas,
                        atributosAgregados: m.atributosAgregados
                    })
                }
            })
        });
    }

    FormValido() {
        var valido = true;
        return valido;
    }



    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false });
                this.setState(x);
            }.bind(this),
            3000
        );
    }


    VerDiseñador3D() {
    if (this.state.idTalla != "") {
      console.log("mmmmmmmmm*******_>", this.props.ProductosTallas);
      var encontrada = false;
      localStorage.removeItem("disenioSeleccionado");
      this.props.ProductosTallas.map(f => {
        if (this.state.idTalla) {
          encontrada = true;
          localStorage.setItem("idModelo", f.idDiseno);
          localStorage.setItem("idTalla", this.state.idTalla);
          if (this.state.Origen == "" || this.state.Origen == undefined) {
            localStorage.setItem("tipo", 1);
          }
          if (this.state.Origen == "Tienda") {
            localStorage.setItem("tipo", 2);
            // localStorage.setItem("ListaDisenos", )
            Cookies.set("ListaDisenos", JSON.stringify(this.props.ProductosTallas))
            }
          }
            });
            if(!encontrada){
                //No encontro la talla obtener el id del diseño y la talla especificada
                var infotalla= this.props.ProductosTallas[0];
                localStorage.setItem("idModelo", infotalla.idDiseno)
                localStorage.setItem("idTalla", infotalla.idTalla)
                if (this.state.Origen == "" || this.state.Origen == undefined) {
                    localStorage.setItem("tipo", 1)
                }
                if (this.state.Origen == "Tienda") {
                    localStorage.setItem("tipo", 2)
                    // localStorage.setItem("ListaDisenos", )
                    Cookies.set("ListaDisenos", JSON.stringify(this.props.ProductosTallas))
                }
            }
            localStorage.setItem("desdePerfil","false")
            document.getElementById("VerDiseñador").click();
        }
        else {
            this.setState({
                errorTalla: true,
                colorNotificacion: "warning",
                mensaje: "Seleccione talla"
            }, () => {
          this.showNotification();
            });
        }
    }

    
    render() {
        const { classes } = this.props;


        return (
            <div >
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal + " " + classes.modalSignup
                    }}
                    open={this.props.VerModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.props.EventoModalTalla}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <Card plain className={classes.modalSignupCard}>
                        <DialogTitle
                            id="signup-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <Button
                                simple
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.EventoModalTalla()}
                            >
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                            <center>
                                <h3 className={`${classes.cardTitle} ${classes.modalTitle}`}>
                                    Selecciona la talla
                                </h3>
                            </center>

                        </DialogTitle>
                        <DialogContent>
                            <GridContainer justify="center">
                                <GridItem lg={5} md={5} sm={12} xs={12} >
                                    <CartaProducto Producto={this.state.Producto} />
                                </GridItem>
                                <GridItem lg={6} md={6} sm={12} xs={12} >
                                    <GridContainer>
                                        <GridItem lg={6} md={6} sm={7} xs={7}>
                                            <FormControl fullWidth className={classes.selectFormControl} error={this.state.errorTalla}>
                                                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                                    Seleccione
                                                </InputLabel>
                                                <Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={this.state.idTalla} onChange={this.handleChange} inputProps={{ name: "idTalla", id: "idTalla", }}>
                                                    <MenuItem disabled classes={{ root: classes.selectMenuItem }}>Seleccione</MenuItem>

                                                    {
                                                        this.state.Tallas.map(talla =>
                                                            <MenuItem value={talla._id} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} >
                                                                {talla.NombreTalla}
                                                            </MenuItem>

                                                        )
                                                    }
                                                </Select>
                                            </FormControl>

                                        </GridItem>
                                        <GridItem lg={6} md={6} sm={5} xs={5}>
                                            <div style={{ marginTop: '1em' }}>
                                                <Button size="sm" onClick={() => this.VerDiseñador3D()} color="danger" > Diseñar</Button>
                                            </div>
                                            <Link to="/diseñador3D">
                                                <button style={{ display: 'none' }} id="VerDiseñador" />
                                            </Link>
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem lg={12} md={12} sm={12} xs={12}>
                                            <Accordion
                                                active={0}
                                                activeColor="primary"
                                                collapses={[
                                                    {
                                                        title: "Características",
                                                        content:
                                                            <div>
                                                                {
                                                                    this.state.atributosAgregados.map(m =>

                                                                        <div>{m.NombreAtributo}: {m.ValorDesplegable.length > 0 ? m.ValorDesplegable[0] : m.ValorColor}</div>
                                                                    )
                                                                }
                                                                {
                                                                    this.state.caracteristicasAgregadas.map(m =>
                                                                        <div>{m.NombreCaracteristica}: {m.ValorCaracteristica}</div>
                                                                    )
                                                                }
                                                            </div>
                                                    },
                                                ]}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                </GridItem>
                            </GridContainer>
                        </DialogContent>
                    </Card>
                    <Snackbar
                        place="tc"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </Dialog>
                {/* <div id="">
          <ProductPage
            idModelo={this.state.idModelo}
          />
        </div> */}
            </div >
        );
    }
}

export default withStyles(modalStyle)(ModalTalla);




// <GridItem lg={6} md={6} sm={12} xs={12} >
// <center>
//                 <div>
//                 <h4 style={{color:'#3C4858',minHeight: 'auto',fontFamily: "Roboto Slab, Times New Roman, serif",fontWeight: '700'}}>{this.state.Producto.NombreProducto}</h4>

//                 </div>


//                 <FormControl fullWidth className={classes.selectFormControl} error={this.state.errorPaqueteria}>
//                     <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
//                         Seleccione
// </InputLabel>
//                     <Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={this.state.idTalla} onChange={this.handleChange} inputProps={{ name: "idTalla", id: "idTalla", }}>

//                         <MenuItem disabled classes={{ root: classes.selectMenuItem }}>Seleccione</MenuItem>

//                         {
//                             this.state.Tallas.map(talla =>
//                                 <MenuItem value={talla._id} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} >
//                                     {talla.NombreTalla}
//                                 </MenuItem>

//                             )
//                         }
//                     </Select>
//                 </FormControl>
//                 <br />
//                 <br />
//                 <Button onClick={() =>this.VerDiseñador3D()} color="danger" > Diseñar</Button>
//                     <Link to="/diseñador3D">
//                     <button style={{display:'none'}} id="VerDiseñador"/>

//                     </Link>

//             </center>

// </GridItem>