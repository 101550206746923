import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "../Generales/Header/Header.jsx";
import HeaderLinks from "../Generales/Header/HeaderLinks.jsx";
import sectionsPageStyle from "assets/jss/material-kit-pro-react/views/sectionsPageStyle.jsx";
import Seccion8 from './Seccion/seccion8';

// sections of this Page

class TerminosCondiciones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Header
                    color="dark"
                    links={<HeaderLinks dropdownHoverColor="dark" />}
                    fixed
                />
                <div>
                    <center>
                        <h3>
                            <b> NOOK </b>
                        </h3>
                        <h3>
                            <b> TÉRMINOS Y CONDICIONES DE USO DEL SERVICIO </b>
                        </h3>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <b>PERSONALIZADOS TERCO, S.A.S. DE C.V.</b>, (conocida comercialmente como NOOK), es una
                        sociedad mexicana, legalmente constituida y existente conforme a la legislación mexicana,
                        que opera la plataforma informática accesible a través de la dirección web
                        www.fabricanook.com, misma que puede ser conectada a través de dicho sitio web.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los presentes Términos y Condiciones de Uso del Servicio, regulan: (i) el uso de NOOK, y; (ii)
                        cualquier otro Contrato o relación jurídica conexa celebrada con La Empresa de forma
                        jurídicamente vinculante.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los Usuarios y/o Artistas deben leer atentamente el presente documento.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> GLOSARIO </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Para los efectos de los presentes Términos y Condiciones de Uso del Servicio, se utilizarán los
                        términos definidos a continuación, mismos que podrán emplearse en mayúsculas y/o
                        minúsculas, singular y/o plural, masculino y/o femenino:
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <b>NOOK (El Sitio, La Empresa o La Plataforma):</b> es una plataforma de e-commerce dedicada
                        a la comercialización de prendas de ropa y productos con diseños personalizados, lo que se
                        consigue a través de una herramienta de manipulación y visualización 3D.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario (como se define más adelante) podrá adquirir diferentes prendas de vestir u otros
                        productos con diseños realizados por ellos mismos o por los Artistas (como se definen a
                        continuación); y el Artista podrá crear diseños personalizados de manera fácil y rápida
                        usando un diseñador 3D.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los datos de contacto de La Empresa son los siguientes:
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            Correo Electrónico: garantias@fabricanook.com.
                        </li>
                        <li>
                            Dirección Comercial: Avenida de Las Américas #3114, Colonia Universitarios, C.P. 80010,
                            en Culiacán, Sinaloa, México.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <b>USUARIO (El Cliente):</b> es la persona que voluntaria y obligatoriamente se registra en La
                        Plataforma para adquirir o comprar las prendas de vestir y productos que se ofrecen en
                        NOOK.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Cliente puede igualmente crear sus propios diseños personalizados para utilizarlos en las
                        prendas de vestir y/o productos que adquiera a través de El Sitio; dichos diseños no podrán
                        ser visualizados, ni utilizados, por ningún otro Usuario.
                    </p>
                      <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <b>ARTISTA (El Creador):</b> es la persona que voluntaria y obligatoriamente se registra en El Sitio,
                        para ofrecer diseños propios y originales a Los Usuarios.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Artista crea sus propios diseños personalizados y los sube en su perfil de NOOK para que
                        otros Usuarios y/o Creadores puedan visualizarlos y adquirirlos, recibiendo una comisión por
                        dicha venta, en las condiciones señaladas en el epígrafe correspondiente.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> INTRODUCCIÓN </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Este documento constituye un contrato legalmente vinculante entre El Usuario y/o Artista, y
                        La Empresa, que regula la utilización de La Plataforma así como las condiciones de uso de los
                        servicios que se prestan en El Sitio.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La utilización de los servicios y adquisición de los productos que ofrece NOOK está
                        supeditada al registro de Los Clientes y/o Creadores en La Plataforma. El propio Usuario y/o
                        Artista proporcionará Los Datos que se le requieran durante el proceso de registro, siendo
                        éstos los únicos responsables por la veracidad de los mismos.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Mediante su registro en El Sitio, El Usuario y/o Artista admiten expresamente conocer y
                        aceptar los presentes Términos y Condiciones de Uso del Servicio, así como el Aviso de
                        Privacidad. El tratamiento de Los Datos proporcionados por los Usuarios y/o Artistas está
                        sujeto al Aviso de Privacidad disponible en <a href="https://fabricanook.com/#/AvisoPrivacidad" target="_blank" rel="noopener noreferrer">
                            https://fabricanook.com/#/AvisoPrivacidad
                        </a>.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> ACCESO, SEGURIDAD Y USO DE NOOK </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El uso de El Sitio está sujeto a los presentes Términos y Condiciones de Uso del Servicio, así
                        como a las leyes que pudieran resultar aplicables. La navegación por este Sitio, el registro, y/
                        o la utilización de cualesquiera de los servicios ofrecidos en él, supone la aceptación expresa
                        del Usuario y/o Artista, sin reserva, limitación ni condición alguna, de los presentes Términos
                        y Condiciones de Uso del Servicio, y de aquellas revisiones que unilateralmente apruebe y
                        publique La Empresa.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La utilización de los servicios que La Empresa ofrece, o cualquier parte de éstos, está
                        supeditada al registro del Usuario y/o Artista en La Plataforma. El Cliente y/o Creador
                        deberán proporcionar todos los datos que se les soliciten durante el proceso de registro,
                        siendo los únicos responsables de la veracidad de los mismos. Igualmente, deberán aceptar
                        de forma íntegra la Política de Privacidad y los presentes Términos y Condiciones de Uso del
                        Servicio.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La Empresa conservará, utilizará y, en su caso, transmitirá dichos datos, de acuerdo con el
                        Aviso de Privacidad disponible en la página web www.fabricanook.com.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Cliente y/o Creador deben establecer un nombre de usuario y una contraseña al momento
                        de formalizar su registro en La Plataforma, siendo ellos los únicos responsables de mantener
                        en todo momento la confidencialidad de su contraseña y de su cuenta, siendo también
                        responsables por todas las actividades que se realicen a través de la misma.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista se comprometen y obligan a notificar inmediatamente a NOOK
                        cualquier uso no autorizado de su cuenta o cualquier otra violación de seguridad.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Bajo ninguna circunstancia podrá considerarse responsable a La Empresa en caso de
                        pérdida, divulgación, robo o uso no autorizado por parte de terceros, por cualquier motivo,
                        de las credenciales de acceso del Usuario y/o Artista.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        En cualquier caso, NOOK colaborará con Los Usuarios y/o Artistas en la identificación de
                        violaciones de seguridad y/o cualquier otra situación que atente contra estos Términos y
                        Condiciones de Uso del Servicio, sin que ello genere responsabilidad, ni gasto alguno a La
                        Empresa.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o El Artista aceptan y se obligan a utilizar El Sitio y los servicios que en él se
                        ofrecen conforme a los presentes Términos y Condiciones de Uso del Servicio, así como a
                        cualquier otra disposición que en su momento pueda publicar La Empresa.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Creador y/o El Usuario se obligan expresamente a actuar en todo momento de acuerdo
                        con las leyes de propiedad industrial e intelectual vigentes. El Creador y/o El Usuario
                        reconocen expresamente que NOOK es una herramienta de e-commerce que facilita la
                        creación de diseños y/o ilustraciones que deben ser propias y originales, por lo que aceptan
                        y reconocen expresamente que cuentan con todos y cada uno de los permisos, licencias,
                        autorizaciones o derechos necesarios para utilizar los diseños o cualquier otro material o
                        contenido que carguen en El Sitio.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o El Creador señalan expresamente que no utilizarán ningún contenido del cual
                        no sean autores o tengan los derechos de uso, para la personalización de playeras o
                        productos, y que actuarán en todo momento en cumplimiento de lo señalado tanto en la Ley
                        Federal de Protección a la Propiedad Industrial y la Ley Federal del Derecho de Autor, sus
                        Reglamentos y Normativas Complementarias, que se encuentren vigentes al momento de
                        emitir éste documento, o las que en un futuro pudieran sustituirlas.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        En consecuencia, El Usuario y/o El Creador eximen expresamente a NOOK de cualquier
                        responsabilidad por reclamaciones de cualquier tipo que se pudieran interponer contra La
                        Empresa, en caso de: (i) incumplimiento de cualquier disposición legal actual o futura, y/o; (ii)
                        por cualquier daño que la actividad del Creador y/o El Usuario pudiera generar.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Asimismo, El Cliente y/o El Artista reconocen expresamente que NOOK no se hace
                        responsable por el diseño encomendado para su impresión, siendo responsabilidad
                        exclusiva de quien lo ordena y/o solicita.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Cliente y/o El Artista reconocen expresamente que La Empresa no modera, ni tiene ningún
                        control, sobre los contenidos publicados por el Artista o cualquier otro usuario de El Sitio,
                        aunque se compromete a actuar en caso de que se reciban quejas de terceras personas o
                        bien órdenes procedentes de autoridades públicas en relación con contenidos que se
                        consideren ofensivos o ilícitos.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        En particular, El Usuario y/o Artista reconocen y aceptan que NOOK se reserva el derecho a
                        negar o retirar, unilateralmente, en cualquier momento y sin necesidad de notificación previa,
                        el acceso a El Sitio a cualquier Cliente y/o Creador que incumpla lo establecido en los
                        presentes Términos y Condiciones de Uso del Servicio, o en los casos que, de manera
                        enunciativa más no limitativa, se señalan a continuación: (i) si terceras personas presentan
                        quejas; (ii) si NOOK recibe una notificación de infracción de derechos de propiedad
                        intelectual o industrial; (iii) si se decide dicha suspensión o interrupción en virtud de acciones
                        legales, o a consecuencia de éstas; (iv) si una autoridad pública lo solicita, o; (v) si La Empresa
                        considera que, mientras el contenido siga estando accesible a través de El Sitio, éste
                        contenido puede suponer un riesgo para otros Usuarios, terceras personas, la disponibilidad
                        del Servicio y/o La Plataforma.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Asimismo, La Empresa puede cancelar la cuenta que El Usuario y/o El Artista tengan en El
                        Sitio, de acuerdo con las causas y el procedimiento establecidos más adelante. Ello conlleva
                        evidentemente la imposibilidad de que dichos Clientes y/o Creadores puedan utilizar
                        ninguno de los servicios ofrecidos por NOOK.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> MODALIDADES Y FUNCIONAMIENTO BÁSICO DEL SERVICIO </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario deberá obligatoriamente darse de alta en La Plataforma, y completar su perfil,
                        para poder adquirir cualquiera de los productos ofertados en NOOK. El Cliente puede
                        igualmente crear sus propios diseños en los productos y adquirirlos a través del sitio, pero no
                        estarán disponibles ni visibles para terceras personas. Éstos servicios no requieren del pago
                        de ninguna membresía.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Artista, obligatoriamente, debe suscribirse y pagar una membresía en El Sitio para que sus
                        diseños estén a disposición de Los Usuarios de NOOK.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            Las suscripción o membresía corresponde al pago mensual que deberán efectuar los
                            artistas para tener acceso a su perfil, diseñar de forma ilimitada, proteger sus diseños,
                            tener la opción de mostrar sus productos y obtener comisiones por la venta de los
                            mismos a través de la plataforma; así mismo, será beneficiado por precios preferenciales
                            que le permitan incrementar su utilidad en la venta de sus diseños.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            Los diseños creados por El Artista únicamente podrán ser visualizados y utilizados por
                            Los Usuarios durante el tiempo en el que éste pague su membresía en La Plataforma.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            El Artista únicamente cobrará las comisiones por la venta de productos con sus diseños,
                            mientras pague su membresía en La Plataforma.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            Si El Artista deja de pagar su membresía en La Plataforma, su cuenta seguirá activa con
                            los mismos beneficios que los de El Usuario.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Las modalidades de membresía así como los beneficios de cada una de ellas serán creadas
                        por NOOK de acuerdo con el negocio, por lo que pueden variar a entera discreción de La
                        Empresa.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los costos de la suscripción pueden ser modificados por NOOK, para el siguiente periodo
                        de vigencia de los servicios, lo que será notificado mediante correo electrónico a El Artista al
                        menos 15 (quince) días antes del inicio del nuevo periodo de vigencia para el que se aplique
                        la revisión de precios.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Igualmente, la modalidad de los servicios que se ofrecen a El Cliente pueden modificarse
                        para que, en su caso, conlleven un costo para El Usuario. Ello será notificado por correo
                        electrónico a El Cliente al menos 15 (quince) días antes de la entrada en vigor de las nuevas
                        modalidades.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> PAGOS A REALIZAR POR LOS USUARIOS Y/O ARTISTAS Y CONDICIONES DE LOS MISMOS </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Las tarifas de los servicios de NOOK deberán cubrirse por El Artista al momento de contratar
                        el servicio de La Plataforma o al momento de su renovación automática mensual, mediante
                        domiciliación de tarjeta bancaria.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Las compras realizadas por El Cliente deberán cubrirse al momento en que se realiza dicha
                        compra. Los costos del envío de las mercancías, en su caso, se señalarán antes de que éste
                        realice el pago. Las formas de pago aceptadas tanto para cubrir el monto de las prendas
                        adquiridas, como para cubrir el monto de su envío, se señalan a continuación: tarjeta de
                        crédito, tarjeta de débito, pago en efectivo en la tienda conocida como “OXXO”,
                        transferencia o crédito electrónico.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Cliente y/o El Artista garantizan expresamente que son los titulares de las tarjetas o
                        cuentas corrientes desde las que se efectuaron los pagos, y que los datos de facturación son
                        verídicos.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Todos los pagos realizados por Los Clientes, a través de los medios de pago ya indicados se
                        realizan a través de plataformas ajenas a NOOK. Por ende, La Empresa es totalmente ajena al
                        flujo del dinero así como a la gestión realizada por la plataforma de cobro.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista entienden que su pago nunca se realiza directamente a NOOK, sino
                        que se gestiona a través de terceros, por lo que dichos pagos se realizan sin que La Empresa
                        intervenga en ningún momento en el flujo de los pagos realizados por Los Usuarios, hasta el
                        momento de su recepción.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        En consecuencia, El Usuario y/o Creador reconocen y aceptan expresamente que NOOK no
                        es responsable de garantizar los pagos realizados por Los Clientes y/o Artistas, y entienden
                        que La Empresa no estará obligada a prestar sus servicios hasta que tenga confirmación del
                        pago realizado por El Cliente y/o Artista.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista aceptan y reconocen que factores externos a NOOK pueden originar
                        retrasos o problemas al momento de realizar o acreditar el pago, y que La Empresa no será
                        responsable por errores, fallas, dificultades técnicas o suspensión en la operación de El Sitio,
                        o en el sistema de las plataforma de gestión de cobro utilizadas por NOOK.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista renuncian expresamente a exigir cualquier responsabilidad a La
                        Empresa por los inconvenientes, perjuicios o menoscabos que pudieran derivarse por la
                        indisponibilidad o acreditación de los pagos, el retraso en la acreditación de los mismos,
                        fallos en el acceso o falta de continuidad en los sistemas proveídos por NOOK o por los
                        bancos o los establecimientos utilizados para realizar pagos a La Empresa.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario acepta y reconoce expresamente que NOOK no se hará cargo en ningún caso del
                        reintegro del dinero o pago, en virtud de perjuicios resultantes de este tipo de situaciones, ni
                        por órdenes de pago no procesadas por el sistema o rechazadas, cuentas suspendidas o
                        canceladas.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        NOOK colaborará con Los Clientes y/o Los Creadores en la solución de cualquier problema
                        relacionado con el retraso o acreditación del pago, sin que ello suponga ninguna aceptación
                        de responsabilidad al respecto, y sin que La Empresa se haga cargo de ningún gasto
                        adicional que la reclamación pudiera generar. Para la resolución de controversias
                        relacionadas con pagos no acreditados o reembolsos El Cliente se pondrá en contacto con
                        NOOK a través del correo electrónico garantias@fabricanook.com.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Una vez el pago esté acreditado, el pedido continuará con su proceso para ser fabricado y
                        enviado.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> MONTOS A COBRAR POR LOS ARTISTAS</b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Cada producto que un Artista venda a través de La Plataforma, tiene asignado un porcentaje
                        de comisión a cobrar por El Artista que lo diseñó.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Las comisiones a Los Artistas se pagarán los días jueves de cada semana, siempre que sean
                        días hábiles, en la cuenta bancaria facilitada por ellos mismos; para ello, es necesario que el
                        pedido que incluya su diseño se encuentre en estatus de entregado.
                    </p>


                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> DESCUENTOS Y OFERTAS </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        NOOK se reserva el derecho, a su entera e incuestionable discreción, a ofrecer descuentos y/
                        u ofertas por un tiempo limitado.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Las condiciones de estos descuentos y/u ofertas se especificarán en cada ocasión en la
                        página de información correspondiente de NOOK. Cada descuento y/u oferta tendrá validez
                        durante el período indicado o, cuando corresponda, hasta que se agoten las existencias.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Si el descuento y/o la oferta se establecen por un tiempo limitado, dicho tiempo se
                        determinará con referencia a la zona horaria en la que está establecida La Empresa.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b>REEMBOLSO </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        En ningún caso será reembolsable el monto de la membresía adquirida por El Artista.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario cuenta con un plazo de 24 (veinticuatro) horas para cancelar su compra. El
                        reembolso incluirá el monto del producto adquirido, así como los gastos de envío en los que
                        incurriría, y se realizará en forma de saldo electrónico en su perfil o cuenta personal para
                        futuras compras. Pasado el plazo señalado, no cabrá reembolso de ningún tipo, total ni
                        parcial.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Cliente podrá cancelar su compra a través de su perfil, dirigiéndose a la sección de
                        “compras”, identificando el pedido que desea cancelar y seleccionando la opción de
                        “cancelar compra”; momento en el que se habilitará una ventana emergente para que El
                        Usuario confirme la cancelación de su pedido.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> GARANTÍA </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        NOOK ofrece una garantía de carácter limitado, únicamente en caso de que alguno de los
                        productos adquiridos por El Cliente presente defectos de fabricación.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario cuenta con un plazo máximo de 48 (cuarenta y ocho) horas posteriores la
                        recepción del producto para comunicarse con NOOK a través del correo electrónico
                        garantias@fabricanook.com, enviando la siguiente información: (i) número de pedido; (ii)
                        teléfono de contacto; (iii) nombre de usuario; (iv) correo electrónico registrado en la
                        plataforma, y; (v) fotos de la prendas que evidencien el defecto de fabricación.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario deberá señalar si el defecto se presenta en todas las prendas adquiridas o
                        únicamente en algunas, indicando en cuántas exactamente está el defecto; e igualmente
                        describir si el defecto es de impresión o de costura.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La garantía está sujeta al análisis que La Empresa realice de cada caso, siendo NOOK, a su
                        entera discreción, la que determinará si la garantía entra en vigor. Toda solicitud será
                        atendida en un máximo de 24 (veinticuatro) horas a contar desde la recepción del correo por
                        parte de La Empresa.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        En caso de que NOOK determine que la garantía resulta aplicable, El Usuario recibirá un
                        nuevo producto exactamente igual al que adquirió originalmente, sin costo alguno.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        No cabe reclamación sobre la decisión tomada por La Empresa.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> ENTREGA </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Las entregas se realizarán durante el horario comercial habitual en la dirección indicada por
                        El Usuario y/o Artista, y de la manera especificada en el resumen del pedido.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        A la entrega, el Usuario y/o Artista deberá verificar el contenido, señalando las anomalías que
                        puedan existir de acuerdo con lo indicado en el epígrafe anterior.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        En caso de que el transportista no pudiera entregar los productos adquiridos por El Usuario
                        y/o El Artista por: (i) no poder ubicar el domicilio de entrega, o; (ii) no encontrar destinatario
                        que los reciba en el domicilio indicado en la orden de compra; NOOK procederá de la
                        siguiente forma:
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los productos se regresarán a La Empresa. Una vez NOOK reciba los artículos, se pondrá en
                        contacto con El Cliente y/o El Artista para agendar una nueva entrega y éstos deberán de
                        pagar de nuevo el segundo envío.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El número de intentos de entrega, así como la logística de dichas entregas dependerán de la
                        empresa encargada de los fletes, lo cual es ajeno a NOOK.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        NOOK no podrá ser considerada responsable de los posibles errores en la entrega debidos
                        a la inexactitud o falta de datos cometidos por El Cliente y/o El Creador al completar la orden
                        de compra, de los daños que puedan sufrir los productos tras su depósito en la empresa
                        transportista, de los posibles retrasos en la entrega atribuibles a ésta última, de la
                        imposibilidad de realizar la entrega por no poder ubicar el domicilio de entrega o no
                        encontrar destinatario que la reciba.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> PRIVACIDAD </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Al acceder, usar y/o registrarse en El Sitio, El Usuario y/o Artista aceptan expresamente las
                        Políticas de Privacidad de La Empresa, mismas que pueden ser consultadas en el Aviso de
                        Privacidad publicado en <a href="https://fabricanook.com/#/AvisoPrivacidad" target="_blank" rel="noopener noreferrer">
                            https://fabricanook.com/#/AvisoPrivacidad
                        </a>.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los vínculos que pudiera contener El Sitio pueden dirigir a El Usuario y/o El Artista a sitios
                        web de terceros que no son propiedad ni están bajo el control de NOOK, por lo que La
                        Empresa no será responsable por la utilización que El Cliente y/o Creador hagan de estos
                        sitios web, ni por las Políticas de Privacidad de los sitios web de dichos terceros.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario entiende que éste apartado relativo a Políticas de Privacidad es meramente
                        informativo, estando supeditado a lo establecido en el Aviso de Privacidad que se encuentra
                        a su disposición en la página de internet www.fabricanook.com.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> VERACIDAD DE LA INFORMACIÓN </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Toda información que El Usuario y/o Artista proporcionen a NOOK deberá ser veraz, por lo
                        que El Cliente y/o Creador garantizan la autenticidad de todos los datos proporcionados y
                        serán responsables de cualquier daño o consecuencia que su inexactitud pudiera generar.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La Empresa se reserva el derecho de utilizar fuentes de terceros o de acceso público, para la
                        recopilación, cotejo y confirmación de la información proporcionada por El Usuario y/o
                        Artista, así como para la ejecución de acciones necesarias para el cumplimiento de las
                        obligaciones que éstos adquieran con NOOK.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> PROPIEDAD INTELECTUAL E INDUSTRIAL </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista reconocen y aceptan que las marcas y demás signos distintivos,
                        contenidos, diseños, elementos gráficos, interfaces, modelo de negocios, software (código
                        fuente, código objeto), trade dress, tecnología y cualquier otro activo inmaterial relacionado
                        con, o derivados de El Sitio, y en general todo el contenido de La Plataforma, son propiedad
                        exclusiva de NOOK y se encuentran protegidos por las leyes vigentes en materia de
                        propiedad intelectual e industrial y los tratados internacionales relacionados. NOOK tiene
                        reservados todos los derechos de propiedad intelectual e industrial.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El registro de El Usuario y/o Artista en El Sitio en ninguna forma supone una autorización o
                        licencia para hacer uso de los signos distintivos, interfaces, modelo de negocios, software o
                        cualquier otro activo intelectual o industrial que se despliega en el mismo. El Cliente y/o
                        Creador tienen expresamente prohibido reproducir, utilizar o explotar en forma alguna los
                        signos distintivos o cualquier otro activo inmaterial de NOOK.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        A través del registro, NOOK autoriza a El Usuario y/o Artista para hacer uso de El Sitio, de
                        forma personal, intransferible, temporal, no exclusiva, limitada y sujeta a la disposición y
                        términos que establezca La Empresa, mismos que podrán ser modificados de forma libre,
                        unilateral, inmediata y sin necesidad de previo aviso. Esta autorización sólo está destinada al
                        uso personal de tal contenido y no incluye en modo alguno su uso comercial. Asimismo está
                        limitada al dispositivo del Usuario y/o Artista.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Cualquier alteración, copia, modificación, mutilación, publicación o explotación del software
                        o de cualquier otro activo intelectual o industrial de NOOK por parte de El Usuario y/o Artista
                        será considerada una violación al derecho que lo ampara y será sancionado conforme a la
                        legislación aplicable.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Por consiguiente, el Usuario y/o Creador no podrán descargar, compartir (más allá de las
                        limitaciones establecidas más abajo), utilizar, modificar, alquilar, arrendar, prestar, distribuir,
                        mutilar, publicar, transmitir, vender, sublicenciar, editar, transferir o ceder a terceros o crear
                        obras derivadas utilizando dicho contenido o basadas en El Sitio o cualquiera de sus
                        elementos, ni siquiera el perteneciente a terceros, que esté disponible en NOOK. Los activos
                        propiedad de La Empresa no podrán ser usados por El Usuario y/o Creador para ningún fin
                        distinto del mero uso de El Sitio en cualquier dispositivo de su propiedad.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Cuando se indique expresamente en La Plataforma, se podrá autorizar al Usuario y/o Artista,
                        únicamente para su uso personal, a descargar, copiar y/o compartir determinados
                        contenidos que estén disponibles a través de NOOK, exclusivamente para su uso personal y
                        a condición de que la atribución de derechos de autor y todas las otras atribuciones
                        solicitadas por La Empresa se señalen correctamente.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Asimismo, El Usuario y/o Artista tienen expresamente prohibido acceder mediante
                        decompilación o por cualquier otro método conocido o por conocerse, a las líneas de
                        código fuente de El Sitio; vender, sublicenciar, transferir o explotar en cualquier otra forma el
                        software de El Sitio o su código fuente; o permitir que terceras personas realicen cualquiera
                        de las acciones descritas. La violación a esta condición será considerada una violación a

                        Página 11 de 18

                        derechos de autor y/o propiedad industrial y/o una violación de secretos industriales, y será
                        sancionada conforme a las disposiciones legales aplicables.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista aceptan no acceder a El Sitio por cualquier otro medio distinto de la
                        interface provista por NOOK. Cualquier violación a lo aquí establecido será sancionada de
                        conformidad con la legislación aplicable.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Igualmente, todas las marcas y signos distintivos de terceros que pudieran mostrarse en La
                        Plataforma, son propiedad exclusiva de éstos, teniendo El Usuario y/o Creador expresamente
                        prohibido reproducir, utilizar o explotar en forma alguna los signos distintivos o cualquier
                        otro activo intelectual de cualquier tercero.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La Empresa se reserva el derecho a ejercer todas las acciones, tanto civiles como penales,
                        destinadas a proteger los derechos de propiedad intelectual e industrial de los que sea
                        legítimo titular.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Por último, El Artista y/o El Usuario podrán incorporar el diseño, marca, dibujo, logotipo o
                        cualquier otro signo que desee incluir en los diseños que genere. El Creador y/o El Usuario
                        aseguran ser titulares de dichos signos, o tener derecho a usarlos, y, en consecuencia,
                        reconocen y aceptan expresamente que La Empresa queda liberada de cualquier
                        responsabilidad en el caso de que no tengan derecho a usarlos y/o cuando los signos
                        citados pertenezcan a terceras personas.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        A través de su registro en La Plataforma, El Artista y/o El Usuario autorizan a NOOK para
                        hacer uso de los diseños, marcas, dibujos, logotipos, o cualquier otro signo que El Creador
                        haya incorporado en su perfil en La Plataforma. La autorización para hacer uso de los
                        elementos señalados es intransferible, temporal, no exclusiva y limitada.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> COMPORTAMIENTO ILEGAL O PERJUDICIAL </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Sitio únicamente podrá usarse de conformidad con los presentes Términos y Condiciones
                        de Uso del Servicio.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista aceptan expresamente abstenerse, de manera enunciativa más no
                        limitativa, de: (i) utilizar El Sitio para cualquier fin ilícito, en violación a las leyes, regulaciones,
                        y disposiciones fiscales, tributarias, mercantiles, de propiedad industrial o intelectual, o de
                        cualquier otra naturaleza, que resulten aplicables o pudieran serlo en un futuro; (ii) publicar
                        páginas web que contengan vínculos que inicien descargas de material ilícito o que infrinjan
                        derechos de propiedad intelectual o industrial de cualquier tercero; (iii) publicar diseños,
                        ilustraciones, o cualquier otro material que infrinja derechos de propiedad intelectual o
                        industrial de cualquier tercero; (iv) participar en cualquier actividad en medios virtuales que
                        se vinculen de forma alguna con La Empresa y/o sus aliados y/o asociados, y que viole los
                        derechos de privacidad de otras personas, incluyendo sin limitación, recabar y distribuir
                        información sobre usuarios sin la autorización de éstos; (v) realizar ingeniería inversa,
                        descompilar, modificar o crear productos derivados de NOOK, o de cualquier parte de La
                        Plataforma; (vi) copiar, almacenar, editar, modificar, preparar cualquier producto derivado o
                        alterar de cualquier modo los contenidos proporcionados a través de El Sitio; (vii) utilizar
                        cualquier robot, “spider”, aplicación de búsqueda/recuperación de sitios web u otro
                        dispositivo, proceso o medio automático para acceder, recuperar, realizar “web scraping” o
                        indexar cualquier parte de NOOK o de sus contenidos; (viii) alquilar, otorgar licencias o sub-
                        licencias sobre El Sitio, cualquier parte de éste o su contenido; (ix) realizar cualquier
                        conducta que resulte ilegal y que vincule de forma directa o indirecta a La Empresa y/o a sus
                        aliados y/o asociados; y/o, en general (x) injuriar, ofender, acosar, realizar prácticas
                        amenazantes, amenazar o violar los derechos de terceros de cualquier otro modo;
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Como condición adicional para el uso y acceso a El Sitio, de manera enunciativa más no
                        limitativa, El Usuario y/o Artista aceptan expresamente no: (i) publicar, distribuir o transmitir
                        cualquier virus, archivo u otro código, archivo, malware, spyware o cualquier otro software
                        dañino que pudiera implantarse o transmitirse por terceros a través de El Sitio; (ii) utilizar El
                        Sitio para acceder a las cuentas de otras personas sin su autorización; (iii) intentar vulnerar las
                        medidas de seguridad de El Sitio, u obtener las contraseñas de otras personas; y/o (iv) eludir
                        cualquier sistema informático utilizado por NOOK o sus licenciantes para proteger el
                        contenido accesible a través de La Plataforma.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> VÍNCULOS </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Sitio puede contener hipervínculos o Links a sitios web de terceras empresas, o puede
                        mostrar contenido proporcionado por terceras personas, que pueden ser aliadas y/o
                        asociadas de NOOK. Dichos sitios web no son propiedad de La Empresa, y NOOK no los
                        administra, edita, ni realiza ninguna moderación previa. En virtud de que La Empresa no tiene
                        ningún control sobre dichos sitios o contenidos, El Usuario y/o Artista reconocen y aceptan
                        expresamente que NOOK no los respalda ni tiene obligación legal alguna respecto de su
                        contenido, sus Políticas de Privacidad, sus prácticas, ni sobre los productos y/o servicios que
                        en ellos se ofrezcan o la exactitud de la información contenida en ellos.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista reconocen y aceptan expresamente que NOOK no será responsable,
                        directa o indirectamente, por ningún daño o perjuicio, pérdida o afectación, que les pueda
                        ser causada por cualquier contenido, práctica, o por la adquisición de cualquier producto y/o
                        la utilización de cualquier servicio disponible en las páginas web enlazadas por medio de El
                        Sitio y ajenas a La Empresa.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> FALLAS DEL SISTEMA </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La Empresa no será responsable por errores, fallas, dificultades técnicas o suspensión
                        temporal y/o definitiva en el acceso y/o la operación de El Sitio o de cualquiera de los
                        vínculos que El Sitio contenga o en un futuro pudiera contener.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista reconocen y aceptan expresamente que La Empresa no tendrá, ni
                        podrá reclamársele obligación o responsabilidad alguna por cualquier daño, perjuicio o
                        menoscabo que pudiera sufrir por fallas en: (i) el servicio prestado por NOOK, incluyendo,
                        sin limitarse a, aquellos sufridos al momento de realizar cualquier pago o generar cualquier
                        diseño; o (ii) cualquiera de los vínculos que contenga o en un futuro pudiera contener El
                        Sitio.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La Empresa tampoco será responsable por fallas en las redes informáticas proveídas por
                        terceros, ni especialmente de las fallas en los sitios web de los bancos emisores de las
                        distintas tarjetas de crédito o débito con las que El Cliente y/o Creador pueden realizar los
                        pagos a NOOK, o de los establecimientos en los que El Usuario y/o Artista pueden realizar
                        los pagos.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> MODIFICACIONES, SUSPENSIONES E INTERRUPCIONES DEL SERVICIO </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Con el fin de garantizar a los Usuarios y/o Artistas un uso óptimo de La Plataforma, NOOK se
                        reserva el derecho de modificar, interrumpir, suspender o desconectar El Sitio o cualquier
                        parte del mismo, así como sus servicios, para realizar labores de mantenimiento o
                        actualizaciones del Sitio.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Estas modificaciones, suspensiones, desconexiones o interrupciones se podrán realizar en
                        cualquier momento y/o periódicamente, sin necesidad de aviso previo, por lo que El Usuario
                        y/o Artista reconocen y aceptan que La Empresa no será responsable por ninguna
                        modificación, suspensión, o interrupción de El Sitio.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> RESPONSABILIDAD </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Creador reconocen expresamente que NOOK es una herramienta de e-
                        commerce que facilita al Creador la creación de diseños y/o ilustraciones que deben ser
                        propias y originales, por lo que reconocen expresamente que NOOK no modera, ni tiene
                        ningún control, sobre los contenidos publicados por el Artista o cualquier otro usuario de El
                        Sitio. En consecuencia, El Cliente y/o Artista eximen expresamente a La Empresa de cualquier
                        responsabilidad por reclamaciones de cualquier tipo que se pudieran interponer contra La
                        Empresa, en caso de: (i) incumplimiento de cualquier disposición legal actual o futura, y/o; (ii)
                        por cualquier daño que la actividad del Creador pudiera generar.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista entienden que su pago y cobro nunca se realizan directamente a
                        NOOK, sino que se gestionan a través de terceros, por lo que dichos pagos y cobros se
                        realizan sin que La Empresa intervenga en ningún momento en el flujo de los pagos
                        realizados por Los Usuarios, y cobros de los Artistas, hasta el momento de su recepción.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        En consecuencia, El Usuario y/o Artista reconocen y aceptan expresamente que NOOK no es
                        responsable de garantizar los pagos realizados por Los Usuarios, y entienden que La
                        Empresa no estará obligada a prestar sus servicios hasta que tenga confirmación del pago
                        realizado por El Cliente y que no será responsable por errores, fallas, dificultades técnicas o
                        suspensión en la operación de El Sitio, o en el sistema de los bancos o de los
                        establecimientos en los que se realiza dicho pago.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista renuncian expresamente a exigir cualquier responsabilidad a La
                        Empresa por los inconvenientes, perjuicios o menoscabos que pudieran derivarse por la
                        indisponibilidad o acreditación de los pagos, el retraso en la acreditación de los mismos,
                        fallos en el acceso o falta de continuidad en los sistemas proveídos por NOOK o los bancos o
                        los establecimientos aliados a La Empresa, incluyendo, sin limitar, aquellos sufridos al
                        momento de realizar cualquier pago.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        En cualquier caso, NOOK colaborará con Los Clientes y/o Creadores en la solución de
                        cualquier problema relacionado con el retraso o acreditación del pago, sin que ello suponga
                        ninguna aceptación de responsabilidad al respecto ni extensión de la misma, y sin que La
                        Empresa se haga cargo de ningún gasto adicional que la reclamación pudiera generar.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        NOOK hará su mejor esfuerzo por conservar la seguridad y estabilidad de El Sitio. Sin
                        embargo, La Empresa no será responsable por, de manera enunciativa más no limitativa: (i) la
                        transmisión de malware, spyware o cualquier otro software dañino que pudiera implantarse o
                        transmitirse por terceros a través de El Sitio; (ii) cualquier conducta delictiva de terceros que
                        pudiera realizarse a través de El Sitio, incluyendo, pero no limitado a, Phishing y Pharming.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La Empresa procurará en todo momento prevenir y/o resolver de forma inmediata cualquier
                        contingencia causada por la existencia de software malicioso, sin que ello constituya o
                        genere una responsabilidad sobre los efectos causados por dicho software.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Creador exoneran de toda responsabilidad a La Empresa (incluyendo a sus
                        filiales, participadas, representantes, administradores, agentes, licenciantes, socios y
                        empleados, según corresponda) respecto de cualesquiera reclamaciones o demandas,
                        incluyendo gastos y honorarios de defensa jurídica, planteadas por terceros debido a o en
                        relación con los contenidos publicados online, infracciones legales o de las disposiciones de
                        las presentes condiciones de uso.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        NOOK y todas las funciones accesibles a través de El Sitio se ponen a disposición de los
                        Usuarios y/o Creadores bajo los términos y condiciones de los presentes Términos y
                        Condiciones de Uso del Servicio, sin ninguna garantía, ya sea expresa o implícita, salvo las
                        exigidas por las leyes correspondientes.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista utilizan NOOK y las funciones accesibles a través de El Sitio bajo su
                        propio riesgo y responsabilidad.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        En particular, La Empresa, de conformidad con lo establecido por las leyes aplicables, sólo
                        asumirá responsabilidad contractual o extracontractual ante los Usuarios y/ Creadores o
                        terceros en la medida de que exista negligencia grave o dolo, cuando el perjuicio se derive
                        de forma inmediata y directa de la actividad de NOOK. Por lo tanto, La Empresa no asume
                        responsabilidad alguna por: (i) los perjuicios que no sean consecuencia directa de un
                        incumplimiento por parte de La Empresa; (ii) la pérdida de oportunidades de negocio o
                        cualesquiera otros perjuicios, incluso indirectos, que pueda sufrir el Usuario y/o El Creador
                        (tales como, de manera enunciativa y no limitativa, perjuicios comerciales, pérdida de
                        ingresos, ganancias, beneficios o ahorros esperados, pérdida de contratos o de relaciones de
                        negocios, pérdida de reputación o de fondo de comercio, etc.); (iii) los daños o perjuicios
                        derivados de la interrupción o funcionamiento defectuoso de El Sitio debido a supuestos de
                        fuerza mayor, o como mínimo debido a hechos imprevistos y hechos imprevisibles y, en
                        cualquier caso, que sean ajenos a la voluntad de NOOK y estén fuera del control de éste,
                        tales como, de manera enunciativa y no limitativa, interrupción o funcionamiento defectuoso
                        de las líneas telefónicas o de suministro eléctrico, de Internet y/o de otros medios de
                        comunicación, falta de disponibilidad de páginas web, huelgas, desastres naturales, virus y
                        ataques informáticos, interrupciones en el suministro de productos, de servicios de terceros o
                        de aplicaciones; y, (iv) la utilización incorrecta o inadecuada de El Sitio por los Usuarios y/o
                        Artistas.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> DURACIÓN Y TERMINACIÓN </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La membresía contratada por El Artista tiene una duración de 1 (un) mes contado a partir de
                        la fecha de suscripción, y se entenderá automáticamente prorrogado por períodos iguales, y
                        para la misma modalidad para la que fueron contratados, salvo que El Creador cancele dicha
                        membresía con carácter previo a que NOOK renueve la suscripción de El Creador.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La terminación o cancelación de la membresía de El Artista conlleva para éste, de manera
                        enunciativa más no limitativa, lo siguiente: (i) los diseños que haya creado se eliminarán de su
                        perfil público y no podrán ser adquiridos por otros usuarios, y; (ii) las comisiones pendientes
                        de pago serán pagadas por La Empresa una vez que el pedido que incluya su diseño se
                        encuentre en estatus de entregado.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Artista acepta que NOOK puede unilateralmente y sin necesidad de notificación previa,
                        cancelar, suspender y terminar inmediatamente la prestación del servicio a El Creador,
                        incluyendo la cancelación y suspensión de la cuenta que El Artista tenga en La Plataforma.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Cliente acepta que NOOK puede unilateralmente y sin necesidad de notificación previa,
                        cancelar, suspender y terminar inmediatamente la prestación del servicio a El Usuario,
                        incluyendo la cancelación y suspensión de la cuenta que éste tenga en La Plataforma.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los Usuarios y/o Artistas podrán igualmente cancelar sus cuentas y cesar en el uso de los
                        servicios ofrecidos en La Plataforma en cualquier momento, mediante la interfaz de El Sitio o
                        poniéndose en contacto directamente con La Empresa.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Entre las causales de cancelación, suspensión y/o terminación de la cuenta de El Usuario y/o
                        Artista se incluyen, de manera enunciativa más no limitativa, las siguientes: (i) incumplimiento
                        de las Políticas de Privacidad, Términos y Condiciones de Uso del Servicio o de cualquier
                        versión de las mismas que pudiera publicarse en un futuro, o de cualquier otro acuerdo,
                        lineamiento o Términos y Condiciones Específicos que se pudieran incorporar en un futuro;
                        (ii) incumplimiento de cualquier disposición legal aplicable o que se pudiera aplicar en un
                        futuro, en especial, pero sin limitarse a, las normas legales que regulan la propiedad
                        industrial o intelectual; (iii) el mandato realizado por autoridades judiciales, administrativas o
                        gubernamentales; (iv) la solicitud voluntaria realizada por el propio Usuario; (v) terminación o
                        modificación sustancial del servicio o cualquier parte del mismo, por parte de NOOK; (vi)
                        problemas técnicos o de seguridad inesperados sufridos por La Plataforma; (vii) que NOOK,
                        a su entera discreción, considere que por cualquier motivo la cuenta es inadecuada, ofensiva
                        o que infringe los Términos de éste documento; y, (vii) la inactividad prolongada de El
                        Usuario y/o El Creador.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La terminación o cancelación de la cuenta de El Usuario y/o Artista en El Sitio conlleva para
                        éstos, de manera enunciativa más no limitativa, lo siguiente: (i) eliminación de su perfil,
                        cuenta y contenido asociado con él dentro de El Sitio; y, (ii) la cancelación del acceso a todos
                        los servicios ofrecidos dentro de El Sitio.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o Artista también reconocen y aceptan que NOOK no será responsable frente a
                        ellos ni frente a ningún otro tercero por cualquier daño o perjuicio que pudiera ocasionar la
                        terminación de su cuenta o la cancelación de su acceso a El Sitio.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> NOTIFICACIONES </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Todas las comunicaciones relativas a NOOK deberán ser enviadas utilizando los datos de
                        contacto establecidos en éste documento.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> JURISDICCIÓN Y LEGISLACIÓN APLICABLE </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o El Artista reconocen y aceptan expresamente que cualquier conflicto derivado
                        de los servicios prestados por NOOK, así como de sus políticas de operación, Términos y
                        Condiciones de Uso del Servicio, Aviso de Privacidad, o cualquier otro, será dirimido y
                        resuelto ante y por La Empresa, cuando con ello no se afecten leyes en materia de propiedad
                        intelectual e industrial, privacidad, protección al consumidor, fiscales, mercantiles, o de
                        cualquier otra naturaleza que deban ser sometidas a una autoridad determinada.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Si por cualquier causa se suscitara una controversia entre NOOK y un Usuario y/o Artista,
                        éste(os) deberá(n) someterse a los tribunales competentes en la ciudad de Culiacán, Estado
                        de Sinaloa, México, renunciando El Usuario y/o Artista a cualquier otra jurisdicción que por
                        razón de su domicilio presente o futuro, o por cualquier otra causa, pudiera corresponderle.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> PROTECCIÓN AL CONSUMIDOR </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        De conformidad con lo establecido en el artículo 76 Bis de la Ley Federal de Protección al
                        Consumidor, La Empresa informa a Los Usuarios y/o Artistas que:
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            NOOK utiliza la información proporcionada por Los Usuarios y/o Artistas de forma
                            confidencial, sujeta al Aviso de Privacidad que se encuentra a su disposición en la página
                            web <a href="https://fabricanook.com/#/AvisoPrivacidad" target="_blank" rel="noopener noreferrer">
                                https://fabricanook.com/#/AvisoPrivacidad
                            </a>. La Empresa no podrá difundir o transmitir dicha información a
                            otros proveedores ajenos, salvo por autorización expresa del Cliente y/o Creador, a través
                            de su aceptación a las Políticas de Privacidad de La Empresa, o por requerimiento de la
                            autoridad competente.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            La información de Los Usuarios y/o Artistas y los diseños creados por estos últimos a través
                            de El Sitio se conservan de forma confidencial por La Empresa, utilizando para ello los
                            medios tecnológicos que permiten la seguridad de su conservación.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            NOOK ha puesto a disposición de Los Usuarios y/o Artistas la información relativa a sus
                            datos de contacto, a través de su sitio web, su Aviso de Privacidad, y el presente
                            documento.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            NOOK evita realizar prácticas engañosas respecto de las características de los servicios que
                            ofrece, dando cumplimiento a las disposiciones relativas a la información y publicidad de
                            dichos bienes y servicios, señaladas en la Ley Federal de Protección al Consumidor y demás
                            disposiciones que deriven de ella.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            Aún cuando los anuncios, publicidad y promociones realizadas por terceros a través de El
                            Sitio o de sus respectivos sitios web no son responsabilidad de NOOK, La Empresa
                            promueve y fomenta la difusión de contenidos claros y veraces.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            El Cliente y/o Artista conocen toda la información sobre los términos, condiciones, costos,
                            cargos adicionales, en su caso, formas de pago de los bienes y servicios ofrecidos por
                            NOOK.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            NOOK respeta la decisión del Cliente y/o Artista en cuanto a la cantidad y calidad de los
                            productos que desea recibir; así como la de no recibir avisos comerciales, a través de los
                            mecanismos previstos en el Aviso de Privacidad disponible en <a href="https://fabricanook.com/#/AvisoPrivacidad" target="_blank" rel="noopener noreferrer">
                                https://fabricanook.com/#/AvisoPrivacidad
                            </a>.
                        </li>
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <li>
                            NOOK se abstiene de utilizar estrategias de venta o publicitarias que no proporcionan al
                            Usuario y/o Artista información clara y suficiente sobre los servicios y/o productos
                            ofrecidos, en especial tratándose de prácticas de mercadotecnia dirigidas a la población
                            vulnerable, como los niños, ancianos y enfermos, incorporando mecanismos que adviertan
                            cuando la información no sea apta para esa población.
                        </li>
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> MODIFICACIONES A LOS PRESENTES TÉRMINOS Y CONDICIONES DE USO DEL SERVICIO </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        NOOK se reserva el derecho de realizar modificaciones, adaptaciones y/o adiciones a este
                        documento, quedando exclusivamente obligada a notificar de ello a Los Usuarios y/o
                        Artistas, por correo electrónico o mediante publicación en el sitio web www.fabricanook.com.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Se entenderá que Los Clientes y/o Creadores que continúen utilizando La Plataforma tras la
                        publicación de los cambios, aceptan las nuevas Condiciones sin reservas.
                    </p>
                    
                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                </div>
                <Seccion8 id="seccion8" />

            </div>
        );
    }
}


export default withStyles(sectionsPageStyle)(TerminosCondiciones);