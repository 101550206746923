import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "../Generales/Header/Header.jsx";
import HeaderLinks from "../Generales/Header/HeaderLinks.jsx";
import sectionsPageStyle from "assets/jss/material-kit-pro-react/views/sectionsPageStyle.jsx";
import Seccion8 from './Seccion/seccion8';

// sections of this Page

class AvisoPrivacidad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Header
                    color="dark"
                    links={<HeaderLinks dropdownHoverColor="dark" />}
                    fixed
                />
                <div>
                    <center>
                        <h3>
                            <b> AVISO DE PRIVACIDAD INTEGRAL </b>
                        </h3>
                        <h4>
                            <b> RESPONSABLE </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        <b>PERSONALIZADOS TERCO, S.A.S. DE C.V.</b>, identificada comercialmente como NOOK (La
                        Empresa), es una sociedad mercantil formalmente constituida conforme a las leyes de los Estados
                        Unidos Mexicanos, con domicilio fi scal en la Avenida de las Américas #3114, Colonia
                        Universitarios, C.P. 80010, en Culiacán, Sinaloa; y cuya dirección electrónica es
                        www.fabricanook.com (La Página Web o La Plataforma).
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Por medio del presente Aviso de Privacidad Integral, y de conformidad con lo dispuesto por la Ley
                        Federal de Protección de Datos Personales en Posesión de Particulares, su Reglamento y los
                        Lineamientos del Aviso de Privacidad, La Empresa informa a sus clientes, usuarios y artistas (Los
                        Usuarios, Los Clientes o Los Artistas) de sus políticas de privacidad, el objeto del tratamiento de los
                        datos recabados (Los Datos) y los medios para ejercer los derechos de Acceso, Rectificación,
                        Cancelación y Oposición (Derechos ARCO).
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Las personas morales o jurídicas no están sujetas al presente Aviso de Privacidad, pues sus datos
                        no constituyen Datos Personales de acuerdo con lo establecido en la ya citada Ley Federal de
                        Protección de Datos Personales en Posesión de Particulares.
                    </p>
                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> DATOS RECABADOS </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los Usuarios, Clientes y/o Artistas, proporcionan a La Empresa los siguientes datos (Los Datos):
                        nombre completo, dirección de correo electrónico, dirección de entrega, teléfono de contacto y
                        datos de una tarjeta de crédito/débito. Para el caso de Los Artistas, además, se solicita una cuenta
                        bancaria en la que depositar las comisiones que les pudieran corresponder.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Todos Los Datos son recogidos a fin de que NOOK cumpla su función esencial, consistente en
                        comercialización de prendas de ropa o productos con diseños personalizados a través de la Página
                        Web. La Empresa podrá utilizar Los Datos recabados para el envío de comunicaciones informativas
                        y/o promocionales, tanto en formato físico como electrónico.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        En ningún caso se recaban datos considerados sensibles o especialmente protegidos de acuerdo a
                        la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento, o
                        los Lineamientos.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Todos Los Usuarios deberán ser mayores de 18 años para comprar en La Página Web.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> CONSENTIMIENTO </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Para proceder a la compra online de productos, Los Usuarios deben registrarse en La Página Web,
                        para lo que deben aceptar el presente Aviso de Privacidad y los Términos y Condiciones de Uso del
                        Servicio publicados en la dirección web www.fabricanook.com.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Para ello, El Usuario debe hacer “click” en los recuadros “HE LEÍDO Y ACEPTO EL AVISO DE
                        PRIVACIDAD” y “HE LEÍDO Y ACEPTO LOS TÉRMINOS Y CONDICIONES DE USO DEL SERVICIO”, lo
                        que supone que Los Clientes y/o Artistas manifiestan expresamente conocer, haber leído y estar de
                        acuerdo con el presente Aviso de Privacidad y los ya citados Términos y Condiciones de Uso del
                        Servicio; otorgando en consecuencia a NOOK su consentimiento expreso e inequívoco para la
                        recolección, el tratamiento y/o transmisión de Los Datos.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Igualmente, una vez El Usuario completa su registro en La Página Web, manifestando conocer,
                        haber leído y estar de acuerdo con el presente Aviso de Privacidad y los Términos y Condiciones de
                        Uso del Servicio, El Usuario otorga a NOOK su consentimiento expreso para la recolección y el
                        tratamiento de los datos de su tarjeta de crédito/débito, limitados exclusivamente a la
                        identificación de la cuenta bancaria designada para efectos de cobranza.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Por su parte, cuando El Artista completa su registro en La Página Web, manifestando conocer,
                        haber leído y estar de acuerdo con el presente Aviso de Privacidad y los Términos y Condiciones de
                        Uso del Servicio, otorga a NOOK su consentimiento expreso para la recolección y el tratamiento de
                        los datos de su tarjeta de crédito/débito y/o su cuenta bancaria, para efectos de transferir las
                        comisiones que le pudieran corresponder.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Asimismo, la falta de manifestación en contrario supone un consentimiento para que Los Datos
                        puedan ser utilizados para el envío de comunicaciones informativas y/o promocionales, tanto de
                        NOOK como de empresas filiales o terceras empresas.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los Usuarios podrán limitar la utilización de Los Datos para fines promocionales o publicitarios, o
                        manifestar su negativa para el tratamiento de Los Datos con estos fines, conforme a lo establecido
                        en el presente Aviso de Privacidad para el ejercicio de los Derechos ARCO.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> OBTENCIÓN DE LOS DATOS </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los Usuarios ponen Los Datos libremente a disposición de NOOK al registrarse en La Página Web
                        como paso previo necesario para completar el proceso de compra en la dirección web
                        www.fabricanook.com; igualmente, Los Artistas ponen Los Datos libremente a disposición de La
                        Empresa al registrarse en La Plataforma, como requisito necesario para mostrar sus diseños, y
                        cobrar las comisiones que les pudieran corresponder.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Por tanto, La Empresa recoge Los Datos que resultan necesarios para la existencia, mantenimiento y
                        cumplimiento de la relación jurídica entre NOOK y Los Usuarios y/o Artistas, directamente de éstos.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Al realizar el proceso de registro, Los Usuarios y/o Artistas manifiestan conocer, haber leído y estar
                        de acuerdo con el presente Aviso de Privacidad y los Términos y Condiciones de Uso del Servicio,
                        lo que constituye una manifestación inequívoca de su voluntad.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        NOOK podrá utilizar fuentes de terceros o de acceso público para recoger, cotejar y/o validar la
                        información proporcionada por Los Usuarios y/o Artistas al momento de su registro, así como para
                        la ejecución de acciones necesarias para el cumplimiento de las obligaciones que éstos hayan
                        adquirido con La Empresa o sus empresas afiliadas.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> FINALIDAD DEL TRATAMIENTO DE LOS DATOS </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La Empresa recaba Los Datos con objeto de cumplir con su finalidad esencial, es decir,
                        comercializar los productos que se encuentran a la venta en La Página Web y entregar dichos
                        productos una vez hayan sido adquiridos; igualmente se considera finalidad esencial el pago a Los
                        Artistas de las comisiones que les pudieran corresponder.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        NOOK también podrá utilizar Los Datos con fines promocionales. Los Usuarios y/o Artistas podrán
                        oponerse y revocar su consentimiento para el uso de Los Datos para esta finalidad no esencial,
                        conforme a lo establecido en el presente Aviso de Privacidad para el ejercicio de los Derechos
                        ARCO.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> TRANSMISIÓN DE DATOS PERSONALES </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los Datos recabados por NOOK para el cumplimiento de su finalidad esencial podrán ser
                        transmitidos a empresas filiales y/o asociadas, cuando ello resulte necesario para la prestación de
                        los servicios contratados por Los Usuarios y/o Artistas.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Al completar el proceso de compra en La página Web, El Usuario concede su consentimiento
                        expreso para la transmisión de Los Datos conforme a lo señalado en el párrafo precedente.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los Datos recogidos con fines promocionales y/o publicitarios podrán igualmente ser transmitidos.
                        Los Usuarios y/o Artistas podrán oponerse y revocar su consentimiento para el uso de Los Datos
                        con fines promocionales y/o publicitarios, conforme a lo establecido en el presente Aviso de
                        Privacidad para el ejercicio de los Derechos ARCO.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> CONSERVACIÓN DE DATOS Y MEDIDAS DE SEGURIDAD </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Todos Los Datos recogidos por La Empresa se encuentran resguardados con medidas de
                        seguridad que garantizan su conservación. Todo el personal de NOOK encargado del tratamiento y
                        conservación de Los Datos, cuenta con la capacitación necesaria para ello, y se encuentra vinculado
                        por obligaciones contractuales de confidencialidad.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> PÁGINA WEB </b>
                        </h4>
                        <h4>
                            www.fabricanook.com
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        NOOK podrá recoger datos de Los Usuarios y/o Artistas por medios remotos, utilizando para ello
                        cookies y/o web beacons dentro de su página de internet.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Las cookies son archivos de datos que se almacenan en el disco duro del equipo de cómputo de
                        Los Usuarios o de su dispositivo de comunicaciones electrónicas, al navegar en un sitio de internet
                        específico, el cual permite intercambiar información de estado entre dicho sitio y el navegador de
                        El Usuario. La información de estado puede revelar medios de identificación de sesión,
                        autenticación o preferencias del usuario, así como cualquier otro dato almacenado por el
                        navegador respecto al sitio de internet.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los web beacons son imágenes visibles u ocultas, insertadas dentro de un sitio web o correo
                        electrónico, que se utiliza para monitorear el comportamiento del usuario en estos medios. A través
                        de éstos se puede obtener información como la dirección IP de origen, navegador utilizado,
                        sistema operativo, momento en que se accedió a la página y en el caso de correo electrónico, la
                        asociación de los datos.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        El Usuario y/o El Artista podrá limitar el uso de las cookies y web beacons en cualquier momento,
                        modificando la configuración de su computadora o dispositivo electrónico.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> DURACIÓN DEL TRATAMIENTO </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los Datos recogidos por NOOK para efecto de cumplir con los servicios contratados por los
                        Usuarios y/o Artistas y de enviar comunicaciones informativas o publicitarias, se conservarán de
                        forma indefinida; salvo que El Usuario y/o El Artista expresamente y por los medios idóneos solicite
                        su cancelación.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b>EJERCICIO DE LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN </b>
                        </h4>
                    </center>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los Usuarios y/o Artistas podrán Acceder, Rectificar, Cancelar y Oponerse al tratamiento de Los
                        Datos (Derechos ARCO), salvo cuando exista una relación u obligación legal que exijan su
                        conservación.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Los Usuarios y/o Artistas podrán ejercer cualquiera de los Derechos ARCO, así como revocar su
                        consentimiento al tratamiento de Los Datos, cuando así proceda, mediante solicitud enviada por
                        correo electrónico a la cuenta de correo electrónico&nbsp;
                         <a href="mailto:privacidad@fabricanook.com">privacidad@fabricanook.com</a> adjunto a la solicitud, Los
                        Usuarios y/o Artistas deberán remitir una imagen de su identificación oficial, para verificar la validez
                        de la solicitud, así como de la identificación y carta poder de su apoderado, cuando actúe por
                        cuenta de un mandatario.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        La Empresa atenderá cualquier solicitud de ejercicio de Derechos ARCO, así como de revocación
                        del consentimiento, dando una respuesta preliminar en un plazo no mayor a 15 (quince) días
                        hábiles posteriores a la recepción de la solicitud, y una respuesta definitiva durante los 15 (quince)
                        días hábiles posteriores a la respuesta preliminar, por el mismo medio utilizado por Los Usuarios y/
                        o Artistas para realizar su solicitud.
                    </p>
                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Para el ejercicio del derecho de Cancelación, La Empresa podrá bloquear la información del titular
                        que lo solicite, hasta comprobar que por la cancelación de Los Datos no se afecta ninguna
                        obligación de o con NOOK.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> MODIFICACIONES AL AVISO DE PRIVACIDAD </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Se informará a Los Usuarios y/o Artistas de cualquier modificación que se realice del presente Aviso
                        de Privacidad, mediante comunicación a su correo electrónico. Los Usuarios y/o Artistas podrán en
                        cualquier momento consultar la última versión del presente Aviso de Privacidad en La Página Web.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> PROCEDIMIENTOS ANTE AUTORIDADES </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Para cualquier duda o reclamación sobre el tratamiento de Los Datos, Los Usuarios y/o Artistas
                        podrán recurrir al INAI (home.inai.org.mx/) como la instancia gubernamental respectiva.
                    </p>

                    <hr style={{ textAlign: 'justify', width: '80%', height: `1px`, background: `black` }}></hr>

                    <center>
                        <h4>
                            <b> VERSIÓN DEL AVISO DE PRIVACIDAD </b>
                        </h4>
                    </center>

                    <p style={{ textAlign: 'justify', width: '80%', margin: '0 auto', lineHeight: '1.6', marginBottom: '7px' }}>
                        Ultima modificación del presente Aviso de Privacidad publicada el 05 de septiembre de 2023.
                    </p>
                </div>
                <Seccion8 id="seccion8" />

            </div>
        );
    }
}


export default withStyles(sectionsPageStyle)(AvisoPrivacidad);