import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Save, Clear } from '@material-ui/icons';
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

import Cookies from 'js-cookie';
import Globales from "utils/Globales";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ModalAccion extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      id: '',
      Nombre: '',
      Correo: '',
      ConfirmacionContrasena: '',
      TipoUsuario: '',
      CuentaBancaria: '',
      Telefono: '',
      Usuario: '',
      ContrasenaAnterior: '',
      ContrasenaNueva: '',
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      errorNombre: false,
      errorCorreo: false,
      errorTelefono: false,
      errorCuentaBancaria: false,
      errorUsuarioLogin: false,
      errorContrasenaAnteriorLogin: false,
      errorUsuario: false,
      errorContrasenaAnterior: false,
      errorConfirmacionContrasena: false,
      errorTipoUsuario: false,
    }
    this.escFunction = this.escFunction.bind(this);
    this.editarPerfil = this.editarPerfil.bind(this);
    this.handleChange = this.handleChange.bind(this);    
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick()
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    this.setState({
      id: Cookies.get('id'),
      TipoUsuario: Cookies.get('TipoUsuario'),
      Nombre: Cookies.get('Nombre'),
      Correo: Cookies.get('Correo'),
      Telefono: Cookies.get('Telefono'),
      Usuario: Cookies.get('Usuario'),

    })
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  editarPerfil() {
    if (this.state.ContrasenaAnterior === "" && this.state.ContrasenaNueva === "" && this.state.ConfirmacionContrasena === "") {
      fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/editarPerfil', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify(this.state)
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else {
            //console.log(Cookies.get())
            Cookies.getJSON('Nombre', this.state.Nombre);
            Cookies.getJSON('Correo', this.state.Correo);
            Cookies.getJSON('Telefono', this.state.Telefono);
            Cookies.getJSON('TipoUsuario', this.state.TipoUsuario);
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje });
            //console.log(Cookies.get())
            this.showNotification();
            this.props.eventClick(false);
          }
        })
        .catch(err => console.error(err));
      //Guardar perfil
      this.setState({ colorNotificacion: 'success', mensaje: 'Se actualizo la contraseña correctamente', errorContrasenaAnterior: false, errorContrasenaNueva: false, errorConfirmacionContrasena: false })
      this.showNotification();
    } else { //Cambio de contraseña
      var valor1 = 0;
      var valor2 = 0;
      var valor3 = 0;
      if (this.state.ContrasenaAnterior === '') {
        this.setState({ colorNotificacion: 'danger', mensaje: 'Complete los campos para actualizar la contraseña', errorContrasenaAnterior: true });
        this.showNotification();
        valor1 = 1;
      } else {
        this.setState({ errorContrasenaAnterior: false })
        valor1 = 0;
      }
      if (this.state.ContrasenaNueva === '') {
        this.setState({ colorNotificacion: 'danger', mensaje: 'Complete los campos para actualizar la contraseña', errorContrasenaNueva: true });
        this.showNotification();
        valor2 = 1;
      } else {
        this.setState({ errorContrasenaNueva: false })
        valor2 = 0;
      }
      if (this.state.ConfirmacionContrasena === '') {
        this.setState({ colorNotificacion: 'danger', mensaje: 'Complete los campos para actualizar la contraseña', errorConfirmacionContrasena: true });
        this.showNotification();
        valor3 = 1;
      } else {
        this.setState({ errorConfirmacionContrasena: false })
        valor3 = 0;
      }
      if (valor1 + valor2 + valor3 === 0) {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/editarPerfil', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
              this.showNotification();
            } else {
              //console.log(Cookies.get())
              Cookies.getJSON('Nombre', this.state.Nombre);
              Cookies.getJSON('Correo', this.state.Correo);
              Cookies.getJSON('Telefono', this.state.Telefono);
              Cookies.getJSON('TipoUsuario', this.state.TipoUsuario);
              //console.log(Cookies.get())
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, ContrasenaAnterior: '', ContrasenaNueva: '', ConfirmacionContrasena: '', errorNuevaContrasena: false, errorNuevaContrasena: false, errorConfirmacionContrasena: false });
              this.showNotification();
              this.props.eventClick(false);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      3000
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalLarge
          }}
          open={this.props.liveDemo}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("liveDemo")}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => this.props.eventClick(false)}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>{this.props.Titulo}</h4>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
            {/* <p>{this.props.body}</p> */}
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Nombre de Usuario"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.Usuario,
                    name: "Usuario",
                    id: "Usuario",
                    type: "text",
                    error: this.state.errorUsuario,
                    disabled: true
                  }} />

              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControl
                  fullWidth
                  className="mt-5"
                >
                  <InputLabel
                    htmlFor="TipoUsuario"
                    className={classes.selectLabel}>
                    Tipo de Usuario
                        </InputLabel>
                  <Select
                    value={this.state.TipoUsuario}
                    onChange={this.handleSimple}
                    name="TipoUsuario"
                    id="TipoUsuario"
                    inputProps={{
                    }}
                  >
                    <MenuItem disabled>Tipo de Usuario</MenuItem>
                    <MenuItem value='Cliente'>Cliente</MenuItem>
                    <MenuItem value='Artista'>Artista</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Nombre Opcional"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.Nombre,
                    name: "Nombre",
                    id: "Nombre",
                    type: "text"
                  }} />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Correo Opcional"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.Correo,
                    name: "Correo",
                    id: "Correo",
                    type: "email"
                  }} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Teléfono Opcional"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.Telefono,
                    name: "Telefono",
                    id: "Telefono",
                    type: "text"
                  }} />

              </GridItem>
            </GridContainer>
            <div className={classes.space50} />
            <h4 className={classes.modalTitle}>Cambiar Contraseña</h4>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Contraseña Anterior"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.ContrasenaAnterior,
                    name: 'ContrasenaAnterior',
                    id: 'ContrasenaAnterior',
                    type: "password",
                    error: this.state.errorContrasenaAnterior
                  }} />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Contraseña Nueva"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.ContrasenaNueva,
                    name: "ContrasenaNueva",
                    id: "ContrasenaNueva",
                    type: "password",
                    error: this.state.errorContrasenaNueva
                  }} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Confirmación de Contraseña"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.ConfirmacionContrasena,
                    name: "ConfirmacionContrasena",
                    id: "ConfirmacionContrasena",
                    type: "password",
                    error: this.state.errorConfirmacionContrasena
                  }} />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button size="sm" color="success" className="pull-right" round onClick={this.editarPerfil.bind(this)}><Save />&nbsp;GUARDAR</Button>
            <Button size="sm" color="danger" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>        
          </DialogActions>
        </Dialog>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div >
    );
  }
}

export default withStyles(modalStyle)(ModalAccion);