import React, { useRef } from 'react';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Globales from "utils/Globales";
// @material-ui/icons
import { Person, PersonAdd, Close, Mail } from "@material-ui/icons";
// core components
import Header from "../Generales/Header/Header.jsx";
import HeaderLinks from "../Generales/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";
import image from "assets/img/bg7.jpg";
import facebookicon from "assets/img/facebook2.png";
import Cookies from "js-cookie";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
//import GoogleLogin from "react-google-login";
import { GoogleOAuthProvider ,GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import LoginButton from '../Aunt0/loginbutton.js';

class Components extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: {},
      DescuentoMembresia: 0,
      PrecioMembresiaDescuento: 0,
      PrecioMembresia: 0,
      ImagenPortada: null,
      FotoPerfil: null,
      RedSocial: null,
      VerMensajeSinRegistro: false,
      CambiarTab: 0,
      Nombre: "",
      Correo: "",
      CorreoRecuperar: "",
      ConfirmacionContrasena: "",
      TipoUsuario: "Cliente",
      CuentaBancaria: "",
      Telefono: "",
      UsuarioLogin: "",
      ContrasenaLogin: "",
      UsuarioRegistro: "",
      ContrasenaRegistro: "",
      mensaje: "",
      br: false,
      colorNotificacion: "success",
      errorCorreo: false,
      errorCorreoRecuperar: false,
      errorTelefono: false,
      errorNombre: false,
      errorCuentaBancaria: false,
      errorUsuarioLogin: false,
      errorContrasenaLogin: false,
      errorUsuarioRegistro: false,
      errorContrasenaRegistro: false,
      errorConfirmacionContrasena: false,
      errorAvisoPrivacidad: false,
      errorTipoUsuario: false,
      disableInicio: false,
      disableRegistro: false,
      avisoPrivacidad: false,
      popuAbierto:false,
      disabledGoogle : 'none'
    };
    this.handleChange = this.handleChange.bind(this);
    this.ValidarCorreo = this.ValidarCorreo.bind(this);
    this.ValidarNombre = this.ValidarNombre.bind(this);
    this.ValidarTelefono = this.ValidarTelefono.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleAceptoTerminosChange = this.handleAceptoTerminosChange.bind(
      this
    );
    this.handleAvisoProvacidadChange = this.handleAvisoProvacidadChange.bind(this);
    this.googleLoginRef = React.createRef();
  }

  ValidarCorreo() {
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.Correo)
    ) {
      this.setState({
        errorCorreo: false,
      });
    } else {
      this.setState({
        errorCorreo: true,
      });
    }
  }

  ValidarTelefono() {
    if (this.state.Telefono.length == 10) {
      this.setState({
        errorTelefono: false,
      });
    } else {
      this.setState({
        errorTelefono: true,
      });
    }
  }
  handleDivClick = () => {
    if (this.googleLoginRef.current) {
      this.googleLoginRef.current.click();
    } 
  };
  validarEmail(correo) {
    if (
      /^[\w-]+(\.[\w-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/.test(correo)
      ) {
     //console.log("correo valido")
      return true;
    } else {
     //console.log("correo invalido")
      return false;
    }
  }

  handleAceptoTerminosChange = () => {
    this.setState((prevState) => ({
      aceptoTerminos: !prevState.aceptoTerminos,
    }));
  };

  handleAvisoProvacidadChange = () => {
    this.setState((prevState) => ({
      avisoPrivacidad: !prevState.avisoPrivacidad,
    }));
  };

  validarNumeroTelefono(numero) {
    return /^\d{10}$/.test(numero);
  }

  ValidarNombre() {
   //console.log(1);
    if (/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g.test(this.state.Nombre)) {
     //console.log(2);
      this.setState({
        errorNombre: true,
      });
      return true;
    } else {
     //console.log(3);
      this.setState({
        errorNombre: false,
      });
      return false;
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    switch (name) {
      case "UsuarioRegistro":
        // var caracteres = /^[a-zA-Z0-9]+$/;
        // var valido = caracteres.test(value);
        // if (!valido) {
        //   alert('Debe introducir únicamente letras o números');
        // }
        this.setState({
          [name]: value,
        });
        // return valido;
        break;
      case "Telefono":
        if ((value.length < 11 && /^[0-9]+$/.test(value)) || value == "") {
          this.setState({
            [name]: value,
          });
        } else {
          return;
        }
        break;
      default:
        this.setState({
          [name]: value,
        });
        break;
    }
  }

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    // this.ObtenerMembresias()

    if (JSON.parse(Cookies.get("UsuarioTerco")).id != "") {
      window.location.href = "#/";
    }
    this.obtenerEmpresa();
  }

  RecuperarContrasena() {
    //Validar el formato del correo
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.state.CorreoRecuperar
      )
    ) {
      var valido = true;
      if (this.state.CorreoRecuperar.trim() === "") {
        this.setState({
          errorCorreoRecuperar: true,
          colorNotificacion: "danger",
          mensaje: "Ingrese el correo",
        });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ errorContrasenaLogin: false });
      }
      if (valido) {
        fetch(Globales.Url + "RecuperarContrasenaCliente", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: Globales.token,
          },
          body: JSON.stringify({ Correo: this.state.CorreoRecuperar }),
        })
          .then((res) => res)
          .then((data) => {
            if (data.status !== 404) {
              var a = data.json();
              a.then((r) => {
               //console.log(r);
                if (r.error) {
                  this.setState({
                    colorNotificacion: "danger",
                    mensaje: r.mensaje,
                  });
                  this.showNotification();
                } else {
                  this.setState({
                    colorNotificacion: "success",
                    mensaje: r.mensaje,
                  });
                  this.showNotification();
                  this.CambiarTab(0);
                }
              });
            } else {
              this.setState({
                colorNotificacion: "danger",
                mensaje: "Usuario y/o Contraseña incorrectos",
              });
              this.showNotification();
            }
          });
      }
    } else {
      this.setState({
        errorCorreoRecuperar: true,
      });
      this.setState({
        colorNotificacion: "danger",
        mensaje: "Proporcione un correo válido",
      });
      this.showNotification();
    }
  }

  IniciarSesion(op) {
    var valor1 = 0;
    var valor2 = 0;

    if (op !== 1) {
      if (this.state.UsuarioLogin.trim() === "") {
        this.setState({
          errorUsuarioLogin: true,
          colorNotificacion: "danger",
          mensaje: "Ingrese los campos requeridos",
        });
        this.showNotification();
        valor1 = 1;
      } else {
        this.setState({ errorUsuarioLogin: false });
        valor1 = 0;
      }
      if (this.state.ContrasenaLogin.trim() === "") {
        this.setState({
          errorContrasenaLogin: true,
          colorNotificacion: "danger",
          mensaje: "Ingrese los campos requeridos",
        });
        this.showNotification();
        valor2 = 1;
      } else {
        this.setState({ errorContrasenaLogin: false });
        valor2 = 0;
      }
    }

    //Validar que no hubo ningún error
    if (valor1 + valor2 === 0) {
      fetch(Globales.Url + "IniciarSesionCliente", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({
          Usuario: this.state.UsuarioLogin,
          Contrasena: this.state.ContrasenaLogin,
          RedSocial: this.state.RedSocial,
        }),
      })
        .then((res) => res)
        .then((data) => {
          if (data.status !== 404) {
            var a = data.json();
            a.then((r) => {
              this.setState({ auth: r }, () => {
                if (this.state.auth.response === true) {
                  var sesion = {
                    id: this.state.auth.usuario._id,
                    Carrito: this.state.auth.carrito,
                    ListaCarrito: this.state.auth.usuario.ListaCarrito,
                    ListaDeseos: this.state.auth.usuario.ListaDeseos,
                    Usuario: this.state.auth.usuario.Usuario,
                    Nombre: this.state.auth.usuario.Nombre,
                    Usuario: this.state.auth.usuario.Usuario,
                    Correo: this.state.auth.usuario.Correo,
                    Telefono: this.state.auth.usuario.Telefono,
                    TipoUsuario: this.state.auth.usuario.TipoUsuario,
                    Token: this.state.auth.token,
                    IndicadorTerco: this.state.auth.usuario.IndicadorTerco,
                  };
                  Cookies.set("UsuarioTerco", JSON.stringify(sesion));
                  Cookies.set("Id", this.state.auth.usuario._id);
                  Cookies.set("token", this.state.auth.token);
                  localStorage.setItem("idUser", this.state.auth.usuario._id);
                  Globales.token = "Bearer " + this.state.auth.token;
                  let dt = new Date();
                  localStorage.setItem(
                    "expira",
                    new Date(dt.getTime() + 20 * 60000)
                  );

                  this.setState({
                    colorNotificacion: "success",
                    mensaje: this.state.auth.mensaje,
                  });
                  this.showNotification();
                  if (Cookies.get("idDiseno") != null) {
                    Cookies.set("login", 1);
                    // window.opener.top.location.href ="/#/diseñador3D";
                    //window.opener.top.location ="/#/diseñador3D";
                    window.opener.top.location.reload();
                    window.close();
                  } else {
                    window.location.href = "/#/Perfil";
                  }
                }
              });
            });
          } else {
            this.setState({
              colorNotificacion: "danger",
              mensaje: "Usuario y/o Contraseña incorrectos",
            });
            this.showNotification();
          }
        });
    }
  }

  handleClick = (opt) => {
    // debugger
    try{
      if (!this.state.aceptoTerminos) {
        this.setState({
          popuAbierto: false,
          errorUsuarioRegistro: true,
          colorNotificacion: "danger",
          mensaje: "Acepte los términos y condiciones.",
        });
        this.showNotification();
      }
  
      if (!this.state.avisoPrivacidad) {
        this.setState({
          popuAbierto: false,
          errorUsuarioRegistro: true,
          colorNotificacion: "danger",
          mensaje: "Acepte el aviso de privacidad",
        });
        this.showNotification();
      }
    }catch (e){
      console.log("ERROR: ", e)
    }
  }

  RegistrarUsuario(op) {
   //console.log("🍺 / file: Login.jsx / line 220 / op", op);
    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    var valor4 = 0;
    var valor5 = 0;
    var valor6 = 0;


    if (op == 1) {
      if (this.state.UsuarioRegistro.trim() === "") {
        this.setState({
          errorUsuarioRegistro: true,
          colorNotificacion: "danger",
          mensaje: "Ingrese los campos requeridos",
        });
        this.showNotification();
        valor1 = 1;
      } else {
        var caracteres = /^[a-zA-Z0-9]+$/;
        var UsuarioValido = caracteres.test(this.state.UsuarioRegistro);
        if (UsuarioValido) {
          this.setState({ errorUsuarioRegistro: false });
          valor1 = 0;
        } else {
          this.setState({
            errorUsuarioRegistro: true,
            colorNotificacion: "danger",
            mensaje: "Los nombres de usuario solo pueden contener caracteres alfanuméricos",
          });
          this.showNotification();
          valor1 = 1;
          return
        }
      }
      if (this.state.ContrasenaRegistro.trim() === "") {
        this.setState({
          errorContrasenaRegistro: true,
          colorNotificacion: "danger",
          mensaje: "Ingrese los campos requeridos",
        });
        this.showNotification();
        valor2 = 1;
      } else {
        this.setState({ errorContrasenaRegistro: false });
        valor2 = 0;
      }
      if (this.state.ConfirmacionContrasena.trim() === "") {
        this.setState({
          errorConfirmacionContrasena: true,
          colorNotificacion: "danger",
          mensaje: "Ingrese los campos requeridos",
        });
        this.showNotification();
        valor3 = 1;
      } else {
        this.setState({ errorConfirmacionContrasena: false });
        valor3 = 0;
      }
      if (
        this.state.Telefono.length == 10 &&
        this.validarNumeroTelefono(this.state.Telefono)
      ) {
        valor4 = 0;
        this.setState({
          errorTelefono: false,
        });
      } else {
        valor4 = 1;

        this.setState({
          errorTelefono: true,
        });
      }
      if (this.state.aceptoTerminos) {
        valor5 = 0;
      } else {
        this.setState({
          errorUsuarioRegistro: true,
          colorNotificacion: "danger",
          mensaje: "Acepte los términos y condiciones.",
        });
        this.showNotification();
        valor5 = 1;
      }
      if (this.state.avisoPrivacidad) {
        valor6 = 0;
      } else {
        this.setState({
          errorUsuarioRegistro: true,
          colorNotificacion: "danger",
          mensaje: "Acepte el aviso de privacidad.",
        });
        this.showNotification();
        valor6 = 1;
      }
    } 

    if (
      valor1 + valor2 + valor3 + valor4 + valor5 + valor6 === 0 &&
      this.validarEmail(this.state.Correo)
    ) {
      // this.state.Telefono = "52"+this.state.Telefono;
      fetch(Globales.Url + "UsuarioClientes/UsuarioClientes/RegistrarCliente", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify(this.state),
      })
        .then((res) => res.json())
        .then((data) => {
         //console.log("🍺 / file: Login.jsx / line 279 / data", data);
          if (data.error) {
            this.setState({
              colorNotificacion: "danger",
              mensaje: data.mensaje,
            });
            this.showNotification();
          } else {
            //this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, Nombre: '', Correo: '', ConfirmacionContrasena: '',
            // TipoUsuario: '', CuentaBancaria: '', Telefono: '', UsuarioRegistro: '', ContrasenaRegistro: '', errorNombre: false,
            // errorCorreo: false, errorTelefono: false, errorCuentaBancaria: false, errorUsuarioRegistro: false, errorContrasenaRegistro: false,
            //errorConfirmacionContrasena: false, errorTipoUsuario: false });
            //this.showNotification();
            if (data.mensaje == "Has sido registrado correctamente") {
              if (op == 2) {
                this.state.UsuarioLogin = this.state.idTokenSocialNetwork;
                this.state.ContrasenaLogin = this.state.TokenSocialNetwork;

                this.IniciarSesion(1);
              } else {
                this.state.UsuarioLogin = this.state.UsuarioRegistro;
                this.state.ContrasenaLogin = this.state.ContrasenaRegistro;
                this.IniciarSesion();
              }
            } else {
              this.setState({
                colorNotificacion: "danger",
                mensaje: data.mensaje,
              });
              this.showNotification();
            }
          }
        })
        .catch((err) => console.error(err));
    } else {  
      // window.alert("aaaaa")
    }
  }

  ValidarExistenciaUsuarioSocialNetwork(id, datos, red) {
    // console.log(
    //   "🍺 / file: Login.jsx / line 332 / id, datos, red",
    //   id,
    //   datos,
    //   red
    // );
    fetch(
      Globales.Url + "UsuarioClientes/UsuarioClientes/ObtenerIdSocialNetwork",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({ idTokenSocialNetwork: id }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("🍺 / file: Login.jsx / line 343 / data", data);
        if (data.error) {
        } else {
          if (data.data != null) {
            this.setState(
              {
                UsuarioLogin: data.data.idTokenSocialNetwork,
                ContrasenaLogin: data.data.TokenSocialNetwork,
                RedSocial: red,
              },
              () => this.IniciarSesion()
            );
          } else {
            /**FACEBOOK == 0 GOOGLE == 1 */
            if (this.state.CambiarTab == 0) {
              this.setState({
                CambiarTab: 1,
                VerMensajeSinRegistro: true,
              });
            } else {
              // console.log("este es la red social")
              // console.log(red)
              // console.log("estos son los datos de la red social")
              // console.log(this.state)
              if (red == 0) {
                this.state.Nombre = datos.name;
                this.state.Correo = datos.email;
                this.state.ContrasenaRegistro = "";
                this.state.ConfirmacionContrasena = "";
                this.state.UsuarioRegistro = datos.name;
                //  this.state.FotoPerfil= datos.picture.data.url;
                this.state.idTokenSocialNetwork = datos.userID;
                this.state.TokenSocialNetwork = datos.accessToken;
                this.state.RedSocial = 0;

                // this.setState({
                //   RedSocial: 0,
                //   // errorUsuarioRegistro: true,
                //   // errorContrasenaRegistro: true,
                //   // errorConfirmacionContrasena: true,

                // }, () => this.RegistrarUsuario(2));
                this.RegistrarUsuario(2);
              } else {
               //console.log(red);

                this.state.RedSocial = 1;
                this.state.Nombre = datos.profileObj.name;
                this.state.Correo = datos.profileObj.email;
                this.state.ContrasenaRegistro = "";
                this.state.ConfirmacionContrasena = "";
                this.state.UsuarioRegistro = datos.profileObj.name;
                // this.state.FotoPerfil =datos.profileObj.imageUrl;
                this.state.idTokenSocialNetwork = datos.profileObj.googleId;
                this.state.TokenSocialNetwork = datos.accessToken;

                //   this.setState({
                //     RedSocial:1,
                //     Nombre: datos.profileObj.name,
                //     Correo: datos.profileObj.email,
                //     ContrasenaRegistro: "",
                //     ConfirmacionContrasena: "",
                //     UsuarioRegistro: "",
                //     FotoPerfil: datos.profileObj.imageUrl,
                //     idTokenSocialNetwork: datos.profileObj.googleId,
                //     TokenSocialNetwork: datos.accessToken,
                //     // errorUsuarioRegistro: true,
                //     // errorContrasenaRegistro: true,
                //     // errorConfirmacionContrasena: true,

                //   }, () => this.RegistrarUsuario(2));
                this.RegistrarUsuario(2);
              }
            }
          }
        }
      })
      .catch((err) => console.error(err));
  }

  CambiarTab(tab) {
    if (tab == 2) {
      this.setState({
        CambiarTab: 2,
      });
    } else if (tab == 0) {
      this.setState({
        RedSocial: null,
        CambiarTab: 0,
        UsuarioRegistro: "",
        ContrasenaRegistro: "",
        ConfirmacionContrasena: "",
        UsuarioRegistro: "",
        Nombre: "",
        Correo: "",
        Telefono: "",
        errorNombre: false,
        errorCorreo: false,
        errorTelefono: false,
        errorUsuarioRegistro: false,
        errorContrasenaRegistro: false,
        errorConfirmacionContrasena: false,
        errorTipoUsuario: false,
      });
    } else {
      this.setState({
        CambiarTab: 1,
      });
    }
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      3000
    );
  }

  obtenerEmpresa() {
    fetch(Globales.Url + "ObtenerPrecioMembresia", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
        } else {
          this.setState({
            PrecioMembresia: data,
          });
          this.ObtenerMembresias();
        }
      });
  }

  ObtenerMembresias() {
    fetch(Globales.Url + "ObtenerMembresiaVigente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
        } else {
          if (data.data != null) {
            this.setState({
              DescuentoMembresia: data.data.PorcentajeDescuento,
              PrecioMembresiaDescuento:
                this.state.PrecioMembresia -
                this.state.PrecioMembresia *
                  (data.data.PorcentajeDescuento / 100),
            });
          } else {
          }
        }
      });
  }

  render() {
    const { classes, ...rest } = this.props;

    const responseFacebook = (response) => {
      var datos = response;
      
      // if (this.state.CambiarTab == 0) {
        if(datos.status == "unknown"){

        }else{

          this.ValidarExistenciaUsuarioSocialNetwork(datos.userID, datos, 0);
        }

      // } else {
      //   this.setState({
      //     RedSocial: 0,
      //     Nombre: datos.name,
      //     Correo: datos.email,
      //     ContrasenaRegistro: "",
      //     ConfirmacionContrasena: "",
      //     UsuarioRegistro: "",
      //     FotoPerfil: datos.picture.data.url,
      //     idTokenSocialNetwork: datos.userID,
      //     TokenSocialNetwork: datos.accessToken,
      //     errorUsuarioRegistro: true,
      //     errorContrasenaRegistro: true,
      //     errorConfirmacionContrasena: true,

      //   }, () =>
      //   //  document.getElementById("UsuarioRegistro").focus();
      //   this.RegistrarUsuario(1)
      //    );
      // }
    };

    const responseGoogle = (response) => {
      let credentials = response.credential;
      let objCredentials = jwt_decode(credentials);
      let datos = objCredentials;
      let profile = {};
      profile.googleId = datos.sub;
      profile.name = datos.name;
      profile.email = datos.email;

      let newDatos = {
        id : datos.sub,
        profileObj : profile,
        accessToken : credentials,
        datos : datos
      }
      // if (this.state.CambiarTab == 0) {
      this.ValidarExistenciaUsuarioSocialNetwork(
        newDatos.profileObj.googleId,
        newDatos,
        1
      );
      // } else {
      //   this.setState({
      //     RedSocial: 1,
      //     Nombre: datos.profileObj.name,
      //     Correo: datos.profileObj.email,
      //     ContrasenaRegistro: datos.profileObj.googleId,
      //     ConfirmacionContrasena: datos.profileObj.googleId,
      //     UsuarioRegistro: "",
      //     FotoPerfil: datos.profileObj.imageUrl,
      //     idTokenSocialNetwork: datos.profileObj.googleId,
      //     TokenSocialNetwork: datos.accessToken,
      //     errorUsuarioRegistro: true,
      //     errorContrasenaRegistro: true,
      //     errorConfirmacionContrasena: true,

      //   }, () => document.getElementById("UsuarioRegistro").focus());

      //}
    };

    return (
      <div style={{marginTop:'-80px'}}>
        <Header
          absolute
          color="transparent"
          links={<HeaderLinks dropdownHoverColor="dark" />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Card className={classes.cardSignup}>
                  <span className={classes.cardTitle} style={{fontWeight:'normal',fontSize:'1.5rem'}}>
                    {this.state.CambiarTab != 2 ? (
                      <div>
                        <a
                          style={{
                            fontWeight:
                            this.state.CambiarTab == 1
                              ? "normal"
                              : "",
                            textDecoration:
                              this.state.CambiarTab == 0 ? "underline" : "none",
                            cursor:
                              this.state.CambiarTab == 1
                                ? "pointer"
                                : "default",
                          }}
                          onClick={() => this.CambiarTab(0)}
                        >
                          {" "}
                          Inicia sesión
                        </a>
                      </div>
                    ) : (
                      <div>
                        <a> Recuperar Contraseña</a>
                      </div>
                    )}
                  </span>

                  <CardBody>
                    {this.state.CambiarTab == 0 ? (
                      <GridContainer justify="center">
                        {/* Inicio de Sesión */}
                    

                        <GridItem xs={12} sm={12} md={12}>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={6} md={6}>
                              <div className={classes.textCenter}>
                                <LoginButton />
                        
                              </div>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    ) : this.state.CambiarTab == 1 ? (
                      <GridContainer justify="center">
                        {this.state.VerMensajeSinRegistro == true ? (
                          <GridItem xs={12} sm={6} md={3}>
                            <div style={{ marginTop: "7em" }}>
                              <Card>
                                <CardBody>
                                  <span>
                                    No hemos encontrado una cuenta asociada a tu
                                    perfil de{" "}
                                    {this.state.RedSocial == 0
                                      ? "Facebook"
                                      : this.state.RedSocial == 1
                                      ? "Google"
                                      : ""}{" "}
                                    , por favor registrate.
                                  </span>
                                </CardBody>
                              </Card>
                            </div>
                          </GridItem>
                        ) : null}

                        <GridItem xs={12} sm={12} md={8}>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={6} md={6}>
                              <CustomInput
                                labelText="Nombre de Usuario*"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  required: true,
                                  onChange: this.handleChange,
                                  value: this.state.UsuarioRegistro,
                                  name: "UsuarioRegistro",
                                  id: "UsuarioRegistro",
                                  type: "text",
                                  error: this.state.errorUsuarioRegistro,
                                  autoComplete: "off",
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                              <CustomInput
                                labelText="Contraseña*"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  required: true,
                                  onChange: this.handleChange,
                                  value: this.state.ContrasenaRegistro,
                                  name: "ContrasenaRegistro",
                                  id: "ContrasenaRegistro",
                                  type: "password",
                                  error: this.state.errorContrasenaRegistro,
                                }}
                              />
                            </GridItem>
                          </GridContainer>

                          <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                              <CustomInput
                                labelText="Confirmación de Contraseña*"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  required: true,
                                  onChange: this.handleChange,
                                  value: this.state.ConfirmacionContrasena,
                                  name: "ConfirmacionContrasena",
                                  id: "ConfirmacionContrasena",
                                  type: "password",
                                  error: this.state.errorConfirmacionContrasena,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                              <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                              >
                                <InputLabel
                                  htmlFor="TipoUsuario"
                                  className={classes.selectLabel}
                                >
                                  Tipo de Usuario
                                </InputLabel>
                                <Select
                                  value={this.state.TipoUsuario}
                                  //  value = {global.NombreMateriaPrima}
                                  onChange={this.handleSimple}
                                  name="TipoUsuario"
                                  id="TipoUsuario"
                                  inputProps={{}}
                                >
                                  <MenuItem disabled>Tipo de Usuario</MenuItem>
                                  <MenuItem value="Cliente">Cliente</MenuItem>
                                  <MenuItem value="Artista">Artista</MenuItem>
                                </Select>
                              </FormControl>
                            </GridItem>
                          </GridContainer>

                          {this.state.TipoUsuario == "Artista" ? (
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <p style={{ textAlign: "justify" }}>
                                  Al regístrarte como Artista podrás tener tu
                                  propia ecommerce en la cuál puedes publicar
                                  tus diseños y recibir comisiones por cada
                                  venta, estos beneficios los podrás obtener por
                                  tan solo{" "}
                                  {"$" +
                                    parseFloat(this.state.PrecioMembresia, 10)
                                      .toFixed(2)
                                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                                      .toString()}{" "}
                                  mensuales, completa tu registro dando clic en
                                  "Registrate".
                                </p>
                              </GridItem>
                            </GridContainer>
                          ) : null}

                          <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                              <CustomInput
                                labelText="Nombre Completo"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  required: true,
                                  onChange: this.handleChange,
                                  value: this.state.Nombre,
                                  name: "Nombre",
                                  id: "Nombre",
                                  type: "text",
                                  autoComplete: "off",
                                  error: this.state.errorNombre,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                              <CustomInput
                                labelText="Correo"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onBlur: this.ValidarCorreo,
                                  required: true,
                                  onChange: this.handleChange,
                                  value: this.state.Correo,
                                  name: "Correo",
                                  id: "Correo",
                                  type: "email",
                                  error: this.state.errorCorreo,
                                  autoComplete: "off",
                                }}
                              />
                            </GridItem>
                          </GridContainer>

                          <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                              <CustomInput
                                labelText="Teléfono"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  error: this.state.errorTelefono,
                                  onBlur: this.ValidarTelefono,
                                  required: true,
                                  onChange: this.handleChange,
                                  value: this.state.Telefono,
                                  name: "Telefono",
                                  id: "Telefono",
                                  type: "text",
                                  autoComplete: "off",
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer style={{marginTop:'10px'}}>
                            <GridItem xs={12} sm={12} md={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                  style={{marginTop:'0px'}}
                                    checked={this.state.avisoPrivacidad}
                                    onChange={this.handleAvisoProvacidadChange}
                                    name="avisoprivacidad"
                                  />
                                }
                                label={
                                  <span>
                                    HE LEÍDO Y ACEPTO EL {" "}
                                    <a
                                      href="/#/AvisoPrivacidad"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      AVISO DE PRIVACIDAD&nbsp; 
                                    </a>
                                  </span>
                                }
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.aceptoTerminos}
                                    onChange={this.handleAceptoTerminosChange}
                                    name="aceptoTerminos"
                                  />
                                }
                                label={
                                  
                                  <span style={{marginTop:'20px !important'}}>
                                    HE LEÍDO Y ACEPTO LOS{" "}
                                    <a
                                      href="/#/TerminosCondiciones"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      TÉRMINOS Y CONDICIONES&nbsp; 
                                    </a>
                                    DE USO DEL SERVICIO.
                                  </span>
                                }
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer xs={12} sm={12} md={12} >
                            <div className="col-12" style={{height:'20px'}}>

                            </div>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                              <div className={classes.textCenter} onClick={() => this.handleClick}>
                                <Button
                                  round
                                  color="info"
                                  style={{width:'230px'}}
                                  disabled={!this.state.aceptoTerminos || !this.state.avisoPrivacidad}
                                  onClick={() => this.RegistrarUsuario(1)}
                                >
                                  <PersonAdd />{" "}
                                  &nbsp;&nbsp;Regístrate&nbsp;&nbsp;
                                </Button>
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                              <div className={classes.textCenter} onClick={() => this.handleClick}>
                                <FacebookLogin
                                  appId="2710913458954459" //APP ID NOT CREATED YET
                                  fields="name,email,picture"
                                  callback={responseFacebook}
                                  isDisabled={!this.state.aceptoTerminos || !this.state.avisoPrivacidad}
                                  render={(renderProps) => (
                                    // <button onClick={renderProps.onClick}>This is my custom FB button</button>
                                    <Button
                                      onClick={renderProps.onClick}
                                      color="facebook"
                                      round
                                      style={{ textTransform: 'none',fontSize:'14px', paddingLeft: '15px',
                                      paddingBottom: '5px',
                                      paddingTop: '5px', }}

                                    >
                                        <img
                                          src={facebookicon}
                                          alt="Logo de Facebook"
                                          style={{ 
                                            width: '30px', 
                                            height: '30px',
                                            marginRight:'10px'
                                            }} 
                                        />
                                      &nbsp;Acceder con Facebook
                                    </Button>
                                  )}
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4} style={{PointerEvent:'none !important'}}>
                              <div style={{marginTop :'5px',PointerEvent:'none !important'}} onClick={() => this.handleClick} >
                                
                              <GoogleOAuthProvider clientId="921980953446-eb8hlmt4l83se8g5k6bm4thmdicfnb9e.apps.googleusercontent.com">
                                <React.StrictMode >
                                <div>
                                  <div> 
                                    <GoogleLogin
                                      onSuccess={responseGoogle}
                                      isSignedIn={true}
                                      text="signup_with"
                                      shape="circle"
                                      onError={responseGoogle}
                                      cookiePolicy={"single_host_origin"}
                                      theme="filled_blue"
                                      size="large"
                                      type="standard"
                                    />
                                  </div>
                                </div>
                                </React.StrictMode>                              
                              </GoogleOAuthProvider>

{/*                                 <GoogleLogin
                                  clientId="779072120103-2lhs20arcuheu4ls5a7auihcfnulr5ui.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                                  buttonText="LOGIN WITH GOOGLE"
                                  onSuccess={responseGoogle}
                                  onFailure={responseGoogle}
                                  cookiePolicy={"single_host_origin"}
                                  isSignedIn={true}
                                  disabled={!this.state.aceptoTerminos || !this.state.avisoPrivacidad}
                                  render={(renderProps) => (
                                    // <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                                    <Button
                                      onClick={renderProps.onClick}
                                      onMouseDown={renderProps.onClick}
                                      
                                      color="google"
                                      round
                                    >
                                      <i className="fab fa-google-plus-square" />
                                      &nbsp;&nbsp;con Google&nbsp;&nbsp;
                                    </Button>
                                  )}
                                /> */}
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3} />
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    ) : (
                      <GridContainer justify="center">
                        <GridContainer style={{ maxWidth: "400px" }}>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="Proporcione el correo de registro"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                onChange: this.handleChange,
                                required: true,
                                value: this.state.CorreoRecuperar,
                                name: "CorreoRecuperar",
                                id: "CorreoRecuperar",
                                type: "email",
                                error: this.state.errorCorreoRecuperar,
                                autoComplete: "off",
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Button
                              round
                              color="danger"
                              onClick={() => this.CambiarTab(0)}
                            >
                              <Close />
                              Cancelar
                            </Button>
                            <Button
                              round
                              color="success"
                              onClick={() => this.RecuperarContrasena()}
                            >
                              <Mail />
                              Recuperar
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </GridContainer>
                    )}

                    {/* Registro de clientes o artistas */}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(signupPageStyle)(Components);

// if (this.state.TipoUsuario === 'Artista') {
//   if (this.state.CuentaBancaria.trim() === '') {
//     this.setState({ errorCuentaBancaria: true, colorNotificacion: 'danger', mensaje: 'Ingrese los campos requeridos' });
//     this.showNotification();
//     valor4 = 1;
//   } else {
//     this.setState({ errorCuentaBancaria: false });
//     valor4 = 0;
//   }
//   if (valor1 + valor2 + valor3 + valor4 === 0) {
//     fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/RegistrarCliente', {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
//       }
//       , body: JSON.stringify(this.state)
//     }
//     ).then(res => res.json())
//       .then(data => {
//         if (data.error) {
//           this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
//           this.showNotification();
//         } else {
//           this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, Nombre: '', Correo: '', ConfirmacionContrasena: '',
// TipoUsuario: '', CuentaBancaria: '', Telefono: '', UsuarioRegistro: '', ContrasenaRegistro: '', errorNombre: false, errorCorreo: false,
// errorTelefono: false, errorCuentaBancaria: false, errorUsuarioRegistro: false, errorContrasenaRegistro: false, errorConfirmacionContrasena: false, errorTipoUsuario: false });
//           this.showNotification();
//         }
//       })
//       .catch(err => console.error(err));
//   }
// } else {
//   if (valor1 + valor2 + valor3 === 0) {
//     fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/RegistrarCliente', {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
//       }
//       , body: JSON.stringify(this.state)
//     }
//     ).then(res => res.json())
//       .then(data => {
//         if (data.error) {
//           this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
//           this.showNotification();
//         } else {
//           this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, Nombre: '',
// Correo: '', ConfirmacionContrasena: '', TipoUsuario: '', CuentaBancaria: '', Telefono: '',
// UsuarioRegistro: '', ContrasenaRegistro: '', errorNombre: false, errorCorreo: false, errorTelefono: false,
//errorCuentaBancaria: false, errorUsuarioRegistro: false, errorContrasenaRegistro: false, errorConfirmacionContrasena: false, errorTipoUsuario: false });
//           this.showNotification();
//         }
//       })
//       .catch(err => console.error(err));
//   }
// }
