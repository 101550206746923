import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.jsx";
import Globales from "utils/Globales";
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import 'react-animated-slider/build/slider-animations.css';
import 'react-animated-slider/build/styles.css';

class BannerInicio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colorSelect: "0",
      sizeSelect: "0",
      imagen: null
    };
  }

  componentDidMount() {
    // imagen = 
    // fetch(Globales.Url + 'Seccion1/banner.jpg', {
    //   method: 'GET'
    // })
    //   .then(res => res.json())
    //   .then(data => {
    //     if (data.error) { //Error al obtener los registros          
    //       this.setState({ imagen: '' });
    //     } else { //Obtuvo los registros correctamente
    //       this.setState({ imagen: data });
    //     }
    //   });
    // var a = [Globales.Url + 'Seccion1/banner.jpg'],
    // var b = [Globales.Url + 'Seccion1/banner.jpg'],
    // var c = [Globales.Url + 'Seccion1/banner.jpg'],
    // var d = [Globales.Url + 'Seccion1/banner.jpg'],
    // var e = [Globales.Url + 'Seccion1/banner.jpg']
    // var array = [a, b]
    // this.setState({ imagen: array });
  }

  render() {
    const { classes, ...rest } = this.props;
    const styleParallax = {
      height: "10vh",
      overflow: "hidden"
    }
    
    const properties = {
      //duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true,
      autoplay: 5000
    }
    
    const content = [
      { image: Globales.Url + 'Seccion1/jersey.jpg', title: 'Personaliza tus Jerseys', description: 'Agrega tu diseño, texto, stickers y mucho más...', button: 'Empieza ya', referencia: '/#/SeleccionaTuDiseño' },
      { image: Globales.Url + 'Seccion1/tshirt.jpg', title: 'Miles de diseños a elegir', description: 'Contamos con librería en línea de más de 2,000 diseños', button: 'Personaliza la tuya', referencia: '/#/SeleccionaTuDiseño' },
      { image: Globales.Url + 'Seccion1/artistas.jpg', title: 'Diseña y vende', description: 'Comecializa tus creaciones únicas y personalizadas a tus clientes', button: 'Registrate', referencia:  '/#/login' }
    ];

    return (
      <div style={{ height: '100vh' }}>
        <Slider {...properties} 
          style={{ height: '100vh',}}
          className="slider-custom"
        >
        {content.map((item, index) => (
          <div key={index}
            className="slider-content"
            style={{ background: `url('${item.image}') no-repeat center center` }} >
            <div className="inner" 
              >
                <h1 style={{ color: `rgb(7, 7, 7)`, textShadow: `rgb(255, 255, 255) 3px 3px` }}>{item.title}</h1>
                <p style={{ color: `rgb(7, 7, 7)`, textShadow: `rgb(255, 255, 255) 3px 3px 3px` }}>{item.description}</p>
                <button onClick={()=>window.location.href = item.referencia}>{item.button}</button>
            </div>
          </div>
        ))}
      </Slider>
      </div>
    );
  }
}

export default withStyles(headersStyle)(BannerInicio);
