import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import UserIcon from "@material-ui/icons/Person";
import ShoppingCartOutlined from "@material-ui/icons/ShoppingCartOutlined";
import Cookies from 'js-cookie';
import ModalAccion from '../Modales/ModalAccion.jsx';
import Globales from "utils/Globales";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import LoginButton from "../../Aunt0/loginbutton.js";
import LogoutButton from "../../Aunt0/logoutbutton.js";

function HeaderLinks(props) {
  const { classes, dropdownHoverColor } = props;
  const { isAuthenticated, user } = useAuth0();
  const [auth, setAuth] = useState(null);
  const [liveDemo, setLiveDemo] = useState(false);

  const handleClickOpen = (modal) => {
    setLiveDemo({ [modal]: true });
  };

  const handleClose = (modal) => {
    setLiveDemo({ [modal]: false });
  };

  const openModal = () => {
    setLiveDemo(true);
  };

  const irPerfil = () => {
    window.location.href = '/#/Perfil';
  };

  const eventoModal = (e) => {
    if (!e) {
      setLiveDemo(false);
      return;
    }
    // cerrarSesion();
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      // Llama a la función Login cuando el usuario esté autenticado y se haya cargado
     ValidarUsuario(user);

    }
  }, [isAuthenticated, user]);

  const Login = (user) => {
    fetch(Globales.Url + "IniciarSesionCliente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token
      },
      body: JSON.stringify({
        Usuario: user.name,
        RedSocial: user.sub
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.status !== 404) {
          // Actualiza el estado con la respuesta del servidor
          setAuth(data);
          if (data.response === true) {
            var sesion = {
              id: data.usuario._id,
              Carrito: data.carrito,
              ListaCarrito: data.usuario.ListaCarrito,
              ListaDeseos: data.usuario.ListaDeseos,
              Usuario: data.usuario.Usuario,
              Nombre: data.usuario.Nombre,
              Correo: data.usuario.Correo,
              Telefono: data.usuario.Telefono,
              TipoUsuario: data.usuario.TipoUsuario,
              Token: data.token,
              IndicadorTerco: data.usuario.IndicadorTerco,
            };
            Cookies.set("UsuarioTerco", JSON.stringify(sesion));
            Cookies.set("Id", data.usuario._id);
            Cookies.set("token", data.token);
            localStorage.setItem("idUser", data.usuario._id);
            Globales.token = "Bearer " + data.token;
            let dt = new Date();
            localStorage.setItem(
              "expira",
              new Date(dt.getTime() + 20 * 60000)
            );
            if (Cookies.get("idDiseno") != null) {
              Cookies.set("login", 1);
              window.opener.top.location.reload();
              window.close();
            }
          }
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  const RegistrarUsuario = (usuarioNuevo) => {
    fetch(Globales.Url + "UsuarioClientes/UsuarioClientes/RegistrarCliente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify(usuarioNuevo)
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Error en la solicitud');
        }
        return res.json();
      })
      .then((data) => {
        if (data.response === true) {
          Login(user); 
          window.location.reload();
          cerrarSesion()
          window.location.reload();
          Login(user); 

        } else {
          console.error('Error en el registro:', data.message); // si hay un mensaje de error en la respuesta
        }
      })
      .catch((err) => console.error(err));
  };
 
  const ValidarUsuario = (user) =>
  {
    fetch(
      Globales.Url + "UsuarioClientes/UsuarioClientes/ObtenerIdSocialNetwork",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({ idTokenSocialNetwork: user.sub }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.data != null) {
          Login(user);
        }else{
          var usuarioNuevo={
            Nombre: user.name,
            Correo: user.email,
            TipoUsuario: "Cliente",
            ContrasenaRegistro: user.sub,
            ConfirmacionContrasena: user.sub,
            UsuarioRegistro: user.nickname,
            idTokenSocialNetwork: user.sub,
            TokenSocialNetwork: user.sub,
            RedSocial: 0
          };
          RegistrarUsuario(usuarioNuevo);
        }
      })
      .catch((err) => console.error(err));
  };

  const cerrarSesion = () => {
    setLiveDemo(false);
    var sesion = {
      id: '',
      Carrito: "",
      ListaCarrito: [],
      ListaDeseos: [],
      Usuario: "",
      Nombre: "",
      Usuario: "",
      Correo: "",
      Telefono: "",
      TipoUsuario: "",
      token: "",
      IndicadorTerco: "",
    }
    Cookies.set("UsuarioTerco", JSON.stringify(sesion))
    Cookies.set("Id", "")
    localStorage.setItem("idUser", "")
    Globales.token = '';
  }

  const CambiarRuta = (ruta) => {
    window.location.href = ruta;
  }

  const popover = {
    position: 'absolute',
    right: '0',
  }

  return (
    <div style={popover}>
      <List className={classes.list + " " + classes.mlAuto} style={{ fontSize: "12px" }}>
        <ListItem className={classes.listItem}>
          <Link to="/" className={classes.dropdownLink} >INICIO</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/SeleccionaTuDiseño" className={classes.dropdownLink} >DISEÑADOR</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/Artistas" className={classes.dropdownLink} >ARTISTAS</Link>
        </ListItem>
        {
          isAuthenticated ?
            <ListItem className={classes.listItem} style={{ border: "1px solid #fefefe", borderRadius: 5 }}>
              <CustomDropdown
                noLiPadding
                navDropdown
                hoverColor={dropdownHoverColor}
                buttonText={user.name}
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent"
                }}
                dropdownList={[
                 
                            <Link to="/Perfil" className={classes.dropdownLink} >PERFIL</Link>,

                    <Link to="#" onClick={() => cerrarSesion()} className={classes.dropdownLink}>
                      <LogoutButton />
                  </Link>,
                ]}
              />
            </ListItem>
            :
            <ListItem className={classes.listItem}>
              <LoginButton />
            </ListItem>
        }
        {
          JSON.parse(Cookies.get("UsuarioTerco")).id !== "" ?
            <ListItem>
              <Link className={classes.dropdownLink} to="/carrito">
                {
                  window.innerWidth < 960 ? "Carrito " + "(" + JSON.parse(Cookies.get("UsuarioTerco")).Carrito + ")" :
                    <div style={{ backgroundColor: "212121", color: "#fefefe", }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-cart3" viewBox="0 0 16 16">
                        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                      </svg>
                    </div>
                }
              </Link>
            </ListItem>
            : null
        }
      </List>
      <div id="">
        <ModalAccion
          Titulo='Cerrar Sesión'
          body={'¿Seguro que deseas cerrar sesión ' + JSON.parse(Cookies.get('UsuarioTerco')).Usuario + '?'}
          liveDemo={liveDemo}
          eventClick={eventoModal}
        />
      </div>
    </div>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};

export default withStyles(headerLinksStyle)(HeaderLinks);