import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "../Generales/Header/Header.jsx";
import HeaderLinks from "../Generales/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import SeecionListaDeseo from './SeccionListaDeseo';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.jsx";


class EcommercePage extends React.Component {
  constructor(props, context) {
    super(props, context);
  
    this.state = {
        
      }
      this.ActualizarCarrito = this.ActualizarCarrito.bind(this)
    }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  ActualizarCarrito(){
    this.setState({a:"a"});
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "dark"
          }}
        />
        <Parallax
          image={require("assets/img/examples/clark-street-merc.jpg")}
          filter="dark"
          // small
          className={classes.parallax}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                {/* <div className={classes.brand}>
                  <h1 className={classes.title}>Mi Lista de Deseos!</h1>
                </div> */}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <SeecionListaDeseo ActualizarCarrito={this.ActualizarCarrito} />          
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(EcommercePage);
