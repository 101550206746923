import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.jsx";


class Encabezado extends React.Component {
  constructor(props) {
    super(props);
  }
 
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
   
    return (
      <div className={classes.productPage}>
        <Header
          brand=""
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 100,
            color: "info"
          }}
        />
        <Parallax
          image={require("assets/img/bg6.jpg")}
          filter="info"
          className={classes.pageHeader}
        ></Parallax>
      </div>
    );
  }
}

export default withStyles(productStyle)(Encabezado);
