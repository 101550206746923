import React from "react";
import ReactDOM from "react-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Globales from "utils/Globales";
// core components
import Header from "../Generales/Header/Header.jsx";
import HeaderLinks from "../Generales/Header/HeaderLinks.jsx";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.jsx";

import product1 from "assets/img/examples/product1.jpg";
import product2 from "assets/img/examples/product2.jpg";
import product3 from "assets/img/examples/product3.jpg";
import product4 from "assets/img/examples/product4.jpg";
import convert from "color-convert";
import Cookies from "js-cookie";

class ProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colorSelect: "0",
      sizeSelect: "0",
      modelos3D: [],
      idModelo: '',
      verModal: false,
      iFrameHeight: '0px',
      nombre: "",
      descripcion: "",
      idCategoria: ""
    };
    this.eventoModal = this.eventoModal.bind(this);
  }
  handleSelect = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var disenioSeleccionado =
      localStorage.getItem("disenioSeleccionado") == undefined ||
      localStorage.getItem("disenioSeleccionado") == null ||
      localStorage.getItem("disenioSeleccionado") == "" ||
      localStorage.getItem("disenioSeleccionado") == "null"
        ? {
            nombre: "Diseño sin guardar",
            descripcion: "",
            idCategoriaDiseno: ""
          }
        : JSON.parse(localStorage.getItem("disenioSeleccionado"));
    this.setState({
      nombre: disenioSeleccionado.nombre,
      descripcion: disenioSeleccionado.descripcion,
      idCategoria: disenioSeleccionado.idCategoriaDiseno
    });
    window.addEventListener("message", this.handleMessage);
    //console.log("aqui es w ")
  }

  componentWillUnmount() {
    localStorage.removeItem("idModelo");
    localStorage.removeItem("idTalla");
    localStorage.removeItem("login");
    localStorage.removeItem("idDiseno");
    Cookies.remove("ListaDisenos");
    window.removeEventListener('message', this.handleMessage);
  }
  handleMessage = (event) => {
    if (event.data.type === 'funcionHexToCMYK') {
      const valor = this.convertirHExToCMYK(event.data.colorHex);
      window.frames[0].postMessage({ type: 'respuesta', data: valor }, '*');
    }
  }
  obtenerModelos() {
    fetch(Globales.Url + 'Modelos/Modelos/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ modelos3D: data.data })

        // this.setState({
        //   title: data.title,
        //   description: data.description,
        //   _id: data._id
        // });
      });
  }

  convertirRGBtoCMYK(){
    const data = convert.rgb.cmyk(167, 255, 4);
    const stringData = JSON.stringify(data);
    return stringData;
  }

  convertirHExToCMYK(colorHex){
    const selectedColor = colorHex;
    try {
        const cmykColor = convert.hex.cmyk(selectedColor);
        return('Conversión exitosa a CMYK:', cmykColor);
    } catch (error) {
        return('No es posible convertir a CMYK:', error.message);
    }
  }

  obtenerModeloTresD() {
    fetch(Globales.Url + `Modelos/Modelos/${localStorage.getItem("idModelo")}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {

        // this.setState({
        //   title: data.title,
        //   description: data.description,
        //   _id: data._id
        // });
      });
  }

  openModal = () => {
    this.setState({ liveDemo: true });
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  eventoModal(e) {
    if (!e) {
      this.setState({ verModal: false });
      return;
    }
    this.setState({ verModal: false });
  }
  render() {
    const images = [
      {
        original: product3,
        thumbnail: product3
      },
      {
        original: product4,
        thumbnail: product4
      },
      {
        original: product1,
        thumbnail: product1
      },
      {
        original: product2,
        thumbnail: product2
      }
    ];
    const styleParallax = {
      height: "20vh",
      overflow: "hidden"
    }
    return (
      <div style={{ borderBlockColor: `red` }}>

        {/* <ModalConfiguracionProducto  eventoModal={this.eventoModal}  verModal={this.state.verModal}/>  */}

        <Header
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
          color="dark"
        />

        <iframe id="iframePrincipalDiseno"
          onLoad={() => {
            const obj = ReactDOM.findDOMNode(this);
            // this.setState({
            //     "iFrameHeight":  obj.ownerDocument.body.scrollHeight + 200 + 'px'
            //   });
          }}
          frameBorder="0"
          style={{
            width: "100%",
            height: "100%",
            position: "fixed"
          }}
          src={`${Globales.Url}componente3d?id=${localStorage.getItem(
            "idModelo"
          )}&tipo=${localStorage.getItem("tipo")}&t=${localStorage.getItem(
            "idTalla"
          )}&idUser=${localStorage.getItem("idUser")}&nombre=${btoa(
            this.state.nombre
          )}&categoria=${this.state.idCategoria}&descripcion=${btoa(
            this.state.descripcion
          )}`}
          //   src={`http://54.89.80.96:5000/componente3d?id=5d8e97e3ba6b701b1bc13ba3&tipo=1`}
          title="Icons iframe"
          allowFullScreen
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductPage);
