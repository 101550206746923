import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ModalAccion extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick()
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: this.props.size === 'large' ? (classes.modal + ' ' + classes.modalLarge) : classes.modal
      }}
          open={this.props.liveDemo}
        TransitionComponent={Transition}
        keepMounted
          onClose={() => this.handleClose("liveDemo")}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
          <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => this.props.eventClick(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{this.props.Titulo}</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <p>{this.props.body}</p>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button round size='sm' onClick={() => this.props.eventClick(true)} color="success">Aceptar</Button>
          <Button round size='sm' onClick={() => this.props.eventClick(false)} color="danger" >Cancelar</Button>
        </DialogActions>
        </Dialog>
      </div >
    );
  }
}

export default withStyles(modalStyle)(ModalAccion);