import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// core components
import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Card from "components/Card/Card.jsx";

import { OfflinePin, Home, ShoppingCart } from "@material-ui/icons";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { Link } from "react-router-dom";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class ModalTalla extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {


        }
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.EventoModalTalla()
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);

    }
    componentWillMount() {
        this.setState({

            Tallas: this.props.Tallas,
            Producto: this.props.Producto,
        }, () => {
        });


    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);

    }
    IrCompras(){
            localStorage.setItem("VerCompras",1)
            window.location.href="#/Perfil"
    }



    render() {
        const { classes } = this.props;


        return (
            <div >
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal
                    }}
                    open={this.props.VerModal}
                    TransitionComponent={Transition}
                    keepMounted
                    // onClose={() => this.props.EventoModalTalla}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <Card plain className={classes.modalSignupCard}>
                        {/* <CardHeader color="success">
                            <center>
                            Compra exitosa

                            </center>
                        </CardHeader> */}
                        {/* <DialogTitle
                            id="signup-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <Button
                                simple
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.EventoModalTalla()}
                            >
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                      

                        </DialogTitle> */}
                        <DialogContent>
                            <GridContainer justify="center">
                                <GridItem lg={12} md={12} sm={12} xs={12}>
                                    <center>
                                        <Button fullWidth justIcon round color="success">

                                            <OfflinePin />

                                        </Button>
                                        {
                                            this.props.FormaDePago == 0 ?
                                                <h4>Tu pedido se ha enviado a fabricar.</h4>

                                                :
                                        <p>Hemos enviado al correo {this.props.Correo} las instrucciones para continuar con el proceso de pago.</p>

                                        }

                                    </center>
                                </GridItem>

                            </GridContainer>

                            <GridContainer justify="center">
                                <GridItem lg={6} md={6} sm={12} xs={12}>
                                    <center>

                                        <Link to="/">

                                            <Button color="github"> Inicio <Home /></Button>
                                        </Link>
                                    </center>
                                </GridItem>
                                <GridItem lg={6} md={6} sm={12} xs={12}>
                                    <center>


                                            <Button onClick={()=> this.IrCompras()} color="github"> Mis compras <ShoppingCart /></Button>
                                    </center>
                                </GridItem>

                            </GridContainer>







                        </DialogContent>

                    </Card>
                </Dialog>
                {/* <div id="">
          <ProductPage
            idModelo={this.state.idModelo}
          />
        </div> */}
            </div >
        );
    }
}

export default withStyles(modalStyle)(ModalTalla);

