/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { HelpOutline } from "@material-ui/icons";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.jsx";

import Cookies from 'js-cookie'
import {  Favorite, StoreMallDirectory, Delete} from "@material-ui/icons";
import CamisaIcono from "../../../node_modules/@icons/material/TshirtCrewIcon"

import ModalCargando from '../Generales/Modales/ModalCargando';

import Globales from "utils/Globales";
import ModalProducto from "../SeleccionProducto/ModalTalla";

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Cargando from '../Generales/Cargando.jsx';

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const clasesTool = useStyles;


// core components

class TiendaArtista extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Cargando: false,
      VerBr: false,
      colorNotificacion: "",
      mensaje: "",
      ListaFavoritos: [],
      ProductosTiendaGeneral: [],
      checked: [0],
      CategoriasDisenoCliente: [],
      ProductosTienda: [],
      Tallas: [],
      ProductosTallas: [],
      CuerpoCargando: "",
      VerCargando: "",
      VerModalDetalles: false,
      TipoUsuario: JSON.parse(Cookies.get("UsuarioTerco")).TipoUsuario,
    };
    this.AgregarFavoritos = this.AgregarFavoritos.bind(this);
    global.Index = 0;
  }
  componentDidMount() {
    //console.log("tienda artista-----", this.props);
    this.setState({
      Cargando: true
    })
    if(this.props.idCliente!=""){
      this.ObtenerCategorias();
    }else{
      this.ObtenerProductosDeseos();
    }
    
  }
  ObtenerProductosDeseos() {

    fetch(Globales.Url + 'ListaDeseos/ListaDeseos/ObtenerProductosListaDeseos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get('UsuarioTerco')).id })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
        } else { //Obtuvo los registros correctamente
          /**verificar rango de precios */
          data.data.map(m => {
            this.state.ListaFavoritos.push(m.diseñoProducto._id)
          })
          this.ObtenerCategorias();
        }
      });
  }

  ObtenerProductosPorDiseno(Producto, idCategoria) {
    /**obtener la info del abanico de productos pars mostrarla */
    fetch(Globales.Url + 'Productos/Productos/ObtenerProductosPorCategoriaTiendaArtista', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idCategoria: idCategoria })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {

        } else {

          var caracteristicasPorTalla = [];
          data.mensaje[0].Producto.map(m => {
            caracteristicasPorTalla.push({ idTalla: m.Tamano, caracteristicasAgregadas: m.caracteristicasAgregadas, atributosAgregados: m.atributosAgregados })

          })
          this.setState({
            CaracteristicasPorTalla: caracteristicasPorTalla

          })

          this.ObtenerTallas(Producto)
        }
      })
  }


  ObtenerTallas(Producto) {
    fetch(Globales.Url + 'Tallas/ObtenerTallasPorDiseno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCategoria: Producto.idModelo.idCategoria })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {
        } else {
          /**obtener tallas originales desde abanico y crear el arreglo para el diseñador 3D */
          var tallas = [];
          //console.log("son las tallas actuales que trae------>",data.data)
          data.data[0].Tallas.map(t => {
            tallas.push(t[0])
          })
          // debugger
          var disenosBueno=[];
          tallas.map(l => {
            // debugger
            //let aux = Enumerable.from(tallas).where(function (x) { return x._id == l.idTalla }).toArray();
            //if (aux.length > 0) {
              var disenoDuplicar = Producto.ListaDisenos[0];
              // debugger
              disenoDuplicar["nombreTalla"] = l.NombreTalla;
              disenoDuplicar.NombreTalla = l.NombreTalla;
              disenoDuplicar["Talla"] = l._id
              disenosBueno.push(disenoDuplicar);
            //}
          })

          this.setState({
            Tallas: tallas,
            ProductosTallas: disenosBueno
          }, () => {
            this.SeleccionarProducto(Producto);
          })
        }
      })
  }
  AgregarFavoritos(id) {
    fetch(Globales.Url + 'Favoritos/AgregarFavoritos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idDiseno: id, idCliente: JSON.parse(Cookies.get("UsuarioTerco")).id })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data)
        if (data.error) {
          this.setState({
            colorNotificacion: "danger",
            mensaje: data.mensaje,
          });
          this.showNotification();
        } else {

          this.ObtenerProductosDeseos();
        }
      })
  }
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      3000
    );
  }
  ObtenerProductos(id) {
    console.log("Cookies",Cookies.get("UsuarioTerco"))
    console.log("----", id)
    /**diseno padre viene el descuento */
    fetch(Globales.Url + 'TiendaArtistas/TiendaArtistas/ObtenerDisenosTienda', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      },
      mode: 'cors'
      , body: JSON.stringify({ idCliente: id ,idUsuarioTerco: JSON.parse(Cookies.get("UsuarioTerco")).id})
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {

        } else {
          console.log("productos del cliente----->", data.data)
          this.setState({ Cargando: false })
          data.data.map(m => {
            var disenosTallas = [];
            var Grupos = [];

            /**obtener id diseno y talla del padre */
            disenosTallas.push({ idDiseno: m.DisenoPadre[0]._id, idPadre: m.DisenoPadre[0]._id, idTalla: m.DisenoPadre[0].idTalla, NombreTalla: "" })
            /**obtener id diseno y talla de los hijos */

            m.DisenoPadre[0].idModelo.GruposMaterial.map(p => {
              Grupos.push(p.PrecioPublico)
            })

            m.DisenosHijos.map(p => {
              disenosTallas.push({ idDiseno: p._id, idPadre: p.idDisenoPadre, idTalla: p.idTalla, nombreTalla: "" })
              if (p.idModelo != null) {
                if (p.idModelo.GruposMaterial != null) {
                  p.idModelo.GruposMaterial.map(m => {
                    Grupos.push(m.PrecioPublico)
                  })
                }
              }
            })

            m["ListaDisenos"] = disenosTallas;
            m["Minimo"] = Math.min.apply(Math, Grupos);
            m["Maximo"] = Math.max.apply(Math, Grupos);
          })
          // var idCategorias = [];
          var idCategoriasDiseno = [];
          var CategoriasDisenoFiltradas = [];
          data.data.map(m => {
            // idCategorias.push(m.idModelo.idCategoria);
            idCategoriasDiseno.push(m.idCategoriaDiseno)
          })
          /**colocar uncamente las categorias donde hay diseños */
          if (this.state.CategoriasDisenoCliente != null) {

            this.state.CategoriasDisenoCliente.map(c => {
              idCategoriasDiseno.map(d => {
                if (c._id == d) {
                  if (CategoriasDisenoFiltradas.length > 0) {
                    var a = CategoriasDisenoFiltradas.findIndex(m => m._id == c._id)
                    if (a == -1) {
                      CategoriasDisenoFiltradas.push(c)
                    }
                  }
                }
              })
            })
          }

          this.state.ListaFavoritos.map(m => {
            data.data.map(d => {
              if (m == d.idDiseno) {
                d["Favorito"] = true;
              } else {
                d["Favorito"] = false;
              }
            })
          })

          this.setState({
            CategoriasDisenoCliente: CategoriasDisenoFiltradas,
            ProductosTiendaGeneral: data.data,
            ProductosTienda: data.data
          }, () => {
            this.ValidarDescuentos()
          });
        }
      })
  }

  ValidarDescuentos() {

    if (global.Index < this.state.ProductosTienda.length && JSON.parse(Cookies.get("UsuarioTerco")).id!="") {
      var idDiseno = this.state.ProductosTienda[global.Index].DisenoPadre[0]._id;
      fetch(Globales.Url + 'Descuentos/Descuentos/VerificarClienteConDescuentoAplicable', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get("UsuarioTerco")).id, idDiseno: idDiseno })
      }
      ).then(res => res.json())
        .then(data => {

          if (data.error) {

          } else {
            if (data.data == false) {
              this.state.ProductosTienda[global.Index].DisenoPadre[0].Descuento = false;
              this.state.ProductosTiendaGeneral[global.Index].DisenoPadre[0].Descuento = false;
            }
            global.Index++;
            this.ValidarDescuentos();
          }
        })

    } else {
      this.setState({ Cargando: false })
    }



  }

  ObtenerPrecios(Productos, Categorias) {
    fetch(Globales.Url + 'TiendaArtistas/TiendaArtistas/ObtenerPrecioProductoPorCategoria', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idsCategorias: Categorias })
    }
    ).then(res => res.json())
      .then(data => {
        // Math.min.apply(Math, numeros)
        if (data.error) {

        } else {
          var ListaPrecios = [];
          var precios = [];
          data.map((m, index) => {
            ListaPrecios.push({ id: index, Precios: [] })
            precios = [];

            m.GruposMateriales.map(p => {
              p.map(c => {
                precios.push(c.PrecioPublico)

              })


            })
            ListaPrecios[index].Precios = precios;

          })

          ListaPrecios.map(m => {
            m["Minimo"] = Math.min.apply(Math, m.Precios);
            m["Maximo"] = Math.max.apply(Math, m.Precios);
          })

          Productos.map((m, index) => {
            m["Minimo"] = ListaPrecios[index].Minimo;
            m["Maximo"] = ListaPrecios[index].Maximo;

          })
          this.setState({
            ProductosTiendaGeneral: Productos,
            ProductosTienda: Productos
          }, () => {

          });
        }
      })
  }


  ObtenerCategorias() {
    fetch(Globales.Url + 'CategoriaDiseno/CategoriaDiseno/ConsultarPorCliente', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCliente: this.props.idCliente })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros

        } else { //Obtuvo los registros correctamente
          //console.log("-------> son las categorias-->", data.data)
          this.setState({
            CategoriasDisenoCliente: data.data
          }, () => {
            this.ObtenerProductos(this.props.idCliente);
          });
        }
      });


  }

  EliminarDisenoTienda(id, idDiseno) {
    this.setState({
      CuerpoCargando: "Eliminando de tienda",
      VerCargando: true
    });

    fetch(Globales.Url + 'TiendaArtistas/TiendaArtistas/EliminarProductoTienda', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idDiseno: id, idCliente: this.props.idCliente, idDisenoProducto: idDiseno })
    }
    ).then(res => res.json())
      .then(Disenos => {
        if (Disenos.error) {
          this.props.EventoEliminarTienda(false);


        } else {
          this.setState({
            CuerpoCargando: "",
            VerCargando: false
          });
          this.ObtenerProductos(this.props.idCliente)
          this.ObtenerDisenosCliente(this.props.idCliente)
          this.props.EventoEliminarTienda(true);

        }
      })
  }


  ObtenerDisenosCliente(id) {
    fetch(Globales.Url + 'DisenoProductos/DisenoProductos/ListarDisenosPorClientePerfil', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idCliente: id })
    }
    ).then(res => res.json())
      .then(Disenos => {
        if (Disenos.error) {

        } else {
          var disenosMostrar = []
          Disenos.data.map(m => {
            if (m.idDisenoPadre == null) {
              disenosMostrar.push(m)
            }
          })
          this.setState({
            Disenos: disenosMostrar
          });

        }
      })
  }
  SeleccionarProducto(Producto) {
    var producto = Producto;
    //console.log("producto selec en tienda---->",producto)
    producto.idModelo.NombreProducto = producto.nombre;
    producto.idModelo["Origen"] = "Tienda";
    producto.idModelo["ImagenProducto"] = producto.ImagenPro;
    producto.idModelo.RutasImagenes = producto.RutaImagen;
    this.setState({
      ProductoSeleccionado: Producto.idModelo,
      VerModalDetalles: true
    });
  }



  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    var ProductosFiltro = [];


    this.setState({
      checked: newChecked,
    }, () => {

      if (value === 0) {
        ProductosFiltro = this.state.ProductosTiendaGeneral;
        this.setState({
          checked: [0],
          ProductosTienda: this.state.ProductosTiendaGeneral
        });
      } else {
        var index = checked.indexOf(0);
        if (index !== -1) {
          newChecked.splice(checked.indexOf(0), 1);

        }


        this.state.ProductosTiendaGeneral.map(m => {
          if (this.state.checked.includes(m.idCategoriaDiseno) == true) {
            ProductosFiltro.push(m)

          }
        })
        this.setState({
          checked: newChecked,
          ProductosTienda: ProductosFiltro
        });
      }

    });

  }

  CambiarFormatoPrecio(precio) {
    var num = '$' + parseFloat((precio), 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
    return num;
  }

  ObtenerPrecioDescuento(Precio, TipoDescuento, Descuento) {
    var descuento = 0;
    if (TipoDescuento == 0) {
      descuento = Precio - (Precio * (Descuento / 100))
    }
    var num = '$' + parseFloat((descuento), 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
    return num;
  }
  MensajePromocion(unidades) {
    if (unidades > 1) {
      return "Aplicable en el carrito en la  compra de " + unidades + " piezas";
    } else {
      return "Aplicable en el carrito";

    }
  }





  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>

        <GridContainer>
          <GridItem lg={2} md={2} sm={12} xs={12}>
            <GridContainer>

              <GridItem lg={12} md={12} sm={12} xs={12}>

                <Accordion

                  active={0}
                  activeColor="primary"
                  collapses={[
                    {
                      title: "Categorías",
                      content:
                        <List>


                          <ListItem >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{ color: this.state.checked.indexOf(0) !== -1 ? '#2883CF' : 'gray' }}
                                  color="default"
                                  tabIndex={-1}
                                  onClick={() => this.handleToggle(0)}
                                  checked={this.state.checked.indexOf(0) !== -1 ? true : false}
                                  checkedIcon={<CamisaIcono />}
                                  icon={<CamisaIcono />}
                                // classes={{ checked: classes.checked, root: classes.checkRoot }} 
                                />
                              }

                              label={"Todo"}
                              style={{ color: this.state.checked.indexOf(0) !== -1 ? '#2883CF' : 'gray', fontSize: '2em' }}

                            />


                          </ListItem>


                          {
                            this.state.CategoriasDisenoCliente.map((categoria, index) =>
                              <ListItem >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{ color: this.state.checked.indexOf(categoria._id) !== -1 ? '#2883CF' : 'gray' }}
                                      color="default"
                                      tabIndex={-1}
                                      onClick={() => this.handleToggle(categoria._id)}
                                      checked={this.state.checked.indexOf(categoria._id) !== -1 ? true : false}
                                      checkedIcon={<CamisaIcono />}
                                      icon={<CamisaIcono />}
                                    // classes={{ checked: classes.checked, root: classes.checkRoot }}
                                    />
                                  }

                                  label={categoria.NombreCategoria}
                                  style={{ color: this.state.checked.indexOf(categoria._id) !== -1 ? '#2883CF' : 'gray' }}
                                />


                              </ListItem>
                            )
                          }
                        </List>
                    },


                  ]}
                />

              </GridItem>

            </GridContainer>


          </GridItem>

          <GridItem lg={10} md={10} sm={12} xs={12}>

            <br />
            <br />

            {/* <div style={{backgroundImage: `url(${Camisa})`}}>
                    
            asdasdasdasdasdadasd<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

                  </div> */}

            {
              this.state.Cargando == true ?
                <Cargando />
                :



                this.state.ProductosTienda.length > 0 ?
                  <GridContainer justify="center">
                    {
                      this.state.ProductosTienda.map(Disenos =>


                        <GridItem md={4} sm={4} xs={6}>

                          {/* <Card profile plain className={classes.card}>
                                    <CardHeader image plain> */}
                          <div style={{ minWidth: "300px", marginRight: '-100px', marginLeft: '-100px' }}
                          // onClick={() => this.ObtenerProductosPorDiseno(Disenos, Disenos.idModelo.idCategoria)}

                          //  onClick={() => this.ObtenerTallas(Disenos)}
                          >

                            <center>
                              <img
                                onClick={() => this.ObtenerProductosPorDiseno(Disenos, Disenos.idModelo.idCategoria)}
                                style={{ width: "250px", height: "250px", objectFit: "cover", objectPosition: "center center", cursor: "hand", cursor: 'pointer' }}
                                src={"data:image/png;base64," + Disenos.ImagenPro} alt=".." />
                              {/* <img src={Camisa} alt=".." /> */}
                            </center>
                          </div>
                          {/* </CardHeader>
                                    <CardBody plain> */}
                          <center>

                            <h4 className={classes.cardTitle}>{Disenos.nombre}</h4>
                            <p className={classes.description}>
                              {/* "descripcion del producto o el artista?" */}
                              <div className={classes.priceContainer}>
                                <span style={{ fontSize: "16px", fontWeight: "2em", color: '#080707' }} > {Disenos.Minimo == Disenos.Maximo ? this.CambiarFormatoPrecio(Disenos.Maximo) : this.CambiarFormatoPrecio(Disenos.Minimo) + " - " + this.CambiarFormatoPrecio(Disenos.Maximo)}</span>
                              </div>

                              {
                                Disenos.DisenoPadre[0].Descuento == true ?
                                  <Tooltip title={this.MensajePromocion(Disenos.DisenoPadre[0].CantidadUnidades)} placement="top" classes={{ tooltip: classes.tooltip }}>
                                    <span style={{ fontSize: "12px", fontWeight: "2em", color: "green", cursor: 'default' }}  >
                                      -
                                      {
                                        Disenos.DisenoPadre[0].TipoDescuento == 0 ?
                                          <span>
                                            {Disenos.DisenoPadre[0].TotalDescuento}%
                                          </span>
                                          :
                                          <span>
                                            {
                                              this.CambiarFormatoPrecio(Disenos.DisenoPadre[0].TotalDescuento)
                                            }
                                          </span>
                                      }
                                      &nbsp;desct.
                                      <HelpOutline style={{ fontSize: '15px' }} />
                                    </span>
                                  </Tooltip>
                                  : null
                              }
                            </p>
                            <Tooltip title="Enviar a fabricar" placement="top" classes={{ tooltip: classes.tooltip }}>
                              <IconButton
                                onClick={() => this.ObtenerProductosPorDiseno(Disenos, Disenos.idModelo.idCategoria)}
                        
                              >
                                <StoreMallDirectory fontSize="small" color="action" />
                              </IconButton>
                              {/* <Button  size='sm' justIcon round color='github'><Edit /></Button> */}
                            </Tooltip>
                            {
                              this.props.TiendaPropia == false ?
                                <Tooltip title="Eliminar diseño" placement="top" classes={{ tooltip: classes.tooltip }}>
                                  <IconButton onClick={() => this.EliminarDisenoTienda(Disenos._id, Disenos.idDiseno)}>
                                    <Delete fontSize="small" color="action" />
                                  </IconButton>
                                  {/* <Button onClick={() => this.EliminarDisenoTienda(Disenos._id, Disenos.idDiseno)} size='sm' justIcon round color='github'><Delete /></Button> */}
                                </Tooltip>
                                : null
                            }


                            <Tooltip title="Agregar a favoritos" placement="top" classes={{ tooltip: classes.tooltip }}>
                              <IconButton
                                onClick={() => this.AgregarFavoritos(Disenos.idDiseno)} disabled={Disenos.Favorito == true ? true : false}
                              >
                                {
                                  Disenos.Favoritos == true ?
                                    <Favorite fontSize="small" color="error" />

                                    :
                                    <Favorite fontSize="small" />

                                }
                              </IconButton>
                              {/* <Button  size='sm' justIcon round color='github'><Edit /></Button> */}
                            </Tooltip>



                          </center>




                          {/* </CardBody> */}
                          {/* <CardFooter plain className={classes.justifyContentBetween}>
                                        <div className={classes.priceContainer}>
                                          <span className={classes.price}>{producto.PrecioPublico}</span>
                                        </div>
                                        <Tooltip
                                          id="tooltip-top"
                                          title="Saved to Wishlist"
                                          placement="left"
                                          classes={{ tooltip: classes.tooltip }}
                                        >
                                          <Button
                                            justIcon
                                            simple
                                            color="rose"
                                            className={classes.pullRight}
                                          >
                                            <Favorite />
                                          </Button>
                                        </Tooltip>
                                      </CardFooter> */}
                          {/* </Card> */}

                        </GridItem>

                        //                         
                      )
                    }




                  </GridContainer>
                  :
                  <GridContainer justify="center">
                    <GridItem lg={10} md={10} sm={10} xs={10}>
                      <h3>Aún no has agregado productos a tu tienda</h3>

                    </GridItem>
                  </GridContainer>
            }



          </GridItem>
        </GridContainer>
        {
          this.state.VerModalDetalles == true ?
            <ModalProducto
              EventoModalTalla={() => this.setState({ VerModalDetalles: false })}
              Tallas={this.state.Tallas}
              CaracteristicasPorTalla={this.state.CaracteristicasPorTalla}
              ProductosTallas={this.state.ProductosTallas}
              Producto={this.state.ProductoSeleccionado}
              VerModal={this.state.VerModalDetalles}

            />
            : null
        }
        


        <ModalCargando Ver={this.state.VerCargando} Cuerpo={this.state.CuerpoCargando} />
      </div>
      
    )
  }
}
export default withStyles(profilePageStyle)(TiendaArtista);


//<GridItem xs={6} sm={4} md={4} className={classes.gridItem}>
//                                 <Card profile plain className={classes.card}>
//                                   <GridContainer>
//                                     <GridItem xs={12} sm={12} md={6}>
//                                       <CardHeader image plain>
//                                           <img width="200" height="150" src={Globales.Url + "Productos/" + Disenos.RutaImagen} alt="..." />
//                                         <div
//                                           className={classes.coloredShadow}
//                                           style={{
//                                             backgroundImage: "url(" + avatar + ")",
//                                             opacity: "1"
//                                           }}
//                                         />
//                                       </CardHeader>
//                                     </GridItem>
//                                     <GridItem xs={12} sm={12} md={6}>
//                                       <CardBody plain>
//                                         <h4 className={classes.cardTitle}>
//                                           {Disenos.nombre}
//                                       </h4>
//                                         <Tooltip disableTouchListener={true} title="Editar diseño" placement="top" classes={{ tooltip: classes.tooltip }}>
//                                           <Button size='sm' justIcon round color='github'><Edit /></Button>
//                                         </Tooltip>
// {/* 
//                                         <Tooltip title="Añadir a mi tienda" placement="top" classes={{ tooltip: classes.tooltip }}>
//                                           <Button  size='sm' justIcon round color='github'><StoreMallDirectory /></Button>
//                                         </Tooltip> */}

//                                         <Tooltip title="Eliminar diseño" placement="top" classes={{ tooltip: classes.tooltip }}>
//                                           <Button  onClick={()=>this.EliminarDisenoTienda(Disenos._id)} size='sm' justIcon round color='github'><Delete /></Button>
//                                         </Tooltip>

//                                       </CardBody>
//                                     </GridItem>
//                                   </GridContainer>
//                                 </Card>
//                               </GridItem>