import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import avatar from "assets/img/faces/avatar.jpg";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";


import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// core components
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import { StoreMallDirectory } from "@material-ui/icons";

import Cookies from "js-cookie";
import Globales from "utils/Globales";
import Enumerable from "linq"
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class ModalAgregarTienda extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            RutaImagen: "",
            checked: [],
            Disenos: [],
            NombreProducto: "",
            Descripcion: "",
            GrupoDisenos: [],
            TallasProducto: [],
            idCategoria:"",
        }
        this.handleChange = this.handleChange.bind(this)
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.eventClick()
        }
    }

    componentDidMount() {
        this.ObtenerTallas();

    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    ObtenerTallas() {
        fetch(Globales.Url + 'Tallas/ObtenerTallas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
        }
        ).then(res => res.json())
            .then(data => {
                if (data.error) {

                } else {
                    this.setState({
                        Tallas: data.data
                    }, () => {
                        this.setState({
                            Disenos: this.props.Disenos,
                            NombreProducto: this.props.Disenos[0].nombre
                        });
                    })
                }
            })
    }





    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false });
                this.setState(x);
            }.bind(this),
            3000
        );
    }

    CancelarPedido() {
        fetch(Globales.Url + 'Carrito/Carrito/CancelarCompra', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }, body: JSON.stringify({ _id: this.props.idPedidoCancelacion })

        }
        ).then(res => res.json())
            .then(data => {
                if (data.error) {

                } else {

                    this.props.EventoModalCancelar(true)

                }
            })

    }


    ObtenerNombreTamano(id) {
        var nombre = "";
        this.state.Tallas.map(m => {
            if (m._id == id) {
                nombre = m.NombreTalla;
            }
        })
        return nombre;
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }



    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }



        this.setState({
            checked: newChecked,
        });





    }

    EnviarMiTienda() {
        this.state.GrupoDisenos = [];
        this.state.checked.map(m => {
            let aux = Enumerable.from(this.state.Disenos).where(function (x) { return x._id == m }).toArray();
            this.state.RutaImagen = aux[0].RutaImagen;
            this.state.idCategoria = aux[0].idCategoriaDiseno;

            if (aux.length > 0) {
                this.state.GrupoDisenos.push(aux[0]._id)
                this.state.TallasProducto.push({ idDiseno: aux[0]._id, Tamano: aux[0].idTalla })
            }
        })
        fetch(Globales.Url + 'TiendaArtistas/TiendaArtistas/AgregarDisenosTienda', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({
                Nombre: this.state.NombreProducto,
                Descripcion: this.state.Descripcion,
                Tallas: this.state.TallasProducto,
                idsDisenos:this.state.GrupoDisenos,
                RutaImagen:this.state.RutaImagen,
                idCliente:JSON.parse(Cookies.get("UsuarioTerco")).id,
                idCategoria:this.state.idCategoria
            })
        }
        ).then(res => res.json())
            .then(data => {
            //console.log("TCL: data", data)
                if (data.error) {
                 


                } else {
                   

                }
            })


    }



    render() {
        const { classes, ...rest } = this.props;




        return (
            <div>
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal + " " + classes.modalSignup
                    }}
                    open={this.props.VerAgregarTienda}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.props.EventoModalTalla}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <Card plain className={classes.modalSignupCard}>
                        <DialogTitle
                            id="signup-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <Button
                                simple
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.EventoModalAgregarTienda()}
                            >
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                            <center>
                                <h3 className={`${classes.cardTitle} ${classes.modalTitle}`}>
                                    Agregar a tienda
                        </h3>
                            </center>

                        </DialogTitle>
                        <DialogContent>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Nombre producto"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            required: true,
                                            onChange: this.handleChange,
                                            value: this.state.NombreProducto,
                                            name: "NombreProducto",
                                            id: "NombreProducto",
                                            type: "text",
                                        }} />

                                </GridItem>

                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Descripción"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            required: true,
                                            onChange: this.handleChange,
                                            value: this.state.Descripcion,
                                            name: "Descripcion",
                                            id: "Descripcion",
                                            type: "text",
                                        }} />

                                </GridItem>
                            </GridContainer>

                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={5}>
                                    <span>Selecciona las tallas correspondientes a tu diseño</span>

                                </GridItem>
                            </GridContainer>
                            <GridContainer justify="center">
                                {
                                    this.state.Disenos.map(Disenos =>
                                        <GridItem xs={6} sm={4} md={4} className={classes.gridItem}>
                                            <Card profile plain className={classes.card}>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CardHeader image plain>
                                                            {/* <img width="200" height="170" src={CamisaImg} alt="..." /> */}

                                                            <img width="200" height="150" src={Globales.Url + "Productos/" + Disenos.RutaImagen} alt="..." />
                                                            <div
                                                                className={classes.coloredShadow}
                                                                style={{
                                                                    backgroundImage: "url(" + avatar + ")",
                                                                    opacity: "1"
                                                                }}
                                                            />
                                                        </CardHeader>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CardBody plain>
                                                            <h4 className={classes.cardTitle}>
                                                                {Disenos.nombre}
                                                            </h4>
                                                            <h4 className={classes.cardTitle}>
                                                                Talla: {this.ObtenerNombreTamano(Disenos.idTalla)}
                                                            </h4>

                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        tabIndex={-1}
                                                                        onClick={() => this.handleToggle(Disenos._id)}
                                                                        checked={this.state.checked.indexOf(Disenos._id) !== -1 ? true : false}
                                                                        checkedIcon={<Check />}
                                                                        icon={<Check />}
                                                                        classes={{ checked: classes.checked, root: classes.checkRoot }} />


                                                                }

                                                                label={this.state.checked.indexOf(Disenos._id) !== -1 ? "Quitar" : "Añadir"}
                                                            />




                                                        </CardBody>
                                                    </GridItem>
                                                </GridContainer>
                                            </Card>
                                        </GridItem>
                                    )
                                }
                            </GridContainer>

                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={12}>
                                    <center>
                                        <Button onClick={() => this.EnviarMiTienda()} round color='success'> Enviar a tienda <StoreMallDirectory /></Button>

                                    </center>


                                </GridItem>
                            </GridContainer>


                        </DialogContent>

                    </Card>
                </Dialog>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
            </div >
        );
    }
}

export default withStyles(modalStyle)(ModalAgregarTienda);