import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class ModalImagenProducto extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            Producto: null,
        }
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.Evento()
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);

    }

    componentWillMount() {
        this.setState({
            Producto: this.props.Producto
        });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render() {
        const { classes } = this.props;
        return (
            <div >
                <Dialog
                    open={this.props.VerModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.props.EventoModalImagen}
                    // aria-labelledby="classic-modal-slide-title"
                    // aria-describedby="classic-modal-slide-description"
                >

                    <DialogTitle
                        id="signup-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.EventoModalImagen()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                    </DialogTitle>
                    <DialogContent>
                        <GridContainer justify="center">
                            <GridItem lg={12} md={12} sm={12} xs={12} >
                                <center>
                                    <img style={{ width: "auto", height: "400px", objectFit: "cover", objectPosition: "center center" }}
                                        src={this.props.Producto.ImagenProducto != null ? "data:image/png;base64," + this.props.Producto.ImagenProducto : null} alt=".." />
                                </center>
                            </GridItem>
                        </GridContainer>
                    </DialogContent>

                </Dialog>
            </div >
        );
    }
}

export default withStyles(modalStyle)(ModalImagenProducto);