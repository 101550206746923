import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";

class CartaProducto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRotate1: "",
      activeRotate2: ""
    };
  }
  componentWillMount(){

      
  }
  componentDidMount() {
    const { classes } = this.props;
    // console.log("carta producto", this.props.Producto )
 
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div >
        <Card>
          <CardHeader color="primary">
            <center>
            {this.props.Producto.NombreProducto}

            </center>
          </CardHeader>
          <CardBody>
            <center>
              {
                this.props.Producto.Origen == "Tienda" ?
                <img style={{width:"250px",height:"250px",objectFit:"cover",objectPosition:"center center"}}
                src={this.props.Producto.ImagenProducto!= null ? "data:image/png;base64," +  this.props.Producto.ImagenProducto : null} alt=".." />  
                :
                <img style={{width:"250px",height:"250px",objectFit:"cover",objectPosition:"center center"}}
                src={this.props.Producto.ImagenProducto!= null ? "data:image/png;base64," +  this.props.Producto.ImagenProducto : null} alt=".."/>
              }

            </center>
          </CardBody>
        </Card>
    
        </div>
     
     
    
    );
  }
}

export default withStyles(styles)(CartaProducto);
