import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "../Generales/Header/Header.jsx";
import HeaderLinks from "../Generales/Header/HeaderLinks.jsx";
// sections of this Page
import Banner from './Secciones/BannerInicio';
import Seccion1 from './Seccion/seccion1';
import Seccion3 from './Seccion/seccion3';
import Seccion6 from './Seccion/seccion6';
import Seccion8 from './Seccion/seccion8';
import SectionProducts from "../SeleccionProducto/Sections/SectionProducts.jsx";
import sectionsPageStyle from "assets/jss/material-kit-pro-react/views/sectionsPageStyle.jsx";

class Principal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overflowXStyle: 'hidden',
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateView);
  }

  easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }
  componentDidUpdate(prevProps, prevState) {
    document.body.style.overflowX = this.state.overflowXStyle;
  }
  updateView() {
    var contentSections = document.getElementsByClassName("cd-section");
    var navigationItems = document
      .getElementById("cd-vertical-nav")
      .getElementsByTagName("a");
  
    for (let i = 0; i < contentSections.length; i++) {
      var activeSection =
        parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
      if (
        contentSections[i].offsetTop - window.innerHeight / 2 <
        window.pageYOffset &&
        contentSections[i].offsetTop +
        contentSections[i].scrollHeight -
        window.innerHeight / 2 >
        window.pageYOffset
      ) {
        navigationItems[activeSection].classList.add("is-selected");
      } else {
        navigationItems[activeSection].classList.remove("is-selected");
      }
    }
  }

  smoothScroll(target) {
    var targetScroll = document.getElementById(target);
    this.scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  }

  scrollGo(element, to, duration) {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = this.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    }.bind(this);
    animateScroll();
  }

  render() {
    const { classes } = this.props;
    document.body.style.overflowX = this.state.overflowXStyle;
    return (
      <div>
        <Header
          color="dark"
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
        />
        <div >
          <Banner id="banner" />
          <SectionProducts
            id="SectionProducts"
            setOverflowXStyle={overflowXStyle => {
              this.setState({ overflowXStyle });
            }}
                      />
          <Seccion1 id="seccion1" />          
          <Seccion3 id="seccion3" />
          <Seccion6 id="seccion6" />
          <Seccion8 id="seccion8" />
        </div>
      </div>
    );
  }
}

export default withStyles(sectionsPageStyle)(Principal);
