import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Save, Clear } from '@material-ui/icons';
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Globales from "utils/Globales";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class ModalCancelarPedido extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
  
    }
  
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick()
    }
  }

  componentDidMount() {
    //console.log(this.props)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  
 

 
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      3000
    );
  }

  CancelarPedido() {
    fetch(Globales.Url + 'Carrito/Carrito/CancelarCompra', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      },body: JSON.stringify({_id:this.props.idPedidoCancelacion})
      
    }
    ).then(res => res.json())
      .then(data => {
      //console.log("TCL: canelacion", data)
        if (data.error) {

        } else {

          this.props.EventoModalCancelar(true)

        }
      })

  }



 
  
 

  render() {
    const { classes, ...rest } = this.props;

  


    return (
      <div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.props.VerModalCancelar}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.props.EventoModalCancelar(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => this.props.EventoModalCancelar(false)}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Cancelar pedido</h4>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
              <span>¿Estas seguro que deseas cancelar tu pedido?</span>
              <br/>
              {/* <span>Tienes hasta el {this.props.FechaParaCancelar} para cancelar o editar tu pedido.</span> */}
      
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() =>this.CancelarPedido()} size="sm" color="success" className="pull-right" round ><Save />&nbsp;ACEPTAR</Button>
            <Button size="sm" color="danger" className="pull-right" round onClick={() => this.props.EventoModalCancelar(false)}><Clear />&nbsp;CANCELAR</Button>
          </DialogActions>
        </Dialog>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div >
    );
  }
}

export default withStyles(modalStyle)(ModalCancelarPedido);