import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

// Definir estilos para el botón
const StyledButton = styled.button`
  /* Agrega tus estilos personalizados aquí */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: inherit;
  display: flex;
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  text-decoration: none;
  background: none;
  font-size: 12px;
`;

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <StyledButton
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      CERRAR SESIÓN
    </StyledButton>
  );
};

export default LogoutButton;
