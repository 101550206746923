import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Footer from "components/Footer/Footer.jsx";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.jsx";


class PieDePagina extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.productPage}>
        <Footer
          // theme="dark"
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="#"
                      className={classes.block}
                    >
                      Términos y Condiciones
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="#"
                      className={classes.block}
                    >
                      Politica de privacidad
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="//blog.creative-tim.com/"
                      className={classes.block}
                    >
                      Blog
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} {" "}
                <a
                  href="#"
                  className={classes.aClasses}
                >
                  Terco SA de CV
                </a>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}
//export default (PieDePagina);
export default withStyles(productStyle)(PieDePagina);
