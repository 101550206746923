import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";


import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import pillsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/pillsStyle.jsx";
import LogoOxxo from "../../assets/img/FormasPago/oxxo_pay.png"


class SectionPills extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this)
        this.ValidarDatos = this.ValidarDatos.bind(this)
        this.state = {
            FormaDePago: 0,
            NumeroTarjeta: "",
            NombreTitular: "",
            CodigoSeguridad: "",
            FechaExpiracion: "",
            Mes: "",
            Año: "",

        
            NombreCliente: "",
            CorreoCliente: "",
            NombreCompletoTransferencia: "",
            CorreoElectronicoTransferencia: "",
            error: {
                NumeroTarjeta: false,
                NombreTitular: false,
                CodigoSeguridad: false,
                FechaExpiracion: false,
                Mes: false,
                Año: false,
                NombreCliente: false,
                CorreoCliente: false
            }

        };

        /**variables globales para totales */
        global.Total = 0;
        global.Subtotal = 0;
        global.IVA = 0;
        global.Descuento = 0;
        global.Envio = 0;
        global.TotalProductos = 0;
        global.Mes = "";
        global.Año = "";
    }


    RetrosesoFunction(event) {
        if (event.keyCode === 8) {
            return true
        } else {
            return false
        }
    }

    componentDidMount() {
        document.addEventListener("FechaVencimiento", this.handleChange, false);

    }

    componentWillUnmount() {
        document.removeEventListener("FechaVencimiento", this.handleChange, false);
    }

    CambiarTabPagos(e) {
        this.setState({ FormaDePago: e });
    }

    handleChange(e) {

        const { name, value } = e.target;
        if(name== "NombreCliente" || name =="CorreoCliente"){


            
            if(value.length < 50){
                
                this.setState({ [name]: name=="NombreCliente" ? value.replace(/[^a-zA-Z\sñÑ]+/, '') : value });

            }

        }

        if (name == "NumeroTarjeta" || name == "CodigoSeguridad" ) {
            if (value != "") {
                if (!/^[0-9]+$/.test(value)) {
                    // alert("Please only enter numeric characters only for your Age! (Allowed input:0-9)")
                } else {
                    if (name == "NumeroTarjeta") {
                        {
                            if (value.length < 17) {
                                this.setState({ [name]: value });

                            }
                        }

                    }
                    if (name == "CodigoSeguridad") {
                        {
                            if (value.length < 4) {
                                this.setState({ [name]: value });

                            }
                        }

                    }
                }
            } else {
                this.setState({ [name]: value });
            }

        }
        if (name == "Mes") {
            if (value == "") {
                this.setState({ [name]: value });

            } else {
                if (!/^[0-9]+$/.test(value)) {

                } else {
                    if (parseInt(value) < 13) {
                        this.setState({ [name]: value });
                    }
                }
            }


        }
        if (name == "Año") {
            if (value == "") {
                this.setState({ [name]: value });
            } else {
                if (!/^[0-9]+$/.test(value)) {
                   // this.setState({ [name]: value });

                } else {
                    if (parseInt(value) > 0 && parseInt(value) < 100) {
                        this.setState({ [name]: value });
                    }
                }
            }


        }
        if (name == "NombreTitular") {
            
            if(value.length < 50){
                this.setState({ [name]: value.replace(/[^a-zA-Z\sñÑ]+/, '') });

            }
        }
        if(name == "NombreCompletoTransferencia"){
            if(value.length < 50){
                
                this.setState({ [name]: value.replace(/[^a-zA-Z\sñÑ]+/, '') });

            }
        }
        if(name == "CorreoElectronicoTransferencia"){
            if(value.length < 50){
                this.setState({ [name]:  value });
            } 
        }

    }

    ValidarDatos(FormaPago, Campo) {
        //console.log("TCL: FormaPago, Campo", FormaPago, Campo)
        var err = this.state.error;
        var EventoTab = false;
        //  var valido = true;
        /**TARJETA */
        if (FormaPago == 0) {

            if (Campo == 1) {
                err.NumeroTarjeta = false;
                if (this.state.NumeroTarjeta == "" || this.state.NumeroTarjeta.length < 16) {
                    err.NumeroTarjeta = true;
                    //        valido=false;
                }
            }
            if (Campo == 2) {
                err.NombreTitular = false;
                if (this.state.NombreTitular == "") {
                    err.NombreTitular = true;
                    //valido=false;

                }
            }
            if (Campo == 3) {

                err.Mes = false;
                if (this.state.Mes.length < 2) {
                    err.Mes = true;
                    //      valido=false;

                }

            }

            if (Campo == 4) {

                err.Año = false;
                if (this.state.Año.length < 2) {
                    err.Año = true;
                    //    valido=false;

                }
            }

            if (Campo == 5) {
                err.CodigoSeguridad = false;
                EventoTab = true;

                if (this.state.CodigoSeguridad.length < 3) {
                    err.CodigoSeguridad = true;
                    //  valido=false;

                }
            }


            this.setState({
                error: err
            });
            var datos = {
                NumeroTarjeta: this.state.NumeroTarjeta,
                NombreTitular: this.state.NombreTitular,
                Mes: this.state.Mes,
                Año: this.state.Año,
                Codigo: this.state.CodigoSeguridad,
                FormaDePago: this.state.FormaDePago
            }
            // //console.log("erroes", this.state.error)
            this.props.RecibirDatosCompra(datos, EventoTab, false)

            if (this.state.error.NumeroTarjeta == false && this.state.error.NombreTitular == false
                && this.state.error.Mes == false && this.state.error.Año == false && this.state.error.CodigoSeguridad == false)

                this.props.RecibirDatosCompra(datos, EventoTab, true)
        }
        if (FormaPago == 1){
            var datos = {
                NombreCliente: this.state.NombreCliente,
                CorreoCliente: this.state.CorreoCliente,
                FormaDePago: this.state.FormaDePago
            }
            if(this.state.NombreCliente != "" && this.state.CorreoCliente!=""){
                this.props.RecibirDatosCompra(datos, EventoTab, true)

            }else{
                this.props.RecibirDatosCompra(datos, EventoTab, false)

            }

        }
        if(FormaPago == 2){
            var datos = {
                NombreCompletoTransferencia: this.state.NombreCompletoTransferencia,
                CorreoElectronicoTransferencia: this.state.CorreoElectronicoTransferencia,
                FormaDePago: this.state.FormaDePago
            }
            if(this.state.NombreCompletoTransferencia != "" && this.state.CorreoElectronicoTransferencia != ""){
                this.props.RecibirDatosCompra(datos,EventoTab,true)
            }else{
                this.props.RecibirDatosCompra(datos, EventoTab, false)
            }
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div >
                <div className={classes.container}>
                    <div id="navigation-pills">

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Card >

                                    <CardBody>

                                        <GridContainer>

                                            <GridItem>
                                                <h4>Forma de pago</h4>
                                            </GridItem>

                                            <GridItem>
                                                <hr />
                                            </GridItem>
                                        </GridContainer>

                                        <NavPills

                                            color="primary"
                                            tabs={[
                                                {
                                                    tabButton: "Pago en línea",
                                                    tabContent: (
                                                        <span onClick={() => this.CambiarTabPagos(0)}>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={4} md={4} lg={6}>
                                                                    <CustomInput
                                                                        labelText="Número de tarjeta"
                                                                        id="success"
                                                                        formControlProps={{
                                                                            fullWidth: true,

                                                                        }}
                                                                        inputProps={{
                                                                            required: true,
                                                                            onChange: this.handleChange,
                                                                            onBlur: () => this.ValidarDatos(0, 1),
                                                                            value: this.state.NumeroTarjeta,
                                                                            name: "NumeroTarjeta",
                                                                            id: "NumeroTarjeta",
                                                                            type: "text",
                                                                            error: this.state.error.NumeroTarjeta,
                                                                            onKeyDown: this.handleChange
                                                                        }}

                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={4} md={4} lg={6}>
                                                                    <CustomInput
                                                                        labelText="Nombre del titular"
                                                                        id="success"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            maxlength:"10",
                                                                            required: true,
                                                                            onChange: this.handleChange,
                                                                            onBlur: () => this.ValidarDatos(0, 2),
                                                                            value: this.state.NombreTitular,
                                                                            name: "NombreTitular",
                                                                            id: "NombreTitular",
                                                                            type: "text",
                                                                            error: this.state.error.NombreTitular,

                                                                        }}
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={4} sm={4} md={2} lg={2}>
                                                                    <CustomInput
                                                                        labelText="Mes"
                                                                        id="success"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            required: true,
                                                                            onChange: this.handleChange,
                                                                            onBlur: () => this.ValidarDatos(0, 3),
                                                                            value: this.state.Mes,
                                                                            name: "Mes",
                                                                            id: "Mes",
                                                                            type: "text",
                                                                            error: this.state.error.Mes
                                                                        }}
                                                                    />

                                                                </GridItem>
                                                                <GridItem xs={4} sm={4} md={2} lg={2}>
                                                                    <CustomInput
                                                                        labelText="Año"
                                                                        id="success"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            required: true,
                                                                            onChange: this.handleChange,
                                                                            onBlur: () => this.ValidarDatos(0, 4),
                                                                            value: this.state.Año,
                                                                            name: "Año",
                                                                            id: "Año",
                                                                            type: "text",
                                                                            error: this.state.error.Año
                                                                        }}
                                                                    />

                                                                </GridItem>
                                                                <GridItem xs={4} sm={4} md={3} lg={3}>
                                                                    <CustomInput
                                                                        labelText="CVC"
                                                                        id="success"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            required: true,
                                                                            onChange: this.handleChange,
                                                                            onBlur: () => this.ValidarDatos(0, 5),
                                                                            value: this.state.CodigoSeguridad,
                                                                            name: "CodigoSeguridad",
                                                                            id: "CodigoSeguridad",
                                                                            type: "password",
                                                                            error: this.state.error.CodigoSeguridad
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                                {/* <GridItem  xs={12} sm={4} md={4} lg={4}>
                                                                     <div style={{marginTop:'20px'}}>
                                                                    <img width="150px" src={LogoOxxo}/>
                                                                    </div> 
                                                                    <br/> */}
                                                                    {/* <center>
                                                                    <Button className="mt-5" id="BtnComprar" onClick={() => { this.ValidarCompra() }} size="md" color="github">COMPRAR</Button>
                                                                    </center> */}

                                                                {/* </GridItem> */}

                                                            </GridContainer>

                                                        </span>
                                                    )
                                                },
                                                {
                                                    tabButton: "OXXO",
                                                    tabContent: (
                                                        <span onClick={() => this.CambiarTabPagos(1)}>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={4} md={4} lg={6}>
                                                                    <CustomInput
                                                                        labelText="Nombre"
                                                                        id="success"
                                                                        formControlProps={{
                                                                            fullWidth: true,

                                                                        }}
                                                                        inputProps={{
                                                                            required: true,
                                                                            onChange: this.handleChange,
                                                                            onBlur: () => this.ValidarDatos(1, 1),
                                                                            value: this.state.NombreCliente,
                                                                            name: "NombreCliente",
                                                                            id: "NombreCliente",
                                                                            type: "text",
                                                                            error: this.state.error.NombreCliente,
                                                                            onKeyDown: this.handleChange
                                                                        }}

                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={4} md={4} lg={6}>
                                                                    <CustomInput
                                                                        labelText="Correo"
                                                                        id="success"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            required: true,
                                                                            onChange: this.handleChange,
                                                                            onBlur: () => this.ValidarDatos(1, 2),
                                                                            value: this.state.CorreoCliente,
                                                                            name: "CorreoCliente",
                                                                            id: "CorreoCliente",
                                                                            type: "text",
                                                                            error: this.state.error.CorreoCliente,

                                                                        }}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>

                                                            <GridContainer justify="right">


                                                                <GridItem xs={3} sm={3} md={3} lg={3}>
                                                                    <div style={{ marginTop: '20px' }}>
                                                                        <img width="150px" src={LogoOxxo} />
                                                                    </div>
                                                                </GridItem>

                                                            </GridContainer>




                                                        </span>
                                                    )
                                                },
                                                {
                                                    tabButton: "Transferencia",
                                                    tabContent:(
                                                        <span onClick={() => this.CambiarTabPagos(2)}>
                                                            <GridContainer>
                                                                <GridItem>
                                                                <CustomInput
                                                                        labelText="Nombre completo"
                                                                        id="success"
                                                                        formControlProps={{
                                                                            fullWidth: true,

                                                                        }}
                                                                        inputProps={{
                                                                            required: true,
                                                                            onChange: this.handleChange,
                                                                            onBlur: () => this.ValidarDatos(2, 1),
                                                                            value: this.state.NombreCompletoTransferencia,
                                                                            name: "NombreCompletoTransferencia",
                                                                            id: "NombreCompletoTransferencia",
                                                                            type: "text",
                                                                            error: this.state.error.NombreClienteTransferencia,
                                                                            onKeyDown: this.handleChange
                                                                        }}

                                                                    />
                                                                </GridItem>
                                                                <GridItem>
                                                                <CustomInput
                                                                        labelText="Correo electrónico"
                                                                        id="success"
                                                                        formControlProps={{
                                                                            fullWidth: true,

                                                                        }}
                                                                        inputProps={{
                                                                            required: true,
                                                                            onChange: this.handleChange,
                                                                            onBlur: () => this.ValidarDatos(2, 2),
                                                                            value: this.state.CorreoElectronicoTransferencia,
                                                                            name: "CorreoElectronicoTransferencia",
                                                                            id: "CorreoElectronicoTransferencia",
                                                                            type: "text",
                                                                            error: this.state.error.CorreoElectronicoTransferencia,
                                                                            onKeyDown: this.handleChange
                                                                        }}

                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                        </span>
                                                    )
                                                }
                                                // {
                                                //     tabButton: "PayPal",
                                                //     tabContent: (
                                                //         <span onClick={() =>this.CambiarTabPagos(2)}>
                                                //         </span>
                                                //     )
                                                // }
                                            ]}
                                        />
                                    </CardBody>
                              
                                    <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                </Card>

                            </GridItem>

                          
                        </GridContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(pillsStyle)(SectionPills);
