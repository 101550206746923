import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// sections for this page
import Header from "../Generales/Header/Header.jsx";
import HeaderLinks from "../Generales/Header/HeaderLinks.jsx";
import SectionProducts from "./Sections/SectionProducts.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.jsx";


class EcommercePage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
          color="dark"
        />
        {/* <Parallax
          image={require("assets/img/examples/clark-street-merc.jpg")}
          filter="dark"
          style={{height: "400px"}}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={10}
                sm={10}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <div className={classes.brand}>
                  <h1 className={classes.title}>Galeria de Productos</h1>
                
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax> */}

        <div className={classNames(classes.main)}>
          {/* <SectionLatestOffers /> */}
          <SectionProducts />
        </div>
        {/* <SectionBlog /> */}
      </div>
    );
  }
}

export default withStyles(styles)(EcommercePage);
