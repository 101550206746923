import Globales from "utils/Globales";

//Función que realiza la petición a la api del envio de mail
export function EnviarMail(to, subject, contenido) {
	fetch(Globales.Url + 'EnvioMail', {
		method: 'POST',
		headers: {
			Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
		}
		, body: JSON.stringify({ to: to, subject: subject, contenido: contenido })
	}
	).then(res => res.json())
	.then(data => {
    //console.log("TCL: EnviarMail -> data", data)
	});
	
}

//Función que realiza el contenido del mail
export function ContenidoMail(Total, Referencia, Tipo, Domicilio, DatosPago) {
	//console.log("TCL: ContenidoMail -> Referencia", Referencia)
	var LogoNookCorreo ="";
	var oxxopay ="src = " +Globales.Url + "oxxopay_brand.png"
	var spei ="src = " +Globales.Url + "spei_brand.png"
	//console.log("logo spei")  
	//console.log(spei)  
	var mensaje = "";
	if (Tipo == 0) {
		var Total2 = '$' + parseFloat(Total, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
	
		if(DatosPago != null){
			var TipoTarjeta = DatosPago.type == "debit" ? "débito " : "crédito ";
			var informacionPago ="Cargo a " + DatosPago.brand +" "+ TipoTarjeta + "terminada en " + DatosPago.last4 ;
		}else{
			var informacionPago ="Compra realizada con saldo";
		}
	
        //console.log("TCL: ContenidoMail -> informacionPago", informacionPago)
		//Creando el contenido del mail
		mensaje = '<!DOCTYPE html>' +
			'<html>' +
			'<head>' +
			'		<style>' +
			'* 	 { margin: 0;padding: 0; }' +
			'body { font-size: 14px; }' +


			'h3 {margin-bottom: 10px;font-size: 15px;font-weight: 600;text-transform: uppercase;}' +

			'.opps {width: 496px;border-radius: 4px;box-sizing: border-box;padding: 0 45px;margin: 40px auto;overflow: hidden;border: 1px solid #b0afb5;font-family: sans-serif;color: black;}' +

			'.opps-reminder {position: relative;top: -1px;padding: 9px 0 10px;font-size: 11px;text-transform: uppercase;text-align: center;color: #ffffff;background: black;}' +

			'.opps-info {margin-top: 2px;position: relative;}' +

			'.opps-info:after {visibility: hidden;display: block;font-size: 0;content: " ";clear: both;height: 0;}' +

			'.opps-brand {width: 100%;height:95px}' +

			'.opps-brand img {max-width: 150px}' +

			'.opps-ammount {width: 55%;}' +

			'.opps-ammount h2 {font-size: 36px;color: #000000;line-height: 24px;margin-bottom: 15px;}' +

			'.opps-ammount h2 sup {font-size: 16px;position: relative;top: -2px}' +

			'.opps-ammount p {font-size: 10px;line-height: 14px;}' +

			'.opps-reference {margin-top: 14px; }' +

			'h1 {font-size: 27px;color: #000000;text-align: center;margin-top: -1px;padding: 6px 0 7px;border: 1px solid #b0afb5;border-radius: 4px;background: #f8f9fa;}' +

			'.opps-instructions {margin: 32px -45px 0;padding: 32px 45px 45px;border-top: 1px solid #b0afb5;background: #f8f9fa;}' +
			'ol {margin: 17px 0 0 16px;}' +

			'li + li {margin-top: 10px;color: #000000;}' +

			'a {color: #1155cc;}' +

			'.opps-footnote {margin-top: 22px;padding: 22px 20 24px;color: #108f30;text-align: center;border: 1px solid #108f30;border-radius: 4px;background: #ffffff;}' +
			'	</style>' +

			'	<link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700" rel="stylesheet">' +
			'	</head>' +
			'	<body>' +
			'	<div class="opps">' +
			'	<div class="opps-header">' +
			'	<div style="background-color: black">' +
			'	<div class="opps-brand"><center><img  src='+ Globales.Url+'LogoNookCorreo.png'+' alt="Logo Nook"></center></div>' +
			'	</div>' +
			'<div>'+
			'<br/><center><img width=100 src="http://www.imagenesanimadas.net/Arquitectura/Industrias/industria-01.gif"></center></div>'+
			'<div> <center> <span style="font-size:20px;">Tu pedido se ha enviado a fabricar</span> </center><br/> </div>'+
		
			'	<div style="border-bottom: 1px solid #b0afb5" >' +
			'<span  style="font-size:20px;" >Resumen de tu compra</span>' +
			'</div>' +
			'	<div >' +
			'<span  style="font-size:14px" >Dirección de entrega<br/>' +
			 Domicilio.Calle+ ", "+ Domicilio.Colonia + '<br/>' +
			 Domicilio.Municipio+ ", "+ Domicilio.Estado + '<br/>' +
			'Pago('+Total2+')<br/>'  +informacionPago +   '</span>' +


			'</div>' +
			'<div><br/> <center> <span style="font-size:20px;">Gracias por tu compra</span> </center> <br/><br/><br/><br/></div>'+
			// '	<div class="opps-reference">' +
			// '	<h3>Referencia</h3>' +
			// '	<h1>'+Referencia+'</h1> <br/>' +
			// '<p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>' +
			// '</div>' +
			'	</div>' +
			// '	<div class="opps-instructions">' +
			// '<h3>Instrucciones</h3>' +
			// '	<ol>' +
			// '	<li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank">Encuéntrala aquí</a>.</li>' +
			// '	<li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>' +
			// '	<li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>' +
			// '	<li>Realiza el pago correspondiente con dinero en efectivo.</li>' +
			// '	<li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>' +
			// '	<div class="opps-footnote">Al completar estos pasos recibirás un correo de <strong>Nombre del negocio</strong> confirmando tu pago.</div>' +
			// '	</div>' +
			'</div>	' +
			'</body>' +
			'</html>';

	}else if(Tipo == 2){
				//Creando el contenido del mail
				mensaje = `<html>
				<head>
				<style>
					* 	 { margin: 0;padding: 0; }
					body { font-size: 14px; }
					h3 {
						margin-bottom: 10px;
						font-size: 15px;
						font-weight: 600;
						text-transform: uppercase;
					}

					.ps {
						width: 496px; 
						border-radius: 4px;
						box-sizing: border-box;
						padding: 0 45px;
						margin: 40px auto;
						overflow: hidden;
						border: 1px solid #b0afb5;
						font-family: 'Open Sans', sans-serif;
						color: #4f5365;
					}

					.ps-reminder {
						position: relative;
						top: -1px;
						padding: 9px 0 10px;
						font-size: 11px;
						text-transform: uppercase;
						text-align: center;
						color: #ffffff;
						background: #000000;
					}

					.ps-info {
						margin-top: 26px;
						position: relative;
					}

					.ps-info:after {
						visibility: hidden;
						display: block;
						font-size: 0;
						content: " ";
						clear: both;
						height: 0;
					}

					.ps-brand {
						width: 45%;
						float: left;
					}

					.ps-brand img {
						max-width: 150px;
						margin-top: 2px;
					}

					.ps-amount {
						width: 55%;
						float: right;
					}

					.ps-amount h2 {
						font-size: 36px;
						color: #000000;
						line-height: 24px;
						margin-bottom: 15px;
					}

					.ps-amount h2 sup {
						font-size: 16px;
						position: relative;
						top: -2px
					}

					.ps-amount p {
						font-size: 10px;
						line-height: 14px;
					}

					.ps-reference {
						margin-top: 14px;
					}

					h1 {
						font-size: 27px;
						color: #000000;
						text-align: center;
						margin-top: -1px;
						padding: 6px 0 7px;
						border: 1px solid #b0afb5;
						border-radius: 4px;
						background: #f8f9fa;
					}

					.ps-instructions {
						margin: 32px -45px 0;
						padding: 32px 45px 45px;
						border-top: 1px solid #b0afb5;
						background: #f8f9fa;
					}

					ol {
						margin: 17px 0 0 16px;
					}

					li + li {
						margin-top: 10px;
						color: #000000;
					}

					a {
						color: #1475ce; 
					}

					.ps-footnote {
						margin-top: 22px;
						padding: 22px 20 24px;
						color: #108f30;
						text-align: center;
						border: 1px solid #108f30;
						border-radius: 4px;
						background: #ffffff;
					}
				</style>
					<link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700" rel="stylesheet">
				</head>
				<body>
					<div class="ps">
						<div class="ps-header">
							<div class="ps-reminder">Ficha digital. No es necesario imprimir.</div>
							<div class="ps-info">
								<div class="ps-brand"><img src='${Globales.Url}oxxopay_brand.png' alt="Banorte"></div>
								<div class="ps-amount">
									<h3>Monto a pagar</h3>
									<h2>$ ${Total} <sup>MXN</sup></h2>
									<p>Utiliza exactamente esta cantidad al realizar el pago.</p>
								</div>
							</div>
							<div class="ps-reference">
								<h3>CLABE</h3>
								<h1>${DatosPago.clabe}</h1>
							</div>
						</div>
						<div class="ps-instructions">
							<h3>Instrucciones</h3>
							<ol>
								<li>Accede a tu banca en línea.</li>
								<li>Da de alta la CLABE en esta ficha. <strong>El banco deberá de ser ${DatosPago.bank}</strong>.</li>
								<li>Realiza la transferencia correspondiente por la cantidad exacta en esta ficha, <strong>de lo contrario se rechazará el cargo</strong>.</li>
								<li>Al confirmar tu pago, el portal de tu banco generará un comprobante digital. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
							</ol>
							<div class="ps-footnote">Al completar estos pasos recibirás un correo de <strong>Nook</strong> confirmando tu pago.</div>
						</div>
					</div>	
				</body>
			</html>`;
	} 
	else {
		//Creando el contenido del mail
		mensaje = '<!DOCTYPE html>' +
			'<html>' +
			'<head>' +
			'		<style>' +
			'* 	 { margin: 0;padding: 0; }' +
			'body { font-size: 14px; }' +


			'h3 {margin-bottom: 10px;font-size: 15px;font-weight: 600;text-transform: uppercase;}' +

			'.opps {width: 496px;border-radius: 4px;box-sizing: border-box;padding: 0 45px;margin: 40px auto;overflow: hidden;border: 1px solid #b0afb5;font-family: "Open Sans", sans-serif;color: #4f5365;}' +

			'.opps-reminder {position: relative;top: -1px;padding: 9px 0 10px;font-size: 11px;text-transform: uppercase;text-align: center;color: #ffffff;background: #000000;}' +

			'.opps-info {margin-top: 26px;position: relative;}' +

			'.opps-info:after {visibility: hidden;display: block;font-size: 0;content: " ";clear: both;height: 0;}' +

			'.opps-brand {width: 45%;float: left;}' +

			'.opps-brand img {max-width: 150px;margin-top: 2px;}' +

			'.opps-ammount {width: 55%;float: right;}' +

			'.opps-ammount h2 {font-size: 36px;color: #000000;line-height: 24px;margin-bottom: 15px;}' +

			'.opps-ammount h2 sup {font-size: 16px;position: relative;top: -2px}' +

			'.opps-ammount p {font-size: 10px;line-height: 14px;}' +

			'.opps-reference {margin-top: 14px; }' +

			'h1 {font-size: 27px;color: #000000;text-align: center;margin-top: -1px;padding: 6px 0 7px;border: 1px solid #b0afb5;border-radius: 4px;background: #f8f9fa;}' +

			'.opps-instructions {margin: 32px -45px 0;padding: 32px 45px 45px;border-top: 1px solid #b0afb5;background: #f8f9fa;}' +
			'ol {margin: 17px 0 0 16px;}' +

			'li + li {margin-top: 10px;color: #000000;}' +

			'a {color: #1155cc;}' +

			'.opps-footnote {margin-top: 22px;padding: 22px 20 24px;color: #108f30;text-align: center;border: 1px solid #108f30;border-radius: 4px;background: #ffffff;}' +
			'	</style>' +

			'	<link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700" rel="stylesheet">' +
			'	</head>' +
			'	<body>' +
			'	<div class="opps">' +
			'	<div class="opps-header">' +
			'	<div class="opps-reminder"><br/><center><img width="100px"  src='+ Globales.Url+'LogoNookCorreo.png'+' alt="Logo Nook"></center>Ficha digital. No es necesario imprimir.</div>' +
			'	<div class="opps-info">' +
			'	<div class="opps-brand"><img src=' + Globales.Url+ 'oxxopay_brand.png'+ ' alt="OXXOPay"></div>' +
			'	<div class="opps-ammount">' +
			'	<h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Monto a pagar</h3>' +
			'	<h2>&nbsp;&nbsp;&nbsp;&nbsp;$' + Total + ' <sup>MXN</sup></h2>' +
			'</div>' +
			'	</div>' +
			'	<div class="opps-reference">' +
			'	<h3>Referencia</h3>' +
			'	<h1>' + Referencia + '</h1> <br/>' +
			'<p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>' +
			'</div>' +
			'	</div>' +
			'	<div class="opps-instructions">' +
			'<h3>Instrucciones</h3>' +
			'	<ol>' +
			'	<li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank">Encuéntrala aquí</a>.</li>' +
			'	<li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>' +
			'	<li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>' +
			'	<li>Realiza el pago correspondiente con dinero en efectivo.</li>' +
			'	<li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>' +
			'	<div class="opps-footnote">Al completar estos pasos recibirás un correo de <strong>Nombre del negocio</strong> confirmando tu pago.</div>' +
			'	</div>' +
			'</div>	' +
			'</body>' +
			'</html>';
	}



	//Regresando el contenido del mail
	return mensaje
}


// var contenido = "<!DOCTYPE html>\n"
// 	contenido += "<html>\n"
// 	contenido += "<head>\n"
// 	contenido += "<style>\n"
// 	contenido += "#customers {\n"
// 	contenido += "font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;\n"
// 	contenido += "border-collapse: collapse;\n"
// 	contenido += "width: 50%;\n"
// 	contenido += "}\n"
// 	contenido += "#customers td, #customers th {\n"
// 	contenido += "border: 1px solid #ddd;\n"
// 	contenido += "padding: 8px;\n"
// 	contenido += "}\n"
// 	contenido += "#customers tr:nth-child(even){background-color: #777575;}\n"
// 	contenido += "#customers tr:hover {background-color: #ddd;}\n"
// 	contenido += "#customers td{"
// 	contenido += "width:50%;"
// 	contenido += "}"
// 	contenido += "#customers th {\n"
// 	contenido += "width:50%;"
// 	contenido += "padding-top: 10px;\n"
// 	contenido += "padding-bottom: 10px;\n"
// 	contenido += "text-align: left;\n"
// 	contenido += "background-color: #777575;\n"
// 	contenido += "color: white;\n"
// 	contenido += "}\n"
// 	contenido += "</style>\n"
// 	contenido += "</head>\n"
// 	contenido += "<body>\n"
// 	contenido += "<h2>Existe un producto que requiere revisión. Porcentaje de utilidad menor al configurado en el sistema!</h2>\n"
// 	contenido += "<table id='customers'>\n"
// 	contenido += "<tr>\n"
// 	contenido += "<th>Nombre del producto:</th>\n"
// 	contenido += "<td>" + Producto.NombreProducto + "</td>\n"
// 	contenido += "</tr>\n"
// 	contenido += "<tr>\n"
// 	contenido += "<th>Usuario que realizó el registro:</th>\n"
// 	contenido += "<td>" + NombreUsuario + "</td>\n"
// 	contenido += "</tr>\n"
// 	contenido += "</table>\n"
// 	contenido +="<table id='customers'>\n"
// 	contenido +="<thead>\n"
// 	contenido +="<tr>\n"
// 	contenido += "<td> Nombre grupo </td>\n"	
// 	contenido += "<td> precio </td>\n"	
// 	contenido +="</tr>\n"
// 	contenido +="</thead>\n"
// 	contenido +="<tbody>\n"
// 	contenido +="<tr>\n"
// 	contenido += "<td> grupo 1 </td>\n"	
// 	contenido += "<td> $100 </td>\n"	
// 	contenido +="</tr>\n"
// 	contenido +="</tbody>\n"
// 	contenido +="</table>\n"
// 	contenido += "</body>\n"
// 	contenido += "</html>\n"

	// contenido += "<tr>\n"
	// contenido += "<th>Cantidad Material:</th>\n"
	// contenido += "<td>" + Producto.materialesAgregados.length + "</td>\n"
	// contenido += "</tr>\n"
	// contenido += "<tr>\n"
	// contenido += "<th>Costo Material:</th>\n"
	// contenido += "<td>" + "$" + Producto.CostoMaterial + "</td>\n"
	// contenido += "</tr>\n"
	// contenido += "<tr>\n"
	// contenido += "<th>Costo Maquila por objeto:</th>\n"
	// contenido += "<td>" + "$" + Producto.CostoMaquila + "</td>\n"
	// contenido += "</tr>\n"
	// contenido += "<tr>\n"
	// contenido += "<th>Precio Público:</th>\n"
	// contenido += "<td>" + "$" + Producto.PrecioPublico + "</td>\n"
	// contenido += "</tr>\n"
	// contenido += "<tr>\n"
	// contenido += "<th>IVA:</th>\n"
	// contenido += "<td>" + Iva + "</td>\n"
	// contenido += "</tr>\n"
	// contenido += "<tr>\n"
	// contenido += "<th>Porcentaje Utilidad:</th>\n"
	// contenido += "<td>" + Producto.PorcentajeUtilidad + "%" + "</td>\n"
	// contenido += "</tr>\n"