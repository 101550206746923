import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import {FulfillingBouncingCircleSpinner} from 'react-epic-spinners'
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import { EnviarMail, ContenidoMail } from '../Generales/Controles/EnvioMail';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class ModalCorreoOxxo extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        Correo:"",
        Referencia:"",
        TotalVenta:0,
        EnviandoCorreo:1,
    }
    this.escFunction =  this.escFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
  
  }

  escFunction(event) {
    if (event.keyCode === 27) {
    this.props.eventClick()
    }
  }

  componentDidMount() {
    //console.log(this.props)
    this.setState({
        TotalVenta:this.props.DetallesCompra.responseFromConekta._json.amount/100,
        Referencia:this.props.DetallesCompra.responseFromConekta.charges._json.data[0].payment_method.reference,
        Correo:this.props.DetallesCompra.responseFromConekta._json.customer_info.email
    },() =>{
        //console.log(this.state)
    })
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      3000
    );
  }
  EnviarCorreo(){
    var contenido = ContenidoMail(this.state.TotalVenta, this.state.Referencia, 1, null, null);
    EnviarMail(this.state.Correo, 'compra exitosa', contenido);
    this.setState({EnviandoCorreo:2})

    this.alertTimeout = setTimeout(
      function () {
        this.setState({EnviandoCorreo:3})
        // this.alertTimeout = setTimeout(
        //   function () {
        //    this.props.eventClick();
        //   }.bind(this),
        //   1000
        // );
      }.bind(this),
      2000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.props.VerModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.props.eventClick()}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => this.props.eventClick()}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Reenviar correo</h4>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
              <GridContainer>
                  
          <GridItem xs={12} sm={7} md={7}>
                  <CustomInput
                    labelText="Correo"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        required: true,
                        onChange: this.handleChange,
                        value: this.state.Correo,
                        name: "Correo",
                        id: "Correo",
                        type: "text"
                    }} />
                </GridItem>
                <GridItem xs={12} sm={5} md={5}>
                    <br/>

                     

                      {
                        this.state.EnviandoCorreo == 1 ?  
                        <Button onClick={() => this.EnviarCorreo()} id="btnEnviar" size="sm" color="github"> Enviar 
                        </Button>    :null
                      }
                      {
                        this.state.EnviandoCorreo == 2 ?  <Button disabled id="btnEnviar" size="sm" color="github">
                          Enviando&nbsp;<FulfillingBouncingCircleSpinner size={20} color="white" />
                        </Button> :null
                      }
                      {
                        this.state.EnviandoCorreo == 3 ? 
                        <Button disabled disabled id="btnEnviar" size="sm" color="github">
                        Enviado
                        </Button> :null
                      }

                 
                
                </GridItem>
          
                    </GridContainer>
             

      
          </DialogContent>
    
        </Dialog>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div >
    );
  }
}

export default withStyles(modalStyle)(ModalCorreoOxxo);